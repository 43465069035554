import moment from "moment";
import Pagination from "./Pagination";

export default function ByJobTable({ name = "", header = [], data = [], totalCount = 0, onPageChanged, onRowSelected }) {
    const columnKey = header.map((i) => i.value);

    const setRow = () => {
        const result = [];
        for (let i = 0; i < data.length; i++) {
            result.push(
                <tr
                    key={name + "_tr_" + i}
                    className={"border-t"}>
                    {setColumn(i, data[i])}
                </tr>
            );
        }

        return result;
    };

    const setColumn = (index, item) => {
        const result = [];

        let basestyle = "p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap";

        for (let i = 0; i < columnKey.length; i++) {

            let style = basestyle;
            let columnData = item[columnKey[i]];

            switch (columnKey[i]) {
                case "paytime":
                    columnData = getFormatDate(columnData, "YYYY-MM-DD HH:mm:ss");
                    style = style + " text-center";
                    break;
                case "paycost":
                    style = style + " text-right";
                    columnData = addComma(columnData);
                    break;
                case "paymode":
                    columnData = getPaymodeStr(columnData);
                    break;
                case "jobtype":
                    style = style + " text-center";
                    columnData = getJobtypeStr(columnData);
                    break;
                default:
                    break;
            }

            result.push(
                <td
                    key={name + "_td_" + index + "_" + i}
                    className={style}
                    onClick={() => { onRowSelected(name, index) }}>
                    {columnData}
                </td>
            );
        }

        return result;
    };

    const getFormatDate = (date, format) => {
        var d = new Date(date);
        return moment(d).format(format)
    };

    const addComma = (amt) => {
        let returnString = amt?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    };

    const getPaymodeStr = (mode) => {
        let rt = "";
        switch (mode) {
            case 1: rt = '신용/체크카드'; break;
            case 2: rt = '티머니'; break;
            case 3: rt = '캐시비'; break;
            case 4: rt = '회원카드'; break;
            case 5: rt = '쿠폰'; break;
        }

        return rt;
    };

    const getJobtypeStr = (jobtype) => {
        let rt = "";
        switch (jobtype) {
            case 'Print': rt = "출력"; break;
            case 'Copy': rt = "복사"; break;
            case 'Scan': rt = "스캔"; break;
            case 'Fax': rt = "팩스"; break;
        }

        return rt;
    }

    return (
        <>
            <div className={"relative flex flex-col border border-blueGray-200 w-full transition-custom1 min-h-595-px min-w-0 mb-6 break-words bg-white rounded shadow-lg"} >
                <div className={"block w-full overflow-x-auto rounded-t min-h-553-px"}>
                    <table className="items-center w-full bg-transparent border-collapse ">
                        <thead>
                            <tr>
                                {
                                    header.map((h, index) =>
                                        <th
                                            key={name + "_h_" + index}
                                            className={"px-3 py-3 text-xs font-semibold text-center uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap bg-blueGray-200 text-blueGray-500 border-blueGray-100"}
                                        >
                                            {h.text}
                                        </th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !data.length && <tr>
                                    <td colSpan={columnKey.length} className="items-center p-4 px-4 text-xs text-center align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap" style={{ paddingTop: "140px" }}>
                                        <span
                                            className="ml-3 font-bold text-blueGray-600"
                                        >
                                            조회된 리스트가 없습니다.
                                        </span>
                                    </td>
                                </tr>
                            }
                            {
                                setRow()
                            }
                        </tbody>
                    </table>
                </div>
                {
                    totalCount > 10 &&
                    <Pagination tablename={name} rowcount={totalCount} onPageChanged={onPageChanged} />
                }
            </div>
        </>
    );
}