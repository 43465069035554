import { useState, useEffect, useRef } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import StatusDropdown from "components/Dropdowns/StatusDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown"
import StatusText from "components/Tables/StatusText.js"
import Alert from "components/Alert/StaticAlert";

import { API } from "api/config";
import request from "api/request";

export default function PrinterLicModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined, onSettingDevice = undefined }) {

    const modalName = "printerlic";

    const userInfo = JSON.parse(sessionStorage.user);

    const [showModal, setShowModal] = useState(show);
    
    const [status, setStatus] = useState(false);

    const [alertData, setAlertData] = useState({ show: false, msg: "" });

    useEffect(() => {
        setShowModal(show);

        if (!show) {
            setStatus(false);
            setAlertData({});
        }

    }, [show]);

    useEffect(() => {
        if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
            return;
        }

        console.log("useEffect", data);
        
        setStatus(data.status !== 90);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [showModal]);

    const onStatusChanged = (selectedStatus) => {
        setStatus(selectedStatus);
    };

    const onSaveClicked = () => {
        let newData = Object.assign({}, data);

        if (newData.status !== 1 || status === false) {
            newData.status = status ? 0 : 90;
        } 
        
        console.log("ori data", data);
        console.log("new data", newData);
        
        if (isChangedRowData(data, newData)) {
            let url = `${API.DEVICE_PRINTER_UPDATE}`.replace("{seq}", newData.seq.toString());
            new request(url, newData).post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {
                        onSaved(true);
                    } else {
                        onSaved(false);
                    }
                },
                (err) => {
                    console.log(err);
                    onSaved(false);
                },
                () => {

                });
        } else {
            onClosed(modalName);
        }
    };

    const isChangedRowData = (oriData, newData) => {
        const keys1 = Object.keys(oriData);
        const keys2 = Object.keys(newData);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            const val1 = oriData[key];
            const val2 = newData[key];
            const areObjects = isObject(val1) && isObject(val2);
            if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
                return true;
            }
        }

        return false;
    };

    const isObject = (obj) => {
        return obj != null && typeof obj === 'object';
    };

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                        onClick={() => { }}
                    >
                        <div className="relative w-auto max-w-4xl mx-auto my-6">
                            {/*content*/}
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                                    <h3 className="text-xl font-semibold">
                                        프린터 정보
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => onClosed(modalName)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6 bg-blueGray-100">
                                    <form>
                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                이름 (위치)
                                            </label>
                                            <div className="flex flex-wrap items-center w-full mb-5">
                                                <h3 className="px-3 text-base font-semibold">
                                                    {data.name}
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap w-full mb-5">
                                            <div className="mr-8">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    IP 주소 (내부)
                                                </label>
                                                <h3 className="px-3 text-base font-semibold">
                                                    {data.privateip}
                                                </h3>
                                            </div>
                                            
                                        </div>

                                        <div className="flex flex-wrap w-full mb-5">
                                            <div className="mr-8">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    모델명
                                                </label>
                                                <h3 className="px-3 text-base font-semibold">
                                                    {data.model}
                                                </h3>


                                            </div>
                                            <div>
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    serial
                                                </label>
                                                <h3 className="px-3 text-base font-semibold">
                                                    {data.serial}
                                                </h3>


                                            </div>
                                        </div>

                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                미니서버 (코드)
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                <h3 className="px-3 pt-2 text-base font-semibold">
                                                    {data.miniservercode}
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="w-full">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                라이센스
                                            </label>

                                            <StatusDropdown status={status} statusText={'활성|비활성'} onStatusChanged={onStatusChanged} />
                                        </div>

                                    </form>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-between p-3 px-6 border-t border-solid rounded-b border-blueGray-200">
                                    <div>
                                        <Alert data={alertData}></Alert>
                                    </div>
                                    <div className="float-right">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            type="button"
                                            onClick={() => onClosed(modalName)}>
                                            닫기
                                        </button>
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={onSaveClicked}>
                                            저장
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </>
    );
};