import { useState, useEffect, useRef } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import StatusDropdown from "components/Dropdowns/StatusDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown"
import StatusText from "components/Tables/StatusText.js"
import Alert from "components/Alert/StaticAlert";

import { API } from "api/config";
import request from "api/request";

export default function PrinterModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined, onSettingDevice = undefined }) {

    const modalName = "printer";

    const userInfo = JSON.parse(sessionStorage.user);

    const [showModal, setShowModal] = useState(show);
    const [selectedItem, setSelectedItem] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);
    const [miniserverList, setMiniserverList] = useState({});
    const [costNameList, setCostNameList] = useState({});
    const [payreaderList, setPayreaderList] = useState({});
    const [printerRoleList, setPrinterRoleList] = useState({});

    const [printerName, setPrinterName] = useState('');
    const [privateip, setPrivateip] = useState('');
    const [ewspass, setEwspass] = useState('');
    const [model, setModel] = useState('');
    const [serial, setSerial] = useState('');
    const [a3, setA3] = useState(false);
    const [color, setColor] = useState(false);
    const [miniserver, setMiniserver] = useState({});
    const [cost, setCost] = useState({});
    const [payreader, setPayreader] = useState({});
    const [paymode, setPaymode] = useState({});
    const [status, setStatus] = useState(false);
    const [license, setLicense] = useState(false);
    const [printerrole, setPrinterrole] = useState('');
    const [printerrolename, setPrinterrolename] = useState('');
    

    const nameRef = useRef();
    const privateipRef = useRef();
    const ewspassRef = useRef();

    const [alertData, setAlertData] = useState({ show: false, msg: "" });

    useEffect(() => {
        setShowModal(show);

        if (!show) {
            setPrinterName('');
            setPrivateip('');
            setEwspass('');
            setModel('');
            setSerial('');
            setColor(false);
            setA3(false);
            setSelectedItem([]);
            setMiniserver({});
            setPayreader({});
            setCost({});
            setPaymode({});
            setStatus(false);
            setLicense(false);
            setAlertData({});
            setPrinterrole('-1');
            setPrinterrolename('');
        }

    }, [show]);

    useEffect(() => {
        if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
            return;
        }

        console.log("useEffect", data);

        if (data.seq !== -1) {
            setPrinterName(data.name);
            setPrivateip(data.privateip);
            setEwspass(data.ewspass);
            setModel(data.model);
            setSerial(data.serial);
            setA3(data.a3 === 1);
            setColor(data.color === 1);
            setStatus(data.status === 1);
            setPaymode(data.paymode.toString().padStart(4, "0"));
            setMiniserver({ key: data.and_seq, name: data.miniservercode });
            setCost({ key: data.cost_seq, name: data.costname });
            setPayreader({ key: data.reader_seq, name: data.payreaderlocation });

            setPrinterrole(data.printerrole);
            setPrinterrolename(data.printerrolename);
            setLicense(data.status === 90 ? false : true);
            // TODO : setManagerID


        } else {
            setPaymode('0000');
        }

        let selected = [];

        if (data.resellerid !== undefined && data.resellerid.length > 0 && userInfo.resellerid !== data.resellerid) {
            selected.push({ field: "reseller", value: data.resellerid, name: data.resellername });
        }

        if (data.customerseq !== undefined && data.customerseq > 0) {
            selected.push({ field: "customer", value: data.customerseq, name: data.customername });
        }

        if (selected.length > 0) {
            setSelectedItem(selected);
        }

        console.log("selected reseller and customer", selected);

        setDropdownData([]);
        getMiniserverList();
        getCostNameList();
        getPayreaderList();
        getPrinterRoleList();
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [showModal]);

    useEffect(() => {
        if (!showModal) {
            return;
        }

        if (dropdownData.length === 0) {
            if (userInfo.role === 1) {
                getResellerList();
            } else if (userInfo.role === 2) {
                getResellerList(userInfo.resellerid);
            }
        } else {
            if (selectedItem.length > 0 && selectedItem.length >= dropdownData.length) {
                let selected = selectedItem[dropdownData.length - 1];
                if (selected.field === "reseller" && selected.value.length > 0) {
                    getResellerList(selected.value);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [dropdownData]);

    const getResellerList = (parentid) => {

        new request(`${API.USER_RESELLER}`, {
            "parentid": parentid,
            "pageindex": -1
        }).post(
            (res) => {
                const response = res.data;
                if (response.code === 'success') {

                    let resellerList = [];

                    resellerList.push({
                        key: "",
                        name: "미정(할당안함)",
                        type: "reseller"
                    });

                    for (let i = 0; i < response.data.length; i++) {
                        resellerList.push({
                            key: response.data[i]["resellerid"],
                            name: response.data[i]["name"],
                            type: "reseller"
                        });
                    }

                    if (parentid === undefined || parentid === '') {
                        let item = {
                            "title": "파트너",
                            "data": resellerList
                        };

                        setDropdownData([...dropdownData, item]);
                    } else {
                        getCustomerList(parentid, resellerList);
                    }
                } else {
                    if (parentid !== undefined && parentid.length > 0) {
                        getCustomerList(parentid);
                    }
                }
            }, (err) => {
                console.log(err);
            }, () => {

            });
    };
    const getCustomerList = (resellerid, mergeData) => {

        new request(`${API.USER_CUSTOMER}`, {
            "resellerid": resellerid,
            "pageindex": -1
        }).post(
            (res) => {
                // onSuccess
                const response = res.data;
                if (response.code === 'success') {

                    let customerList = [];

                    if (mergeData !== undefined) {
                        customerList = [...mergeData];
                    }

                    if (customerList <= 1) {
                        customerList.push({
                            key: -1,
                            name: "미정(할당안함)",
                            type: "customer"
                        });
                    }


                    for (let i = 0; i < response.data.length; i++) {
                        customerList.push({
                            key: response.data[i]["seq"],
                            name: response.data[i]["displayname"],
                            type: "customer"
                        });
                    }

                    let item = {
                        "title": "고객",
                        "data": customerList
                    };

                    setDropdownData([...dropdownData, item]);

                }
            }, (err) => {
                // onFailed

            }, () => {
                // onExpired login

            });
    };
    const onResellerDropdownSelected = (type, index, selectedKey, selectedName) => {

        let selected = [...selectedItem];
        let curDropdown = [...dropdownData];

        let isChanged = true;
        let refresh = true;

        if (index < selected.length) {

            if (selected[index].field === type && selected[index].value === selectedKey) {
                refresh = false;
                isChanged = false;
            }

            selected[index].field = type;
            selected[index].value = selectedKey;
        } else {
            selected.push({
                field: type,
                value: selectedKey,
                name: selectedName
            });
        }

        if (refresh) {
            selected = selected.slice(0, index + 1);
            setSelectedItem(selected);
            curDropdown = curDropdown.slice(0, index + 1);
            setDropdownData(curDropdown);

            //setMiniserver({ key: -1, name: "" });
            setCost({ key: -1, name: "" });
            setPayreader({ key: -1, name: "" });
        }

        let selectedReseller = "";
        let selectedCustomer = -1;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].field === "reseller") {
                selectedReseller = selected[i].value;
            } else {
                selectedCustomer = selected[i].value;
            }
        }
        if (selectedReseller.length === 0) {
            selectedReseller = userInfo.resellerid;
        }

        getMiniserverList(selectedReseller, selectedCustomer);
        getPayreaderList(selectedReseller);
        getCostNameList(selectedReseller)

    };
    const getSelectedItem = (index) => {
        if (index < selectedItem.length) {
            return { key: selectedItem[index].value, name: selectedItem[index].name };
        }
        return undefined;
    };

    const getMiniserverList = (resellerid, customerseq) => {
        let param = {};
        if (userInfo.role !== 1) {
            param.resellerid = (data.resellerid === undefined) ? userInfo.resellerid : data.resellerid;
            param.customerseq = (data.customerseq === undefined) ? userInfo.customerseq : data.customerseq;
        }
        if (resellerid !== undefined) {
            param.resellerid = resellerid;
        }
        if (customerseq !== undefined) {
            //param.customerseq = customerseq;
        }

        new request(`${API.DEVICE_LIST_MINISERVERCODE}`, param)
            .post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {

                        let list = [];

                        list.push({
                            key: -1,
                            name: "미정(할당안함)",
                            type: "miniserver"
                        });

                        for (let i = 0; i < response.data.length; i++) {
                            list.push({
                                key: response.data[i]["seq"],
                                name: response.data[i]["codename"],
                                type: "miniserver"
                            });
                        }

                        let item = {
                            "title": "미니서버",
                            "data": list
                        };

                        setMiniserverList(item);
                    }
                }, (err) => {
                    console.log(err);
                    setMiniserverList({});
                }, () => {

                });
    }

    const getPayreaderList = (resellerid) => {
        let param = {};
        if (userInfo.role !== 1) {
            param.resellerid = (data.resellerid === undefined) ? userInfo.resellerid : data.resellerid;
        }
        if (resellerid !== undefined) {
            param.resellerid = resellerid;
        }
        new request(`${API.DEVICE_LIST_PAYREADER}`, param)
            .post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {

                        let list = [];

                        list.push({
                            key: -1,
                            name: "미정(할당안함)",
                            type: "payreader"
                        });

                        for (let i = 0; i < response.data.length; i++) {
                            list.push({
                                key: response.data[i]["seq"],
                                name: response.data[i]["location"],
                                type: "payreader"
                            });
                        }

                        let item = {
                            "title": "결제단말기",
                            "data": list
                        };

                        setPayreaderList(item);
                    }
                }, (err) => {
                    console.log(err);
                    setPayreaderList({});
                }, () => {

                });
    };

    const getCostNameList = (resellerid) => {
        let param = {};
        if (userInfo.role !== 1) {
            param.resellerid = (data.resellerid === undefined) ? userInfo.resellerid : data.resellerid;
        }
        if (resellerid !== undefined) {
            param.resellerid = resellerid;
        }
        new request(`${API.DEVICE_LIST_COST}`, param)
            .post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {

                        let list = [];

                        list.push({
                            key: -1,
                            name: "미정(할당안함)",
                            type: "cost"
                        });

                        for (let i = 0; i < response.data.length; i++) {
                            list.push({
                                key: response.data[i]["seq"],
                                name: response.data[i]["name"],
                                type: "cost"
                            });
                        }

                        let item = {
                            "title": "비용(코드)",
                            "data": list
                        };

                        setCostNameList(item);
                    }
                }, (err) => {
                    console.log(err);
                    setCostNameList({});
                }, () => {

                });
    };

    const getPrinterRoleList = () => {
        
        new request(`${API.USER_PRINTER_ROLE_LIST}`, {})
            .post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {

                        let list = [];

                        list.push({
                            key: -1,
                            name: "없음",
                            type: "printerrole"
                        });

                        for (let i = 0; i < response.data.length; i++) {
                            list.push({
                                key: response.data[i]["seq"],
                                name: response.data[i]["name"],
                                type: "printerrole"
                            });
                        }

                        let item = {
                            "title": "관리방법",
                            "data": list
                        };

                        setPrinterRoleList(item);
                    }
                }, (err) => {
                    console.log(err);
                    setPrinterRoleList({});
                }, () => {

                });
    };

    const onDropdownSelected = (type, index, selectedKey, selectedName) => {
        setAlertData({});
        if (type === "miniserver") {
            setMiniserver({ key: selectedKey, name: selectedName });
        } else if (type === "cost") {
            setCost({ key: selectedKey, name: selectedName });
        } else if (type === "payreader") {
            setPayreader({ key: selectedKey, name: selectedName })
        } else {
            setPrinterrole(selectedKey);
        }
    };


    const onA3Changed = (value) => {
        setA3(value)
    };
    const onColorChanged = (value) => {
        setColor(value)
    };

    const onStatusChanged = (selectedStatus) => {
        setStatus(selectedStatus);
    };

    const onLicenseChanged = (selectedStatus) => {
        if (selectedStatus === true) {
            setStatus(false);
        }
        setLicense(selectedStatus);
    };

    const onSaveClicked = () => {
        let newData = Object.assign({}, data);

        if (printerName.length === 0) {
            setAlertData({ show: true, msg: "이름(위치)은 필수입력입니다." });
            return nameRef.current.focus();
        }

        if (privateip.length === 0) {
            setAlertData({ show: true, msg: "IP주소는 필수입력입니다." });
            return privateipRef.current.focus();
        }

        if (ewspass.length === 0) {
            setAlertData({ show: true, msg: "웹 비밀번호는 필수입력입니다." });
            return ewspassRef.current.focus();
        }

        if (miniserver.key < 0) {
            setAlertData({ show: true, msg: "미니서버를 선택해 주세요." });
            return;
        }
        
        // TODO : miniserver.key validation

        newData.name = printerName;
        newData.privateip = privateip;
        newData.ewspass = ewspass;
        newData.model = model;
        newData.serial = serial;
        newData.a3 = a3 ? 1 : 0;
        newData.color = color ? 1 : 0;
        newData.and_seq = miniserver.key;
        newData.reader_seq = payreader.key;
        newData.cost_seq = cost.key;
        newData.paymode = Number(paymode);
        newData.status = status ? 1 : 0;

        if (license === false) {
            newData.status = 90;
        }

        newData.printerrole = printerrole;

        let selectedReseller = "";
        let selectedCustomer = -1;
        for (let i = selectedItem.length - 1; i >= 0; i--) {
            if (selectedItem[i].field === "reseller") {
                selectedReseller = (selectedReseller.length === 0) ? selectedItem[i].value : selectedReseller;
            } else {
                selectedCustomer = selectedItem[i].value;
            }
        }

        if (userInfo.role === 2 && selectedReseller.length === 0) {
            selectedReseller = userInfo.resellerid;
        }
        newData.resellerid = selectedReseller;
        newData.customerseq = selectedCustomer;

        if (newData.resellerid === null || newData.resellerid.length === 0 || newData.customerseq === undefined) {
            newData.customerseq = -1;
        }

        console.log("ori data", data);
        console.log("new data", newData);


        if (isChangedRowData(data, newData)) {
            let url = `${API.DEVICE_PRINTER_UPDATE}`.replace("{seq}", newData.seq.toString());
            new request(url, newData).post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {
                        onSaved(true);
                    } else {
                        onSaved(false);
                    }
                },
                (err) => {
                    console.log(err);
                    onSaved(false);
                },
                () => {

                });
        } else {
            onClosed(modalName);
        }
    };

    const isChangedRowData = (oriData, newData) => {
        const keys1 = Object.keys(oriData);
        const keys2 = Object.keys(newData);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            const val1 = oriData[key];
            const val2 = newData[key];
            const areObjects = isObject(val1) && isObject(val2);
            if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
                return true;
            }
        }

        return false;
    };

    const isObject = (obj) => {
        return obj != null && typeof obj === 'object';
    };

    const changePaymode = (index, replacement) => {
        let tmp = paymode;
        tmp = tmp.substring(0, index) + replacement + tmp.substring(index + replacement.length);
        setPaymode(tmp);
    }


    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                        onClick={() => { }}
                    >
                        <div className="relative w-auto max-w-4xl mx-auto my-6">
                            {/*content*/}
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                                    <h3 className="text-xl font-semibold">
                                        프린터 정보
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => onClosed(modalName)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6 bg-blueGray-100">
                                    <form>
                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                이름 (위치)
                                            </label>
                                            <input
                                                type="text"
                                                className="px-3 py-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                ref={nameRef}
                                                value={printerName}
                                                onChange={(e) => setPrinterName(e.target.value)}
                                            />
                                        </div>

                                        <div className="flex flex-wrap w-full mb-5">
                                            <div className="mr-8">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    IP 주소 (내부)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="px-4 py-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    ref={privateipRef}
                                                    value={privateip}
                                                    onChange={(e) => setPrivateip(e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    웹 비밀번호
                                                </label>
                                                <input
                                                    type="password"
                                                    className="px-4 py-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    ref={ewspassRef}
                                                    value={ewspass}
                                                    onChange={(e) => setEwspass(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {
                                            userInfo.role === 1 &&
                                            <div className="flex flex-wrap w-full mb-5">
                                                <div className="mr-8">
                                                    <label
                                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                        모델명
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="px-4 py-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                        value={model}
                                                        onChange={(e) => setModel(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                        serial
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="px-4 py-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                        value={serial}
                                                        onChange={(e) => setSerial(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {
                                            userInfo.role === 1 &&
                                            <div className="w-full mb-5">
                                                <label
                                                    className="block w-full mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    A3 / 컬러 지원여부
                                                </label>
                                                <div className="flex ">
                                                    <div className="flex mr-6" style={{ width: '210px' }}>
                                                        <div className="mt-2 mr-2">
                                                            <h3 className="px-3 text-sm font-semibold">
                                                                A3
                                                            </h3>
                                                        </div>
                                                        <div className="mr-4">
                                                            <StatusDropdown status={a3} statusText={"지원|지원안함"} onStatusChanged={onA3Changed} />
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="mt-2 mr-2">
                                                            <h3 className="px-3 text-sm font-semibold" >
                                                                컬러
                                                            </h3>
                                                        </div>
                                                        <div className="mr-4">
                                                            <StatusDropdown status={color} statusText={"지원|지원안함"} onStatusChanged={onColorChanged} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                파트너 / 고객
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                {userInfo.role === 2 &&
                                                    <h3 className="px-3 pt-2 text-sm font-semibold">
                                                        {userInfo.resellername} /
                                                    </h3>
                                                }
                                                {
                                                    dropdownData.map((item, index) =>
                                                        <SearchDropdown
                                                            key={"SearchDropdown_" + index}
                                                            title={item.title}
                                                            inx={index}
                                                            selecteditem={getSelectedItem(index)}
                                                            items={item.data}
                                                            onselected={onResellerDropdownSelected} />
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                미니서버 (코드)
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                {
                                                    Object.keys(miniserverList).length === 0 ?
                                                        <h3 className="px-3 text-sm font-semibold">
                                                            {"없음"}
                                                            <button
                                                                type="button"
                                                                className="w-2/12 px-2 py-1 ml-3 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (onSettingDevice) {
                                                                        onSettingDevice(2);
                                                                        onClosed(modalName);
                                                                    }
                                                                }}>
                                                                설정하기
                                                            </button>
                                                        </h3>
                                                        :
                                                        <SearchDropdown
                                                            title={miniserverList.title}
                                                            selecteditem={{ key: miniserver.key, name: miniserver.key === -1 ? "미정(할당안함)" : miniserver.name }}
                                                            items={miniserverList.data}
                                                            onselected={onDropdownSelected} />
                                                }
                                            </div>
                                        </div>

                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                비용 (코드)
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                {
                                                    Object.keys(costNameList).length === 0 ?
                                                        <h3 className="px-3 text-sm font-semibold">
                                                            {"없음"}
                                                            <button
                                                                type="button"
                                                                className="w-2/12 px-2 py-1 ml-3 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (onSettingDevice) {
                                                                        onSettingDevice(3);
                                                                        onClosed(modalName);
                                                                    }
                                                                }}>
                                                                설정하기
                                                            </button>
                                                        </h3>
                                                        :
                                                        <SearchDropdown
                                                            title={costNameList.title}
                                                            selecteditem={{ key: cost.key, name: cost.key === -1 ? "미정(할당안함)" : cost.name }}
                                                            items={costNameList.data}
                                                            onselected={onDropdownSelected} />
                                                }

                                            </div>
                                        </div>

                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                결제단말기 (위치/이름)
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                {
                                                    Object.keys(payreaderList).length === 0 ?
                                                        <h3 className="px-3 text-sm font-semibold">
                                                            {"없음"}
                                                            <button
                                                                type="button"
                                                                className="w-2/12 px-2 py-1 ml-3 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (onSettingDevice) {
                                                                        onSettingDevice(1);
                                                                        onClosed(modalName);
                                                                    }
                                                                }}>
                                                                설정하기
                                                            </button>
                                                        </h3>
                                                        :
                                                        <SearchDropdown
                                                            title={payreaderList.title}
                                                            selecteditem={{ key: payreader.key, name: payreader.key === -1 ? "미정(할당안함)" : payreader.name }}
                                                            items={payreaderList.data}
                                                            onselected={onDropdownSelected} />
                                                }

                                            </div>
                                        </div>

                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-3 text-xs font-bold uppercase text-blueGray-600">
                                                결제방법
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                <div className="mr-6">
                                                    <label
                                                        className="block mb-2 text-sm font-bold uppercase text-blueGray-600">
                                                        <input
                                                            type="checkbox"
                                                            className="mr-2 -mt-3-px"
                                                            checked={paymode[0] === "1"}
                                                            onChange={(e) => {
                                                                changePaymode(0, e.target.checked ? "1" : "0");
                                                            }} />
                                                        신용카드
                                                    </label>
                                                </div>
                                                <div className="mr-6">
                                                    <label
                                                        className="block mb-2 text-sm font-bold uppercase text-blueGray-600">
                                                        <input
                                                            type="checkbox"
                                                            className="mr-2 -mt-3-px"
                                                            checked={paymode[1] === "1"}
                                                            onChange={(e) => {
                                                                changePaymode(1, e.target.checked ? "1" : "0");
                                                            }} />
                                                        티머니
                                                    </label>
                                                </div>
                                                <div className="mr-6">
                                                    <label
                                                        className="block mb-2 text-sm font-bold uppercase text-blueGray-600">
                                                        <input
                                                            type="checkbox"
                                                            className="mr-2 -mt-3-px"
                                                            checked={paymode[2] === "1"}
                                                            onChange={(e) => {
                                                                changePaymode(2, e.target.checked ? "1" : "0");
                                                            }} />
                                                        회원카드
                                                    </label>
                                                </div>
                                                <div className="mr-6">
                                                    <label
                                                        className="block mb-2 text-sm font-bold uppercase text-blueGray-600">
                                                        <input
                                                            type="checkbox"
                                                            className="mr-2 -mt-3-px"
                                                            checked={paymode[3] === "1"}
                                                            onChange={(e) => {
                                                                changePaymode(3, e.target.checked ? "1" : "0");
                                                            }} />
                                                        부가세 계산
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        {userInfo.role === 1 &&
                                            <div className="flex flex-wrap w-full mb-5">
                                                <div className="mr-8">
                                                    <label
                                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                        관리방법
                                                    </label>
                                                    
                                                    <SearchDropdown
                                                            title={printerRoleList.title}
                                                            selecteditem={{ key: printerrole, name: printerrolename || '없음' }}
                                                            items={printerRoleList.data}
                                                            onselected={onDropdownSelected} />
                                                    
                                                </div>
                                                <div>
                                                    <label
                                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                        라이센스
                                                    </label>

                                                    <StatusDropdown status={license} statusText={"활성|비활성"} onStatusChanged={onLicenseChanged} />
                                                </div>
                                            </div>
                                        }

                                        {license === true ?
                                            <div className="w-full">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    상태
                                                </label>

                                                <StatusDropdown status={status} onStatusChanged={onStatusChanged} />
                                            </div>
                                            :
                                            <div className="w-full">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    상태
                                                </label>
                                                <StatusText classname={"text-sm font-bold mb-1"} status={license} text={"사용|라이센스 없음"} />
                                            </div>
                                        }

                                    </form>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-between p-3 px-6 border-t border-solid rounded-b border-blueGray-200">
                                    <div>
                                        <Alert data={alertData}></Alert>
                                    </div>
                                    <div className="float-right">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            type="button"
                                            onClick={() => onClosed(modalName)}>
                                            닫기
                                        </button>
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={onSaveClicked}>
                                            저장
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </>
    );
};