import moment from "moment";
import Pagination from "./Pagination.js";

export default function CardHistoryTable({ name = "", header = [], data = [], totalCount = 0, onPageChanged }) {

  const columnKey = header.map((i) => i.value);

  const setRow = () => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push(
        <tr
          key={name + "_tr_" + i}
          className={"border-t text-center"}>
          {setColumn(i, data[i])}
        </tr>
      );
    }

    return result;
  };

  const setColumn = (index, item) => {
    const result = [];
    for (let i = 0; i < columnKey.length; i++) {
      if (i === 0) {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"items-center p-2 px-4 align-middle text-xs border-l-0 border-r-0 whitespace-nowrap"}
            >
            <span className={"font-bold text-" + ((item['topup'] === 1 && item['cost'] > 0)? "emerald" : "orange") + "-500"}>
              {item['topup'] === 1 ? item['cost'] > 0 ? "충전" : "변경" : "사용"}
            </span>
          </td>
        );
      } else {
        if (columnKey[i] === 'clienttime') {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap text-center"}
              >
              {getFormatDate(item[columnKey[i]], "YYYY-MM-DD HH:mm:ss")}</td>
          );
        } else if (columnKey[i] === 'status') {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
              >
              <div><i className={"mr-2 fas fa-circle text-" + (item[columnKey[i]] === 1 ? "emerald" : "orange") + "-500"}></i>{item[columnKey[i]] === 1 ? "사용가능" : "사용불가"}</div>
            </td>
          );
        } else if (columnKey[i] === 'jobtype') {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}>
              <div>
                  {item['topup'] === 1 ? item[columnKey[i]] : 
                  item[columnKey[i]] === "Print" ? "출력" : 
                  item[columnKey[i]] === "Scan" ? "스캔" :
                  item[columnKey[i]] === "Copy" ? "복사" :
                  item[columnKey[i]] === "Fax" ? "팩스" : ""}
              </div>
            </td>
          )
        } else if (columnKey[i] === 'cost') {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
              >
              {Math.abs(item[columnKey[i]])}
            </td>
          );
        }else {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
              >
              {item[columnKey[i]]}
            </td>
          );
        }
      }
    }

    return result;
  };

  const getFormatDate = (date, format) => {
    var d = new Date(date);
    return moment(d).format(format)
  };

  return (
    <>
      <div className={"relative flex flex-col border border-blueGray-200 w-full transition-custom1 min-h-400-px min-w-0 mb-6 break-words bg-white rounded shadow-lg"} >
        <div className={"block w-full overflow-x-auto rounded-t min-h-146-px"}>
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              <tr>
                {
                  header.map((h, index) =>
                    <th
                      key={name + "_h_" + index}
                      className={"px-3 py-3 text-xs font-semibold text-center uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap bg-blueGray-200 text-blueGray-500 border-blueGray-100"}
                    >
                      {h.text}
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                !data.length && <tr>
                  <td colSpan={columnKey.length} className="items-center p-4 px-4 text-xs text-center align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap" style={{ paddingTop: "140px" }}>
                    <span
                      className="ml-3 font-bold text-blueGray-600"
                    >
                      조회된 리스트가 없습니다.
                    </span>
                  </td>
                </tr>
              }
              {
                setRow()
              }
            </tbody>
          </table>
        </div>
        {
          totalCount > 10 &&
          <Pagination tablename={name} rowcount={totalCount} onPageChanged={onPageChanged} />
        }
      </div>
    </>
  );
}
