import React, { useEffect } from "react";
import { createPopper } from "@popperjs/core";

const SearchDropdown = ({ name = "", selecteditem = {}, items = [], onselected }) => {
    // dropdown props
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);

    const [currentItem, setCurrentItem] = React.useState(selecteditem);
    const [dropdownList, setDropdownList] = React.useState(items);

    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start"
        });
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    useEffect(() => {
        setCurrentItem(selecteditem);
    }, [selecteditem.key]);

    useEffect(() => {
        setDropdownList(items);
    }, [items]);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <div className="relative inline-flex w-full align-middle">
                        <button
                            className="px-4 py-2 mr-3 text-sm font-bold transition-all duration-150 ease-linear bg-white border-t rounded shadow outline-none border-blueGray-100 text-blueGray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            data={currentItem.key === undefined ? "" : "" + currentItem.key}
                            ref={btnDropdownRef}
                            onClick={() => {
                                dropdownPopoverShow
                                    ? closeDropdownPopover()
                                    : openDropdownPopover();
                            }}
                        >
                            {currentItem.name === undefined ? "" : currentItem.name}
                            <i className={"ml-2 fas fa-caret-" + (dropdownPopoverShow ? "up" : "down")}></i>
                        </button>

                        <div
                            className={(dropdownPopoverShow ? "block " : "hidden ") + "fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"}
                            onClick={(e) => closeDropdownPopover()}>
                            <div
                                ref={popoverDropdownRef}
                                className={
                                    (dropdownPopoverShow ? "block " : "hidden ") +
                                    "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48 border-blueGray-200 border"
                                }
                            >

                                <div className="overflow-y-auto" style={{ height: "180px" }}>
                                    {
                                        dropdownList.map((item, index) =>
                                            <a
                                                key={"Dropdown_" + index + "_" + item.key}
                                                href="#pablo"
                                                className="block w-full px-4 py-2 text-sm font-bold whitespace-no-wrap bg-transparent text-blueGray-500"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentItem({ key: item.key, name: item.name });
                                                    closeDropdownPopover();
                                                    if (name.length > 0) {
                                                        onselected(item.key, name, item.name);
                                                    } else {
                                                        onselected(item.key, item.name);
                                                    }
                                                    
                                                }}
                                            >
                                                {item.name}
                                            </a>
                                        )
                                    }
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );

};



export default SearchDropdown;
