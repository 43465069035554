import moment from "moment";
import StatusText from "components/Tables/StatusText.js"
import Pagination from "./Pagination.js";

export default function PrinterTable({ name = "", header = [], data = [], totalCount = 0, onPageChanged, onRowSelected }) {

  const userInfo = JSON.parse(sessionStorage.user);

  const columnKey = header.map((i) => i.value);

  const setRow = () => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (i === data.length - 1) {
        result.push(
          <tr
            key={name + "_tr_" + i}
            className={"border-t border-b"}>
            {setColumn(i, data[i])}
          </tr>
        );
      } else {
        result.push(
          <tr
            key={name + "_tr_" + i}
            className={"border-t"}>
            {setColumn(i, data[i])}
          </tr>
        );
      }
      
    }

    return result;
  };

  const setColumn = (index, item) => {
    const result = [];

    let rowData = Object.assign({}, item);
    rowData.support_A3_color = "" + rowData.a3 + "" + rowData.color;

    for (let i = 0; i < columnKey.length; i++) {
      if (columnKey[i] === 'name') {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"items-center p-2 px-4 text-xs align-middle border-l-0 border-r whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            <span className={"ml-3 font-bold text-blueGray-600"}>{rowData[columnKey[i]]}</span>
          </td>
        );
      } else if (columnKey[i] === 'lastupdate') {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"p-2 px-4 text-xs text-center align-middle border-l-0 border-r whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            {moment(rowData[columnKey[i]]).format("YYYY-MM-DD")}
          </td>
        );
      } else if (columnKey[i] === 'paymode') {

        let paymode = rowData.paymode.toString().padStart(4, "0");
        

        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"p-2 px-4 text-xs text-center align-middle border-l-0 border-r whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            <StatusText classname={"text-xs mb-1"} label={"신용카드 : "} icon={"ox"} status={paymode[0] === "1"} text={"Ｏ|Ｘ"} />
            <StatusText classname={"text-xs mb-1"} label={"교통카드 : "} icon={"ox"} status={paymode[1] === "1"} text={"Ｏ|Ｘ"} />
            <StatusText classname={"text-xs mb-1"} label={"회원카드 : "} icon={"ox"} status={paymode[2] === "1"} text={"Ｏ|Ｘ"} />
            <StatusText classname={"text-xs mb-1"} label={"부 가 세 : "} icon={"ox"}  status={paymode[3] === "1"} text={"Ｏ|Ｘ"} />
          </td>
        );
      } else if (columnKey[i] === 'support_A3_color') {

        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"p-2 px-4 text-xs align-middle border-l-0 border-r whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            <StatusText classname={"text-xs mb-1"} label={"A3 : "} icon={"ox"} status={rowData.support_A3_color[0] === "1"} text={"지원|지원안함"} />
            <StatusText classname={"text-xs mb-1"} label={"컬러 : "} icon={"ox"} status={rowData.support_A3_color[1] === "1"} text={"지원|지원안함"} />
          </td>
        );
      }else if (columnKey[i] === 'status') {
        if (userInfo.role === 4) {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r whitespace-nowrap"}
              onClick={() => { onRowSelected(name, index) }}>
              <StatusText classname={"text-xs mb-1"} status={rowData[columnKey[i]] !== 90} text={"라이센스 활성|라이센스 비활성"} />
            </td>
          );
        } else {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r whitespace-nowrap"}
              onClick={() => { onRowSelected(name, index) }}>
              <StatusText classname={"text-xs mb-1"} status={rowData[columnKey[i]] === 1} text={"사용중|사용하지 않음"} />
            </td>
          );
        }
      } else {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"p-2 px-4 text-xs align-middle border-l-0 border-r whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            {rowData[columnKey[i]]}</td>
        );
      }
    }

    return result;
  };

  return (
    <>
      <div
        className={"relative flex flex-col border border-blueGray-200 w-full transition-custom1 min-h-553-px min-w-0 mb-6 break-words bg-white rounded shadow-lg"}
      >

        <div className={"block w-full overflow-x-auto rounded-t min-h-400-px"}>

          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              <tr>
                {
                  header.map((h, index) =>
                    <th
                      key={name + "_h_" + index}
                      className={"px-3 py-3 text-xs font-semibold text-center uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap bg-blueGray-200 text-blueGray-500 border-blueGray-100"}
                    >
                      {h.text}
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                !data.length && <tr>
                  <td colSpan={columnKey.length} className="items-center p-4 px-4 text-xs text-center align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap" style={{ paddingTop: "140px" }}>
                    <span
                      className="ml-3 font-bold text-blueGray-600"
                    >
                      조회된 리스트가 없습니다.
                    </span>
                  </td>
                </tr>
              }
              {setRow()}
            </tbody>
          </table>
        </div>
        {
          totalCount > 10 &&
          <Pagination tablename={name} rowcount={totalCount} limit={10} onPageChanged={onPageChanged} />
        }
      </div>
    </>
  );
}
