import React, { useEffect, useState } from "react";
import { API } from "../api/config"
import request from "../api/request"

import CardProfile from "components/Cards/CardProfile.js";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown";
import { useNavigate } from "react-router-dom";

import Header from "components/Tables/Header.json"

import Alert from "components/Alert/StaticAlert";
import UserCardTable from "components/Tables/UserCardTable";
import UserCardModal from "components/Modals/UserCardModal";
import CardHistoryModal from "components/Modals/CardHistoryModal";
import CouponTable from "components/Tables/CouponTable";
import CouponModal from "components/Modals/CouponModal";

export default function CardAndCoupon() {

  // current tab
  const [openTab, setOpenTab] = useState(1);

  const [selectedItem, setSelectedItem] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdownTo, setDropdownTo] = useState("customer");

  const [field, setField] = useState({ card: "comment", coupon: "pointnum" });
  const [value, setValue] = useState('');

  // data table
  const [header, setHeader] = useState({ card: [], coupon: [] });
  const [dataTable, setDataTable] = useState({ card: [], coupon: [] });
  const [rowCount, setRowCount] = useState({ card: 0, coupon: 0 });
  const [pageIndex, setPageIndex] = useState({ card: 1, coupon: 1 });

  // modals
  const [showModal, setShowModal] = useState({ card: false, coupon: false, cardhistory: false });

  const [selectedRow, setSelectedRow] = useState({});

  const userInfo = JSON.parse(sessionStorage.user);
  const navigate = useNavigate();

  const onTabClick = (tabIndex) => {
    setOpenTab(tabIndex);

    if (tabIndex === 1) {
      setDropdownTo("reseller");
    } else {
      setDropdownTo("customer");
    }

    setValue('');
    setDropdownData([]);
    setSelectedItem([]);
  };

  const setTableHeader = () => {
    setHeader({
      card: Header.card.filter((c) => c.role === undefined || c.role === userInfo.role),
      coupon: Header.coupon.filter((c) => c.role === undefined || c.role === userInfo.role)
    });
  };

  useEffect(() => {
    setTableHeader();
    if (userInfo.role === 3) {
      onTabClick(2);
    } else {
      onTabClick(1);
    }
  }, []);

  useEffect(() => {

    if (dropdownData.length === 0) {
      if (userInfo.role === 1) {
        getResellerList();
      } else {
        getCustomerList(userInfo.resellerid);
      }
    } else {
      if (selectedItem.length > 0 && selectedItem.length === dropdownData.length) {
        let selected = selectedItem[selectedItem.length - 1];
        if (selected.field === "reseller" && selected.value.length > 0) {
          getResellerList(selected.value);
        }
      }
    }

  }, [dropdownData]);

  const onClosed = (modalName) => {
    let show = Object.assign({}, showModal);
    show[modalName] = false;
    setShowModal(show);
  };

  const onPageChanged = (tablename, pageindex) => {
    console.log("onPageChanged", tablename, pageindex);

    let pindex = Object.assign({}, pageIndex);
    pindex[tablename] = pageindex;

    startSearch(undefined, tablename + '-search', pindex);
  };

  const onRowSelected = (name, index) => {
    let show = { card: false, coupon: false, cardhistory: false };
    show[name] = true;

    setShowModal(show); // show 부터 먼저 호출

    if (name === "cardhistory") {
      setSelectedRow(Object.assign({}, dataTable["card"][index]));
    } else {
      setSelectedRow(Object.assign({}, dataTable[name][index]));
    }
  };

  // const useHistoryClicked = (index) => {
  //   setHistoryModal(true);
  //   setSelectedRow(Object.assign({}, dataTable["card"][index]));
  // }

  /**
* search condition
*/
  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          if (response.data.length > 1) {
            resellerList.push({
              key: "",
              name: "전체",
              type: "reseller"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '') {
            let item = {
              "title": "파트너",
              "data": resellerList
            };

            setDropdownData([...dropdownData, item]);
          }
          else {
            if (dropdownTo === "customer") {
              getCustomerList(parentid, resellerList);
            } else {
              let item = {
                "title": "파트너",
                "data": resellerList
              };

              setDropdownData([...dropdownData, item]);
            }
          }
        }
        else {
          if (parentid !== undefined && parentid.length > 0) {
            if (dropdownTo === "customer") {
              getCustomerList(parentid);
            }
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {
        navigate('/login');
      });
  };
  const getCustomerList = (resellerid, mergeData) => {

    new request(`${API.USER_CUSTOMER}`, {
      "resellerid": resellerid,
      "pageindex": -1
    }).post(
      (res) => {
        // onSuccess
        const response = res.data;
        if (response.code === 'success') {

          let customerList = [];

          if (mergeData !== undefined) {
            customerList = [...mergeData];
          }

          if (response.data.length > 1 && customerList.length <= 1) {
            customerList.push({
              key: "",
              name: "전체",
              type: "customer"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            customerList.push({
              key: response.data[i]["seq"],
              name: response.data[i]["displayname"],
              type: "customer"
            });
          }

          let item = {
            "title": "고객",
            "data": customerList
          };

          setDropdownData([...dropdownData, item]);

        }
      }, (err) => {
        // onFailed

      }, () => {
        // onExpired login
        navigate('/login');
      });
  };

  const onDropdownSelected = (type, index, selectedKey) => {

    let selected = [...selectedItem];
    let curDropdown = [...dropdownData];

    let refresh = true;

    if (selected.length - 1 >= index) {

      if (selected[index].field === type && selected[index].value === selectedKey) {
        refresh = false;
      }

      selected[index].field = type;
      selected[index].value = selectedKey;
    } else {
      selected.push({
        field: type,
        value: selectedKey
      });
    }

    if (refresh) {
      selected = selected.slice(0, index + 1);
      setSelectedItem(selected);
      curDropdown = curDropdown.slice(0, index + 1);
      setDropdownData(curDropdown);
    }
  };

  const onFieldChanged = (fieldKey) => {
    let rt = Object.assign({}, field);

    switch (openTab) {
      case 1: rt.card = fieldKey; break;
      case 2: rt.coupon = fieldKey; break;
      default: rt.card = fieldKey; break;
    }

    setField(rt);
    setValue('');
  };

  /**
* update
*/
  const onUpdateModal = (modalName, isSaved) => {
    if (isSaved) {
      startSearch(undefined, modalName + "-search");
    } else {
      // TODO : alert 
    }
    onClosed(modalName);
  };

  const startSearch = (e, searcher, pageindex) => {

    let url = "";
    let data = {};
    let searchForm = "";

    let list = Object.assign({}, dataTable);
    let cnt = Object.assign({}, rowCount);

    if (e !== undefined) {
      e.preventDefault();
      searchForm = e.target.id;
    } else {
      searchForm = searcher;
    }

    for (let i = 0; i < selectedItem.length; i++) {
      if (selectedItem[i].field === "reseller") {
        data.resellerid = selectedItem[i].value;
      } else {
        data.customerseq = selectedItem[i].value;
      }
    }

    if (pageindex === undefined) {
      // 검색버튼을 통한 조회 시 Pagination 초기화
      pageindex = { card: 1, coupon: 1 };
      cnt[searchForm.replace("-search", "")] = 0;
      setRowCount(cnt);
    }

    data.value = value;

    if (searchForm === "card-search") {

      url = `${API.USER_CARD}`;
      data.field = field.card;
      data.pageindex = pageindex.card;
      data.resellerid = data.resellerid || userInfo.resellerid;
    } else {
      url = `${API.USER_COUPON}`;
      data.pageindex = pageindex.coupon;
      data.field = field.coupon;
      data.selectedfield = selectedItem[selectedItem.length - 1]?.field || "";
      data.selectedvalue = selectedItem[selectedItem.length - 1]?.value || "";
      data.resellerid = data.resellerid || userInfo.resellerid;
      data.customerseq = data.customerseq || userInfo.customerseq;
    }

    console.log("search data : " + JSON.stringify(data));

    new request(url, data).post(
      (res) => {
        const response = res.data;

        console.log(response);

        if (response.code === 'success') {
          switch (searchForm) {
            case 'card-search': list.card = response.data; cnt.card = response.rowcnt; break;
            case 'coupon-search': list.coupon = response.data; cnt.coupon = response.rowcnt; break;
          }
          setDataTable(list);
          setRowCount(cnt);
        }
        if (response.code === "error" && response.message === "no data") {
          // 조회된 리스트가 없습니다.

          switch (searchForm) {
            case 'card-search': list.card = []; cnt.card = 0; break;
            case 'coupon-search': list.coupon = []; cnt.coupon = 0; break;
          }
          setDataTable(list);
          setRowCount(cnt);

        }
      },
      (err) => {
        const errResponse = err.response;
        if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
          // 조회된 리스트가 없습니다.

          switch (searchForm) {
            case 'card-search': list.card = []; cnt.card = 0; break;
            case 'coupon-search': list.coupon = []; cnt.coupon = 0; break;
          }
          setDataTable(list);
          setRowCount(cnt);

        } else {
          // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
          console.log(errResponse);
        }
      },
      () => {
        // expired login token
      }
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex flex-row flex-wrap pt-3 pb-4 mb-0 list-none"
            role="tablist"
          >
            {userInfo.role === 3 ? null :
              <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-lightBlue-600"
                      : "text-lightBlue-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    onTabClick(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  사용자 카드
                </a>
              </li>
            }
            <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-lightBlue-600"
                    : "text-lightBlue-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  onTabClick(2);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                쿠폰
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg min-h-screen-75">
            <div className="flex-auto px-4 py-5">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    사용자 카드
                  </label>

                  <form id="card-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    {userInfo.role === 1 ?
                      <div className="flex flex-wrap w-full pr-4 mb-2">
                        {
                          dropdownData.map((item, index) =>
                            <SearchDropdown
                              key={"SearchDropdown_" + index}
                              title={item.title}
                              inx={index}
                              selecteditem={undefined}
                              items={item.data}
                              onselected={onDropdownSelected} />
                          )
                        }
                      </div>
                      : userInfo.role === 2 ?
                        <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                          {userInfo.resellername}
                        </label>
                        : userInfo.role === 3 ?
                          <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                            {userInfo.customername}
                          </label>
                          : null}
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "comment", name: "카드번호" }}

                        items={userInfo.role === 1 ?
                          [
                            { key: "comment", name: "카드번호" },
                            { key: "cardnum", name: "내부번호" },
                            { key: "description", name: "카드설명" },
                          ] :
                          [
                            { key: "comment", name: "카드번호" },
                            { key: "description", name: "카드설명" },
                          ]
                        }
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  {userInfo.role === 1 ?
                    <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                      <button
                        className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                        type="button"
                        onClick={() => onRowSelected("card", "")}
                      >
                        추가
                      </button>
                    </div>
                    : null}
                  <div className="flex flex-wrap w-full">
                    <UserCardTable
                      name={"card"}
                      header={header.card}
                      data={dataTable.card}
                      totalCount={rowCount.card}
                      onRowSelected={onRowSelected}
                      onPageChanged={onPageChanged}
                    />
                  </div>

                  <UserCardModal show={showModal.card} data={selectedRow} onSaved={onUpdateModal} onClosed={onClosed} />
                  <CardHistoryModal show={showModal.cardhistory} data={selectedRow} onSaved={onUpdateModal} onClosed={onClosed} />
                </div>

                <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    쿠폰
                  </label>

                  <form id="coupon-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    {userInfo.role === 3 ?
                      <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                        {userInfo.customername}
                      </label>
                      :
                      <>
                        <div className="flex ">
                          {userInfo.resellername &&
                            <div className="flex items-center mb-2 mr-4 text-base font-semibold uppercase text-blueGray-600">
                              {userInfo.resellername}
                            </div>
                          }
                          <div className="flex flex-wrap pr-4 mb-2">
                            {
                              dropdownData.map((item, index) =>
                                <SearchDropdown
                                  key={"SearchDropdown_" + index}
                                  title={item.title}
                                  inx={index}
                                  selecteditem={undefined}
                                  items={item.data}
                                  onselected={onDropdownSelected} />
                              )
                            }
                          </div>
                        </div>
                      </>
                    }
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "pointnum", name: "쿠폰번호" }}
                        items={[
                          { key: "pointnum", name: "쿠폰번호" }
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                    <button
                      className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={() => onRowSelected("coupon", "")}
                    >
                      추가
                    </button>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <CouponTable
                      name={"coupon"}
                      header={header.coupon}
                      data={dataTable.coupon}
                      totalCount={rowCount.coupon}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected}
                    />
                  </div>

                  <CouponModal show={showModal.coupon} data={selectedRow} onSaved={onUpdateModal} onClosed={onClosed} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
