import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

export default function Admin() {

  const [pageTitle, setPageTitle] = useState("");

  const location = useLocation();


  useEffect(() => {
    switch (window.location.pathname) {
      case "/admin":
        //setPageTitle("Dashboard");
        setPageTitle("Reports");
        break;
      case "/admin/profile":
        setPageTitle("Profile");
        break;
      case "/admin/management":
        setPageTitle("Management");
        break;
      case "/admin/reports":
        setPageTitle("Reports");
        break;
      case "/admin/cardcoupon":
        setPageTitle("Card and Coupon");
        break;
      case "/admin/printer":
        setPageTitle("Printers");
        break;
      default:
        setPageTitle("Home");
        break;
    }
  }, [location]);


  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar title={pageTitle} />
        <div className="w-full h-full px-4 mx-auto -m-4 pt-28 md:px-10">
          <Outlet />
        </div>
        <FooterAdmin />
      </div>
    </>
  );
}
