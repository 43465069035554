import React, { useEffect, useRef, useState } from "react";

import { API } from "api/config";
import request from "api/request";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import Alert from "components/Alert/StaticAlert";

export default function ResellerModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

  const [showModal, setShowModal] = useState(show);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);

  const [resellerid, setResellerid] = useState('');
  const [name, setName] = useState('');
  const [gmissionid, setGmissionid] = useState('');
  const [gmissionpw, setGmissionpw] = useState('');
  const [parentid, setParentid] = useState('');

  const [alertData, setAlertData] = useState({ show: false, msg: "" });

  const modalName = "reseller";
  const reselleridRef = useRef();
  const nameRef = useRef();

  useEffect(() => {

    setShowModal(show);

    if (!show) {
      setResellerid('');
      setName('');
      setGmissionid('');
      setGmissionpw('');
      setParentid('');
      setSelectedItem([]);
    }
  }, [show]);

  useEffect(() => {
    // console.log(data);
    // if (data === undefined || data === null || Object.keys(data).length === 0) {
    //   return;
    // }

    console.log('useEffect data', data);

    setResellerid(data.resellerid);
    setName(data.name);
    setGmissionid(data.gmissionid);
    setParentid(data.parentid);

    setSelectedItem([]);

    let selected = [];

    if (data.parentid != undefined && data.parentid.length > 0) {
      selected.push({ field: "reseller", value: data.resellerid, name: data.parentname});
    }

    if (selected.length > 0) {
      setSelectedItem(selected);
    }

    setDropdownData([]);
  }, [showModal]);

  useEffect(() => {
    if(!showModal) {
      return;
    } else {
      if (selectedItem.length > 0 && selectedItem.length >= dropdownData.length) {
        let selected = selectedItem[dropdownData.length - 1];
        if (selected != undefined && selected != null) {
          if (selected.field === "reseller" && selected.value.length > 0) {
            getResellerList(selected.value);
          }
        }
      }
    }

    if(dropdownData.length === 0) {
      getResellerList();
    } 

  }, [dropdownData]);

  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          resellerList.push({
            key: "",
            name: "미정(할당안함)",
            type: "reseller"
          });

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '') {
            let item = {
              "title": "파트너",
              "data": resellerList
            };

            setDropdownData([...dropdownData, item]);
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {

      });
  };

  const isChangedRowData = (oriData, newData) => {
    const keys1 = Object.keys(oriData);
    const keys2 = Object.keys(newData);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const val1 = oriData[key];
      const val2 = newData[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
        return true;
      }
    }

    return false;
  };

  const isObject = (obj) => {
    return obj != null && typeof obj === 'object';
  };

  const onSaveClicked = () => {
    let newData = Object.assign({}, data);
    newData.resellerid = resellerid;
    newData.name = name;
    newData.gmissionid = gmissionid;
    newData.gmissionpw = gmissionpw;
    newData.parentid = selectedItem[0]?.value || '';
    //newData.comment = comment;
    
    if (newData.seq === null || newData.seq === undefined) {
      newData.seq = -1;
    }

    if (newData.resellerid === undefined || newData.resellerid.length === 0) {
      setAlertData({ show: true, msg: "사업자 번호는 필수입력입니다."});
      return reselleridRef.current.focus();
    }
    if (newData.name === undefined || newData.name.length === 0) {
      setAlertData({ show: true, msg: "파트너명은 필수입력입니다."});
      return nameRef.current.focus();
    }
    console.log("reseller update", newData);

    if (isChangedRowData(data, newData)) {
      let url = `${API.USER_RESELLER_UPDATE}`.replace("{seq}", newData.seq.toString());
      new request(url, newData).post(
        (res) => {
          const response = res.data;

          if (response.code === 'success') {
            onSaved(modalName, true);
          } else {
            onSaved(modalName, false);
          }
        },
        (err) => {
          console.log(err);
          onSaved(modalName, false);
        },
        () => {

        });
    } else {
      onClosed(modalName);
    }
  };

  const onDropdownSelected = (type, index, selectedKey, selectedName) => {

    setAlertData();
    
    let selected = [...selectedItem];
    let curDropdown = [...dropdownData];

    let refresh = true;

    if (index < selected.length) {

      if (selected[index].field === type && selected[index].value === selectedKey) {
        refresh = false;
      }

      selected[index].field = type;
      selected[index].value = selectedKey;
    } else {
      selected.push({
        field: type,
        value: selectedKey,
        name: selectedName
      });
    }

    if (refresh) {
      selected = selected.slice(0, index + 1);
      setSelectedItem(selected);
      curDropdown = curDropdown.slice(0, index + 1);
      setDropdownData(curDropdown);
    }
  };

  const getSelectedItem = (index) => {
    if (index < selectedItem.length) {
      return { key: selectedItem[index].value, name: selectedItem[index].name };
    }
    return undefined;
  };

  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={() => { }}
          >
            <div className="relative w-auto max-w-xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">
                    파트너
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClosed(modalName)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative flex-auto p-6 bg-blueGray-100">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          사업자 번호
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={resellerid}
                          ref={reselleridRef}
                          onChange={(e) => {
                            setResellerid(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          파트너명
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={name}
                          ref={nameRef}
                          onChange={(e) => {
                            setName(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          지미션 아이디
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={gmissionid}
                          onChange={(e) => {
                            setGmissionid(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          지미션 비밀번호
                        </label>
                        <input
                          type="password"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          onChange={(e) => {
                            setGmissionpw(e.target.value)
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          상위 파트너
                        </label>
                        <div className="flex flex-wrap w-full pr-4 mb-2">
                          {
                            dropdownData.map((item, index) =>
                              <SearchDropdown
                                key={"SearchDropdown_" + index}
                                title={item.title}
                                inx={index}
                                selecteditem={getSelectedItem(index)}
                                items={item.data}
                                onselected={onDropdownSelected} />
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-3 border-t border-solid rounded-b border-blueGray-200">
                  <div>
                    <Alert data={alertData}></Alert>
                  </div>
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => onClosed(modalName)}>
                    닫기
                  </button>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={onSaveClicked}>
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}