import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const LogoutRoute = () => {
    const isLogined = !!sessionStorage.user;

    console.log("IsLogined2 :", isLogined);

    return isLogined ? <Navigate to={"/admin"}/> : <Outlet/>  
};

export default LogoutRoute;