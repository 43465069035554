import React, { useEffect, useState } from "react";
import moment from "moment";
import Pagination from "./Pagination.js";

export default function ResellerTable({ name = "", header = [], data = [], totalCount = 0, onPageChanged, onRowSelected }) {
  
  const columnKey = header.map((i) => i.value);
  // console.log(columnKey);

  // console.log(data);
  const setRow = () => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push(
        <tr
          key={name + "_tr" + i}
          className={"border-t text-center"}>
            {setColumn(i, data[i])}
          </tr>
      );
    }
    // console.log(result);
    return result;
  };

  const setColumn = (index, item) => {
    const result = [];
    for (let i = 0; i < columnKey.length; i++) {
      if (i === 0) {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"flex items-center p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
              <span className={"font-bold text-blueGray-600 m-auto"}>{item[columnKey[i]]}</span>
          </td>
        );
      } else {
        if (columnKey[i] === 'activetime') {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap text-center"}
              onClick={() => { onRowSelected(name, index) }}>
              {getFormatDate(item[columnKey[i]], "YYYY-MM-DD")}</td>
          );
        } else if (columnKey[i] === 'lastupdate') {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap text-center"}
              onClick={() => { onRowSelected(name, index) }}>
              {getFormatDate(item[columnKey[i]], "YYYY-MM-DD HH:mm:ss")}</td>
          );
        } else {
          result.push(
            <td
              key={name + "_td_" + index + "_" + i}
              className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
              onClick={() => { onRowSelected(name, index) }}>
              {item[columnKey[i]]}</td>
          );
        }
      }
    }
    
    return result;
  };

  const getFormatDate = (date, format) => {
    var d = new Date(date);
    return moment(d).format(format);
  };

  return (
    <>
      <div className={"relative flex flex-col border border-blueGray-200 w-full transition-custom1 min-h-595-px min-w-0 mb-6 break-words bg-white rounded shadow-lg"} >
        <div className={"block w-full overflow-x-auto rounded-t min-h-553-px"}>
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              <tr>
                {
                  header.map((h, index) =>
                    <th
                      key={name + "_h_" + index}
                      className={"px-3 py-3 text-xs font-semibold text-center uppercase align-middle border border-l-0 border-r-0 border-solid whitespace-nowrap bg-blueGray-200 text-blueGray-500 border-blueGray-100"}
                    >
                      {h.text}
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                !data.length && <tr>
                  <td colSpan={columnKey.length} className="items-center p-4 px-4 text-xs text-center align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap" style={{ paddingTop: "140px" }}>
                    <span
                      className="ml-3 font-bold text-blueGray-600"
                    >
                      조회된 리스트가 없습니다.
                    </span>
                  </td>
                </tr>
              }
              {
                setRow()
              }
            </tbody>
          </table>
        </div>
        {
          totalCount > 10 &&
          <Pagination tablename={name} rowcount={totalCount} onPageChanged={onPageChanged} />
        }
      </div>
    </>
  )
};