import React, { useEffect } from "react";

const Alert = ({ data }) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [background, setBackground] = React.useState('amber');

  useEffect(() => {
    if (data !== undefined) {
      setMsg(data.msg);
      setShowAlert(data.show);
      if (data.background) {
        setBackground(data.background);
      }
    } else {
      setShowAlert(false);
    }
  }, [data]);

  return (
    <>
      {showAlert ? (
        <div
          className={"relative px-2 py-3 text-white border-0 rounded bg-" + background + "-500"}
        >
          <span className="inline-block mr-8 text-sm font-semibold align-middle">
            {msg}
          </span>

        </div>
      ) : null}
    </>
  );
};

export default Alert;