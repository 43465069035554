import React from "react";

// components
import FooterSmall from "components/Footers/FooterSmall.js";

// views
import Login from "views/Login.js";

export default function Auth() {
  return (
    <>
      
      <main>
        <section className="relative w-full h-full min-h-screen py-40">
          <div
            className="absolute top-0 w-full h-full bg-no-repeat bg-blueGray-800 bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          >
          </div>

          <Login/>
          
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
