import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom"
import { API } from "../api/config"
import Alert from "components/Alert/StaticAlert";

export default function Login() {

  const [id, setId] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [alertData, setAlertData] = React.useState({show:false, msg:""});  
  

  const navigate = useNavigate();

  const onChangedID = (e) => {
    setId(e.target.value);
    setAlertData();
  };
  const onChangedPW = (e) => {
    setPassword(e.target.value);
    setAlertData();
  };

  const doLogin = (e) => {
    e.preventDefault();

    if (id.length === 0 || password.length === 0) {
      setAlertData({show:true, msg:"아이디 또는 패스워드를 입력해 주세요."});
      return;
    }

    axios.post(`${API.LOGIN}`, {
      "userid": id,
      "password": password
    })
      .then((res) => {
        const response = res.data;

        if (response.code === 'success') {
          const userInfo = {
            seq: response.data.seq,
            userid: response.data.userid,
            displayname: response.data.displayname,
            role: response.data.role,
            resellerid: response.data.resellerid,
            resellername: response.data.resellername,
            customerseq: response.data.customerseq,
            customername: response.data.customername,
            printerrole: response.data.printerrole,
            printerrolename: response.data.printerrolename,
            gmissionid: response.data.gmissionid
          };

          sessionStorage.user = JSON.stringify(userInfo);
          sessionStorage.token = JSON.stringify(response.data.token);
          navigate('/admin');
        } else {
          console.log(JSON.stringify(response));
          setAlertData({show:true, msg:response.message});
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
        setAlertData({show:true, msg:error.message});
      })
      .then(function () {
        // 항상 실행
      });
  };

  return (
    <>
      <div className="container h-full px-4 mx-auto">
        <div className="flex items-center content-center justify-center h-full">
          <div className="w-full px-4 lg:w-4/12">
            <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
              <div className="px-6 py-6 mb-0 rounded-t">
                <div className="mb-3 text-center">
                  <h2 className="text-xl font-bold text-blueGray-600">
                    EP-Pay
                  </h2>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 py-10 pt-0 lg:px-10">

                <form onSubmit={doLogin}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      아이디
                    </label>
                    <input
                      type="text"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="아이디"
                      value={id}
                      onChange={onChangedID}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      비밀번호
                    </label>
                    <input
                      type="password"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="비밀번호"
                      autoComplete="on"
                      value={password}
                      onChange={onChangedPW}
                    />
                  </div>

                  <div className="mt-6 text-center">
                    <button
                      className="w-full px-6 py-3 mb-3 mr-1 text-xl font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                      type="submit"
                    >
                      로그인
                    </button>
                    <Alert data={alertData}></Alert>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
