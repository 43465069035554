/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";


export default function Sidebar() {

  const userInfo = JSON.parse(sessionStorage.user);


  return (
    <>
      <nav className="relative z-10 flex flex-wrap items-center justify-between px-6 py-4 bg-white shadow-xl md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:w-64">
        <div className="flex flex-wrap items-center justify-between w-full px-0 mx-auto md:flex-col md:items-stretch md:min-h-full md:flex-nowrap">

          <Link
            className="inline-block p-4 px-0 mr-0 text-2xl font-bold text-center uppercase md:block md:pb-2 text-blueGray-600 whitespace-nowrap"
            to={sessionStorage.user != null ? "/admin" : "/"}
          >
            EP Pay
          </Link>

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded "
            }
          >

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Navigation */}
            <ul className="flex flex-col list-none md:flex-col md:min-w-full">

              {/** Partner 로그인 시 사이드 바 메뉴 */}
              {/* PROFILE */}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/profile") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/profile"
                >
                  <i
                    className={
                      "mr-2 text-lg fas fa-user-circle " +
                      (window.location.href.indexOf("/admin/profile") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Profile
                </Link>
              </li>

              {userInfo.role === 4 ?
                <>
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/printer") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/printer"
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-base " +
                          (window.location.href.indexOf("/admin/printer") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      PRINTERS
                    </Link>
                  </li>
                </>
                :
                <>
                  {/* MANAGEMENT */}
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/management") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/management"
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-base " +
                          (window.location.href.indexOf("/admin/management") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Management
                    </Link>
                  </li>

                  {/* CARD AND POUPON */}
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/cardcoupon") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/cardcoupon"
                    >
                      <i
                        className={
                          "fas fa-credit-card mr-2 text-base " +
                          (window.location.href.indexOf("/admin/cardcoupon") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Card and Coupon
                    </Link>
                  </li>

                  {/* REPORTS */}
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/reports") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/reports"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-base " +
                          (window.location.href.indexOf("/admin/reports") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Reports
                    </Link>
                  </li>
                </>}


            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
