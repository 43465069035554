import React, { useEffect, useState } from "react";

export default function Pagination({ tablename = "", rowcount, limit = 10, onPageChanged}) {

  const [curIndex, setCurIndex] = useState(1);

  useEffect(() => {
    console.log("useEffect Pagination.rowcount");
    setCurIndex(1);
  }, [rowcount]);

  const numberStyle_selected = "relative flex items-center justify-center w-8 h-8 p-0 mx-1 text-xs font-semibold leading-tight text-white border border-solid rounded-full first:ml-0 border-lightBlue-500 bg-lightBlue-500"
  const numberStyle = "relative flex items-center justify-center w-8 h-8 p-0 mx-1 text-xs font-semibold leading-tight text-lightBlue-500 border border-solid rounded-full first:ml-0 border-lightBlue-500 bg-white"

  const [totalpage, setTotalpage] = useState(0);

  useEffect(() => {
    let pages = parseInt(rowcount / limit);
    if (rowcount % limit > 0) {
      pages += 1;
    }
    setTotalpage(pages);
  }, []);

  const setPageNumber = () => {
    const result = [];


    let startpage = Math.max(1, curIndex - 2);
    let endpage = Math.min(totalpage, startpage + 4);

    for (let i = startpage; i <= endpage; i++) {
      result.push(
        <li key={"page_" + i}>
          <a
            href="#"
            className={i === curIndex ? numberStyle_selected : numberStyle} onClick={() => { onPageClicked(i) }}>
            {i}
          </a>
        </li>
      );
    }

    return result;
  };

  const onPrevJumpClicked = () => {
    if (curIndex > 1) {
      if (curIndex - 3 < 1) {
        setCurIndex(1);
        onPageChanged(tablename, 1);
      } else {
        setCurIndex(curIndex - 3);
        onPageChanged(tablename, curIndex - 3);
      }
    }
  };

  const onPrevClicked = () => {
    if (curIndex > 1) {
      setCurIndex(curIndex - 1);
      onPageChanged(tablename, curIndex - 1);
    }
  };

  const onPageClicked = (index) => {
    if (curIndex !== index) {
      setCurIndex(index);
      onPageChanged(tablename, index);
    }
  };

  const onNextClicked = () => {
    if (curIndex < totalpage) {
      setCurIndex(curIndex + 1);
      onPageChanged(tablename, curIndex + 1);
    }
  };

  const onNextJumpClicked = () => {
    if (curIndex < totalpage) {
      if (curIndex + 3 > totalpage) {
        setCurIndex(totalpage);
        onPageChanged(tablename, totalpage);
      } else {
        setCurIndex(curIndex + 3);
        onPageChanged(tablename, curIndex + 3);
      }
    }
  };

  // TODO : selected style, setPageInx 설정 필요


  return (
    <>
      <div className="relative flex items-center justify-center py-2 mt-8 mb-1">
        <nav className="block">
          <ul className="flex flex-wrap pl-0 list-none rounded">
            {curIndex - 3 >= 1 &&
              <li key={"page_prev_jump"}>
                <a href="#pablo" className={numberStyle} onClick={onPrevJumpClicked}>
                  <i className="-mr-px fas fa-chevron-left"></i>
                  <i className="-mr-px fas fa-chevron-left"></i>
                </a>
              </li>
            }
            <li key={"page_prev"}>
              <a href="#pablo" className={numberStyle} onClick={onPrevClicked}>
                <i className="-ml-px fas fa-chevron-left"></i>
              </a>
            </li>
            {setPageNumber()}
            <li key={"page_next"}>
              <a href="#pablo" className={numberStyle} onClick={onNextClicked}>
                <i className="-mr-px fas fa-chevron-right"></i>
              </a>
            </li>
            {curIndex + 3 <= totalpage &&
              <li key={"page_next_jump"}>
                <a href="#pablo" className={numberStyle} onClick={onNextJumpClicked}>
                  <i className="-mr-px fas fa-chevron-right"></i>
                  <i className="-mr-px fas fa-chevron-right"></i>
                </a>
              </li>
            }
          </ul>
        </nav>
      </div>
    </>
  );
}
