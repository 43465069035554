import React, { useEffect, useState } from "react";
import { API } from "../api/config"
import request from "../api/request"
import { useNavigate } from "react-router-dom"

import Tab from "components/Navbars/Tab";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown"

import Header from "components/Tables/Header.json"
import PrinterTable from "components/Tables/PrinterTable";
import PrinterLicModal from "components/Modals/PrinterLicModal";

export default function Printers() {
    // tab
    const [tabData, setTabdata] = useState([]);
    const [openTab, setOpenTab] = useState(1);

    const [header, setHeader] = useState({ printerlic: [] });
    const [dataTable, setDataTable] = useState({ printerlic: [] });
    const [rowCount, setRowCount] = useState({ printerlic: 0 });
    const [pageIndex, setPageIndex] = useState({ printerlic: 1 });

    // modals
    const [showModal, setShowModal] = useState({ printerlic: false });
    const [selectedRow, setSelectedRow] = useState({});

    const [field, setField] = useState({ printerlic: "A.name" });
    const [value, setValue] = useState('');

    const userInfo = JSON.parse(sessionStorage.user);
    const navigate = useNavigate();

    useEffect(() => {
        //setTableHeader();
        setHeader({ printerlic: Header.printer_lic });
        setTabdata(["프린터 라이센스"])
        //onTabClick(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps


    }, []);

    
    const onFieldChanged = (fieldKey) => {
        let rt = Object.assign({}, field);

        switch (openTab) {
            case 1: rt.printerlic = fieldKey; break;
        }

        setField(rt);
    };

    const startSearch = (e, searcher, pageindex) => {
        let url = "";
        let data = {};
        let searchForm = "";

        let list = Object.assign({}, dataTable);
        let cnt = Object.assign({}, rowCount);

        if (e !== undefined) {
            e.preventDefault();
            searchForm = e.target.id;
        } else {
            searchForm = searcher;
        }

        data.value = value;

        if (pageindex === undefined) {
            // 검색버튼을 통한 조회 시 Pagination 초기화
            pageindex = { payreader: 1, miniserver: 1, cost: 1, printer: 1 };
            cnt[searchForm.replace("-search", "")] = 0;
            setRowCount(cnt);
        }

        url = `${API.DEVICE_PRINTER}`;
        data.field = field.printerlic;
        data.pageindex = pageindex.printerlic;
        data.printerrole = userInfo.printerrole;

        new request(url, data).post(
            (res) => {
                const response = res.data;

                if (response.code === 'success') {
                    switch (searchForm) {
                        case 'printerlic-search': list.printerlic = response.data; cnt.printerlic = response.rowcnt; break;
                    }
                    setDataTable(list);
                    setRowCount(cnt);
                }
            },
            (err) => {
                const errResponse = err.response;
                if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
                    // 조회된 리스트가 없습니다.

                    console.log(errResponse.data.message);

                    switch (searchForm) {
                        case 'printerlic-search': list.printerlic = []; cnt.printerlic = 0; break;
                    }
                    setDataTable(list);
                    setRowCount(cnt);

                } else {
                    // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
                    console.log(errResponse);
                }
            },
            () => {
                // expired login token
                navigate('/login');
            }
        );
    };

    const onPageChanged = (tablename, pageindex) => {
        console.log("onPageChanged", tablename, pageindex);

        let pindex = Object.assign({}, pageIndex);
        pindex[tablename] = pageindex;
        setPageIndex(pindex);

        startSearch(undefined, tablename + '-search', pindex);
    };

    const onRowSelected = (name, index) => {
        let show = { payreader: false, miniserver: false, cost: false, printer: false };
        show[name] = true;

        setShowModal(show); // show 부터 먼저 호출
        setSelectedRow(Object.assign({}, dataTable[name][index]));
    };

    const onUpdatePrinterLic = (isSaved) => {
        if (isSaved) {
            startSearch(undefined, "printerlic-search");
          } else {
            // TODO : alert 
          }
          onClosed("printerlic");
    };

    const onClosed = (modalName) => {
        let show = Object.assign({}, showModal);
        show[modalName] = false;
        setShowModal(show);
    };


    return (<>
        <div className="flex flex-wrap">
            <div className="w-full">

                <Tab data={tabData} onChanged={undefined} />

                <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg min-h-screen-75">
                    <div className="flex-auto px-4 py-5">
                        <div className="tab-content tab-space">
                            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                {/** Pay Reader list table */}

                                <i className="mr-2 fas fa-bars"></i>
                                <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                                    프린터 라이센스 설정
                                </label>

                                <form id="printerlic-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                                    <div className="flex flex-wrap w-full pr-4 mb-2">

                                    </div>
                                    <div className="flex flex-wrap w-full pr-4 mb-2">
                                       
                                    </div>
                                    <div className="flex flex-wrap w-full mb-2">
                                        <NormalDropdown
                                            selecteditem={{ key: "name", name: "이름 (위치)" }}
                                            items={[
                                                { key: "A.name", name: "이름 (위치)" },
                                                { key: "A.model", name: "모델명" },
                                                { key: "A.serial", name: "시리얼 번호" }
                                            ]}
                                            onselected={onFieldChanged} />
                                        <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                                            <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="검색어를 입력하세요."
                                                className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                        >
                                            검색
                                        </button>
                                    </div>
                                </form>

                                <div className="flex flex-wrap w-full">
                                    <PrinterTable
                                        name={"printerlic"}
                                        header={header.printerlic}
                                        data={dataTable.printerlic}
                                        totalCount={rowCount.printerlic}
                                        onPageChanged={onPageChanged}
                                        onRowSelected={onRowSelected} />
                                </div>

                                <PrinterLicModal show={showModal.printerlic} data={selectedRow} onSaved={onUpdatePrinterLic} onClosed={onClosed} />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>);
}