import React, { useEffect, useRef, useState } from "react";

import { API } from "api/config";
import request from "api/request";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import { useNavigate } from "react-router-dom";
import NormalDropdown from "components/Dropdowns/NormalDropdown";
import Alert from "components/Alert/StaticAlert";

export default function CustomerModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

  const [showModal, setShowModal] = useState(show);
  const [selectedItem, setSelectedItem] = useState({});
  const [dropdownData, setDropdownData] = useState({});

  const [displayname, setDisplayname] = useState('');
  const [resellerid, setResellerid] = useState('');
  const [resellername, setResellername] = useState('');

  const [alertData, setAlertData] = useState({ show: false, msg: "" });

  const navigate = useNavigate();
  const displaynameRef = useRef();

  const modalName = "customer";
  const userInfo = JSON.parse(sessionStorage.user);

  useEffect(() => {

    setShowModal(show);

    if (!show) {
      setResellername('');
      setResellerid('');
      setDisplayname('');
      setSelectedItem({});
      setAlertData();
    }
  }, [show]);

  useEffect(() => {
    // console.log(data);
    // if (data === undefined || data === null || Object.keys(data).length === 0) {
    //   return;
    // }

    console.log('useEffect data', data);
    
    setDisplayname(data.displayname);
    setResellerid(data.resellerid);
    setResellername(data.resellername);

    setSelectedItem({});

    let selected = {};

    if (data.resellerid != undefined && data.resellerid.length > 0) {
      // selected.push({ field: "reseller", value: data.resellerid, name: data.resellername});
      selected.field = "reseller";
      selected.value = data.resellerid;
      selected.name = data.resellername;
    }

    if (selected.field) {
      setSelectedItem(selected);
    }
    getResellerList();

  }, [showModal]);
  
  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          if (response.data.length > 1) {
            resellerList.push({
              key: "",
              name: "전체",
              type: "reseller"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '') {
            let item = {
              "title": "선택",
              "data": resellerList
            };

            // setDropdownData([...dropdownData, item]);
            setDropdownData(item);
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {
        navigate('/login');
      });
  };

  const isChangedRowData = (oriData, newData) => {
    const keys1 = Object.keys(oriData);
    const keys2 = Object.keys(newData);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const val1 = oriData[key];
      const val2 = newData[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
        return true;
      }
    }

    return false;
  };

  const isObject = (obj) => {
    return obj != null && typeof obj === 'object';
  };

  const onSaveClicked = () => {
    let newData = Object.assign({}, data);

    newData.displayname = displayname;

    if (displayname === undefined || displayname.length === 0) {
      setAlertData({ show: true, msg: "고객명은 필수입력입니다." });
      return displaynameRef.current.focus();
    }
    newData.resellerid = selectedItem.value;
    if (newData.resellerid === undefined || newData.resellerid.length === 0) {
      setAlertData({ show: true, msg: "소속파트너는 필수입력입니다." });
      return;
    }
    
    console.log(selectedItem);
    if (newData.seq === null || newData.seq === undefined) {
      newData.seq = -1;
    }


    console.log("reseller update", newData);

    if (isChangedRowData(data, newData)) {
      let url = `${API.USER_CUSTOMER_UPDATE}`.replace("{seq}", newData.seq.toString());
      new request(url, newData).post(
        (res) => {
          const response = res.data;

          if (response.code === 'success') {
            onSaved(modalName, true);
          } else {
            onSaved(modalName, false);
          }
        },
        (err) => {
          console.log(err);
          onSaved(modalName, false);
        },
        () => {

        });
    } else {
      onClosed(modalName);
    }
  };

  const onDropdownSelected = (type, index, selectedKey) => {

    setAlertData();

    let selected = Object.assign({}, selectedItem);
    let curDropdown = Object.assign({}, dropdownData);

    let refresh = true;

    // if (selected.length - 1 >= index) {

      if (selected.field === type && selected.value === selectedKey) {
        refresh = false;
      }

      selected.field = type;
      selected.value = selectedKey;
    // } else {
    //   selected.push({
    //     field: type,
    //     value: selectedKey,
    //   });
    // }

    if (refresh) {
      setSelectedItem(selected);
      setDropdownData(curDropdown);
    }
  };
  
  const getSelectedItem = () => {
    // if (selectedItem.value != undefined) {
      return { key: selectedItem.value, name: selectedItem.name };
    // }
    // return { key: undefined, name: undefined };
  };

  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={() => { }}
          >
            <div className="relative w-auto max-w-xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">
                    고객
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClosed(modalName)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative flex-auto p-6 bg-blueGray-100">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          고객명
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={displayname || ""}
                          ref={displaynameRef}
                          onChange={(e) => {
                            setDisplayname(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          소속 파트너
                        </label>
                        {userInfo.role === 1 ?
                        <div className="flex flex-wrap w-full pr-4 mb-2">
                            <SearchDropdown
                              title={dropdownData.title}
                              inx={0}
                              selecteditem={getSelectedItem}
                              items={dropdownData.data}
                              onselected={onDropdownSelected} 
                            />
                        </div>
                        : userInfo.role === 2 ?
                        <label
                          className="block mb-2 ml-1 text-ms font-bold uppercase text-blueGray-600">
                          {userInfo.resellername}
                        </label>
                        : null }
                      </div>
                    </div>
                  </form>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-3 border-t border-solid rounded-b border-blueGray-200">
                  <div>
                    <Alert data={alertData}></Alert>
                  </div>
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => onClosed(modalName)}>
                    닫기
                  </button>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={onSaveClicked}>
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}