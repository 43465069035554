import React, { useEffect, useState } from "react";
import { API } from "../api/config"
import request from "../api/request"

import CardProfile from "components/Cards/CardProfile.js";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown";
import { useNavigate } from "react-router-dom";

import Header from "components/Tables/Header.json"
import ResellerTable from "components/Tables/ResellerTable";
import ResellerModal from "components/Modals/ResellerModal";
import CustomerTable from "components/Tables/CustomerTable";
import UserTable from "components/Tables/UserTable";
import UserModal from "components/Modals/UserModal";

import Alert from "components/Alert/StaticAlert";
import CustomerModal from "components/Modals/CustomerModal";

export default function Profile() {

  // current tab
  const [openTab, setOpenTab] = useState(1);

  // search conition
  const [field, setField] = useState({ reseller: "displayname", customer: "customername", user: "A.userid" });
  const [value, setValue] = useState('');

  // drop down data
  const [selectedItem, setSelectedItem] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdownTo, setDropdownTo] = useState("customer");

  // data table
  const [header, setHeader] = useState({ reseller: [], customer: [], user: [] });
  const [dataTable, setDataTable] = useState({ reseller: [], customer: [], user: [] });
  const [rowCount, setRowCount] = useState({ reseller: 0, customer: 0, user: 0, });
  const [pageIndex, setPageIndex] = useState({ reseller: 1, customer: 1, user: 1 });

  // modals
  const [showModal, setShowModal] = useState({ reseller: false, customer: false, user: false });
  const [selectedRow, setSelectedRow] = useState({});

  // user정보
  const [userDisplayname, setUserDisplayname] = useState('');
  const [userpass, setUserpass] = useState('');
  const [userpassch, setUserpassch] = useState('');
  const [usergmissionid, setUsergmissionid] = useState('');
  const [usergmissionpw, setUsergmissionpw] = useState('');


  const [alertData, setAlertData] = useState({ show: false, msg: "" });

  const userInfo = JSON.parse(sessionStorage.user);
  const navigate = useNavigate();

  console.log("profile")
  useEffect(() => {
    setTableHeader();
    if (userInfo.role === 1) {
      onTabClick(1);
    } else if (userInfo.role === 2) {
      onTabClick(2);
    } else {
      onTabClick(4);
    }
    console.log(userInfo);
    setUserDisplayname(userInfo.displayname);
    setUsergmissionid(userInfo.gmissionid);
  }, []);

  useEffect(() => {
    console.log("dropdata change")

    if (dropdownData.length === 0) {
      if (userInfo.role === 1) {
        getResellerList();
      } else {
        getResellerList(userInfo.resellerid);
      }
    } else {
      if (selectedItem.length > 0 && selectedItem.length === dropdownData.length) {
        let selected = selectedItem[selectedItem.length - 1];
        if (selected.field === "reseller" && selected.value.length > 0) {
          getResellerList(selected.value);
        }
      }
    }

  }, [dropdownData]);

  const onTabClick = (tabIndex) => {
    setOpenTab(tabIndex);

    // 검색창 초기화
    if (tabIndex === 1) {
      setDropdownTo("reseller");
    } else {
      setDropdownTo("customer");
    }

    setValue('');
    setDropdownData([]);
    setSelectedItem([]);
  };

  const setTableHeader = () => {
    setHeader({
      reseller: Header.reseller.filter((c) => c.role === undefined || c.role === userInfo.role),
      customer: Header.customer.filter((c) => c.role === undefined || c.role === userInfo.role),
      user: Header.user.filter((c) => c.role === undefined || c.role === userInfo.role)
    });
  };


  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);
  /**
   * search button event
   */
  const startSearch = (e, searcher, pageindex) => {

    let url = "";
    let data = {};
    let searchForm = "";

    let list = Object.assign({}, dataTable);
    let cnt = Object.assign({}, rowCount);

    if (e !== undefined) {
      e.preventDefault();
      searchForm = e.target.id;
    } else {
      searchForm = searcher;
    }

    for (let i = 0; i < selectedItem.length; i++) {
      if (selectedItem[i].field === "reseller") {
        data.resellerid = selectedItem[i].value;
      } else {
        data.customerseq = selectedItem[i].value;
      }
    }

    if (pageindex === undefined) {
      // 검색버튼을 통한 조회 시 Pagination 초기화
      pageindex = { reseller: 1, customer: 1, user: 1 };
      cnt[searchForm.replace("-search", "")] = 0;
      setRowCount(cnt);
    }

    data.value = value;

    if (searchForm === "reseller-search") {

      url = `${API.USER_RESELLER}`;
      data.field = field.reseller;
      data.pageindex = pageindex.reseller;
      data.parentid = userInfo.resellerid;
    } else if (searchForm === "customer-search") {
      url = `${API.USER_CUSTOMER2}`;
      data.pageindex = pageindex.customer;
      data.field = field.customer;
      data.parentid = userInfo.resellerid;
      data.resellerid = data.resellerid || userInfo.resellerid;
      data.seq = data.customerseq || -1;
    } else {
      url = `${API.USER_LIST}`;
      data.pageindex = pageindex.user;
      data.field = field.user;
      //data.selectedfield = selectedItem[selectedItem.length - 1]?.field || "";
      //data.selectedvalue = selectedItem[selectedItem.length - 1]?.value || "";
    }

    console.log("search data:", data);

    new request(url, data).post(
      (res) => {
        const response = res.data;

        console.log(response);

        if (response.code === 'success') {
          switch (searchForm) {
            case 'reseller-search': list.reseller = response.data; cnt.reseller = response.rowcnt; break;
            case 'customer-search': list.customer = response.data; cnt.customer = response.rowcnt; break;
            case 'user-search': list.user = response.data; cnt.user = response.rowcnt; break;
          }
          setDataTable(list);
          setRowCount(cnt);

        } else if (response.code === "error" && response.message === "no data") {
          // 조회된 리스트가 없습니다.

          switch (searchForm) {
            case 'reseller-search': list.reseller = []; cnt.reseller = 0; break;
            case 'customer-search': list.customer = []; cnt.customer = 0; break;
            case 'user-search': list.user = []; cnt.user = 0; break;
          }
          setDataTable(list);
          setRowCount(cnt);
        }
      },
      (err) => {
        const errResponse = err.response;
        if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
          // 조회된 리스트가 없습니다.

          switch (searchForm) {
            case 'reseller-search': list.reseller = []; cnt.reseller = 0; break;
            case 'customer-search': list.customer = []; cnt.customer = 0; break;
            case 'user-search': list.user = []; cnt.user = 0; break;
          }
          setDataTable(list);
          setRowCount(cnt);

        } else {
          // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
          console.log(errResponse);
        }
      },
      () => {
        // expired login token
      }
    );
  };

  /**
 * search condition
 */
  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          if (response.data.length > 1) {
            resellerList.push({
              key: "",
              name: "전체",
              type: "reseller"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '') {
            let item = {
              "title": "파트너",
              "data": resellerList
            };

            setDropdownData([...dropdownData, item]);
          } else {
            if (dropdownTo === "customer") {
              getCustomerList(parentid, resellerList);
            } else {
              let item = {
                "title": "파트너",
                "data": resellerList
              };

              setDropdownData([...dropdownData, item]);
            }
          }
        } else {

          if (parentid !== undefined && parentid.length > 0) {
            if (dropdownTo === "customer") {
              getCustomerList(parentid);
            }
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {
        navigate('/login');
      });
  };
  const getCustomerList = (resellerid, mergeData) => {

    new request(`${API.USER_CUSTOMER}`, {
      "resellerid": resellerid,
      "pageindex": -1
    }).post(
      (res) => {
        // onSuccess
        const response = res.data;
        if (response.code === 'success') {

          let customerList = [];

          if (mergeData !== undefined) {
            customerList = [...mergeData];
          }

          if (response.data.length > 1 && customerList.length <= 1) {
            customerList.push({
              key: "",
              name: "전체",
              type: "customer"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            customerList.push({
              key: response.data[i]["seq"],
              name: response.data[i]["displayname"],
              type: "customer"
            });
          }

          let item = {
            "title": "고객",
            "data": customerList
          };

          setDropdownData([...dropdownData, item]);

        }
      }, (err) => {
        // onFailed

      }, () => {
        // onExpired login
        navigate('/login');
      });
  };

  const onDropdownSelected = (type, index, selectedKey) => {

    let selected = [...selectedItem];
    let curDropdown = [...dropdownData];

    let refresh = true;

    if (selected.length - 1 >= index) {

      if (selected[index].field === type && selected[index].value === selectedKey) {
        refresh = false;
      }

      selected[index].field = type;
      selected[index].value = selectedKey;
    } else {
      selected.push({
        field: type,
        value: selectedKey
      });
    }

    if (refresh) {
      selected = selected.slice(0, index + 1);
      setSelectedItem(selected);
      curDropdown = curDropdown.slice(0, index + 1);
      setDropdownData(curDropdown);
    }
  };

  const onFieldChanged = (fieldKey) => {
    let rt = Object.assign({}, field);

    switch (openTab) {
      case 1: rt.reseller = fieldKey; break;
      case 2: rt.customer = fieldKey; break;
      case 3: rt.user = fieldKey; break;
      default: rt.reseller = fieldKey; break;
    }

    setField(rt);
  };

  const onPageChanged = (tablename, pageindex) => {
    console.log("onPageChanged", tablename, pageindex);

    let pindex = Object.assign({}, pageIndex);
    pindex[tablename] = pageindex;

    startSearch(undefined, tablename + '-search', pindex);
  };
  const onRowSelected = (name, index) => {
    let show = { reseller: false, customer: false, user: false };
    show[name] = true;

    setShowModal(show); // show 부터 먼저 호출
    setSelectedRow(Object.assign({}, dataTable[name][index]));
  };

  const onClosed = (modalName) => {
    let show = Object.assign({}, showModal);
    show[modalName] = false;
    setShowModal(show);
  };

  /**
 * update
 */
  const onUpdateModal = (modalName, isSaved) => {
    if (isSaved) {
      startSearch(undefined, modalName + "-search");
    } else {
      // TODO : alert 
    }
    onClosed(modalName);
  };

  const onSaveClicked = () => {

    // 파트너 로그인 => 내정보변경

    let newData = {};

    if (userInfo.resellername !== userDisplayname) {
      newData.displayname = userDisplayname;
    }

    if (userpass.length > 0) {
      if (userpass !== userpassch) {
        setAlertData({ show: true, msg: "비밀번호를 확인해주세요." });
        return;
      }
      newData.password = userpass;
    }

    if (usergmissionid.length > 0) {
      if (userInfo.gmissionid !== usergmissionid) {
        newData.gmissionid = usergmissionid;
      }
    }

    if (usergmissionid.length > 0 && usergmissionpw.length > 0) {
      newData.gmissionpw = usergmissionpw;
    }

    if (Object.keys(newData).length === 0) {
      // 변경된 데이터 없음.
      return;
    }

    newData.seq = userInfo.seq;

    console.log("reseller update", newData);

    let url = `${API.USER_UPDATE}`.replace("{seq}", newData.seq.toString());
    new request(url, newData).post(
      (res) => {
        const response = res.data;

        if (response.code === 'success') {
          // onSaved(true);
          console.log("변경성공")
          console.log("이름 변경?? ", userDisplayname != userInfo.dispalyname);

          let user = Object.assign({}, userInfo);
          if (userDisplayname != userInfo.dispalyname) {
            user.displayname = userDisplayname;
          }
          if (usergmissionid != userInfo.gmissionid) {
            user.gmissionid = usergmissionid;
          }

          sessionStorage.user = JSON.stringify(user);

          setAlertData({ show: true, msg: "변경 완료되었습니다." });
        } else {
          // onSaved(false);
          setAlertData({ show: true, msg: "변경하지 못했습니다." });
        }
      },
      (err) => {
        console.log(err);
        // onSaved(false);
        setAlertData({ show: true, msg: err.message });
      },
      () => {

      });
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex flex-row flex-wrap pt-3 pb-4 mb-0 list-none"
            role="tablist"
          >
            {userInfo.role === 1 &&
              <>
                <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 1
                        ? "text-white bg-lightBlue-600"
                        : "text-lightBlue-600 bg-white")
                    }
                    onClick={e => {
                      e.preventDefault();
                      onTabClick(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    파트너 관리
                  </a>
                </li>
                <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 2
                        ? "text-white bg-lightBlue-600"
                        : "text-lightBlue-600 bg-white")
                    }
                    onClick={e => {
                      e.preventDefault();
                      onTabClick(2);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    고객 관리
                  </a>
                </li>
                <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                      (openTab === 3
                        ? "text-white bg-lightBlue-600"
                        : "text-lightBlue-600 bg-white")
                    }
                    onClick={e => {
                      e.preventDefault();
                      onTabClick(3);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    로그인 관리
                  </a>
                </li>
              </>
            }
            {userInfo.role === 2 &&
              <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-lightBlue-600"
                      : "text-lightBlue-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    onTabClick(2);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  고객 관리
                </a>
              </li>
            }
            <li className="flex-auto mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-lightBlue-600"
                    : "text-lightBlue-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  onTabClick(4);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                정보 변경
              </a>
            </li>
          </ul>
          {/* : "" } */}

          {/* 탭 변경했을 때 보이는 화면 */}
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg min-h-screen-75">
            <div className="flex-auto px-4 py-5">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    파트너 관리
                  </label>

                  <form id="reseller-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      {
                        dropdownData.map((item, index) =>
                          <SearchDropdown
                            key={"SearchDropdown_" + index}
                            title={item.title}
                            inx={index}
                            selecteditem={undefined}
                            items={item.data}
                            onselected={onDropdownSelected} />
                        )
                      }
                    </div>
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "displayname", name: "이름" }}
                        items={[
                          { key: "displayname", name: "이름" },
                          { key: "resellerid", name: "사업자 번호" },
                          { key: "gmissionid", name: "지미션 계정" },
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                    <button
                      className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={() => onRowSelected("reseller", "")}
                    >
                      추가
                    </button>
                  </div>

                  <div className="flex flex-wrap w-full">
                    <ResellerTable
                      name={"reseller"}
                      header={header.reseller}
                      data={dataTable.reseller}
                      totalCount={rowCount.reseller}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected}
                    />
                  </div>

                  <ResellerModal show={showModal.reseller} data={selectedRow} onSaved={onUpdateModal} onClosed={onClosed} />
                </div>

                <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    고객 관리
                  </label>

                  <form id="customer-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex ">
                      {userInfo.resellername &&
                        <div className="flex items-center mb-2 mr-4 text-base font-semibold uppercase text-blueGray-600">
                          {userInfo.resellername}
                        </div>
                      }
                      {userInfo.role === 1 &&
                        <div className="flex flex-wrap w-full pr-4 mb-2">
                          {
                            dropdownData.map((item, index) =>
                              <SearchDropdown
                                key={"SearchDropdown_" + index}
                                title={item.title}
                                inx={index}
                                selecteditem={undefined}
                                items={item.data}
                                onselected={onDropdownSelected} />
                            )
                          }
                        </div>}
                    </div>
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "customername", name: "고객명" }}
                        items={[
                          { key: "customername", name: "고객명" },
                          { key: "resellername", name: "파트너명" },
                          // { key: "gmissionid", name: "지미션 계정" },
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                    <button
                      className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={() => onRowSelected("customer", "")}
                    >
                      추가
                    </button>
                  </div>

                  <div className="flex flex-wrap w-full">
                    <CustomerTable
                      name={"customer"}
                      header={header.customer}
                      data={dataTable.customer}
                      totalCount={rowCount.customer}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected}
                    />
                  </div>

                  <CustomerModal show={showModal.customer} data={selectedRow} onSaved={onUpdateModal} onClosed={onClosed} />
                </div>

                <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    사용자 관리
                  </label>

                  <form id="user-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      {
                        dropdownData.map((item, index) =>
                          <SearchDropdown
                            key={"SearchDropdown_" + index}
                            title={item.title}
                            inx={index}
                            selecteditem={undefined}
                            items={item.data}
                            onselected={onDropdownSelected} />
                        )
                      }
                    </div>
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "userid", name: "아이디" }}
                        items={[
                          { key: "A.userid", name: "아이디" },
                          { key: "A.displayname", name: "이름" },
                          { key: "D.resellerid", name: "사업자 번호" }
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                    <button
                      className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal({ reseller: false, customer: false, user: true });
                        setSelectedRow({ seq: -1 });
                      }}
                    >
                      추가
                    </button>
                  </div>

                  <div className="flex flex-wrap w-full">
                    <UserTable
                      name={"user"}
                      header={header.user}
                      data={dataTable.user}
                      totalCount={rowCount.user}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected}
                    />
                  </div>

                  <UserModal show={showModal.user} data={selectedRow} onSaved={onUpdateModal} onClosed={onClosed} />
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link1">
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    정보 변경
                  </label>
                  <div className="flex w-full mb-5">
                    <div className="w-1/8">
                      <label
                        className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                        아이디
                      </label>
                    </div>
                    <label
                      className="block px-3 py-3 text-sm font-bold text-blueGray-600">
                      {userInfo.userid}
                    </label>
                  </div>
                  <div className="flex w-full mb-5">
                    <div className="w-1/8">
                      <label
                        className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                        이름
                      </label>
                    </div>
                    <input
                      type="text"
                      className="block px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      value={userDisplayname || ""}
                      onChange={(e) => {
                        setUserDisplayname(e.target.value);
                        setAlertData();
                      }}
                    />
                  </div>
                  <div className="flex w-full mb-5">
                    <div className="w-1/8">
                      <label
                        className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600"
                        pl>
                        비밀번호
                      </label>
                    </div>
                    <input
                      type="password"
                      className="block px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="변경시에만 입력해주세요."
                      onChange={(e) => {
                        setUserpass(e.target.value);
                        setAlertData();
                      }}
                    />
                  </div>
                  <div className="flex w-full mb-5">
                    <div className="w-1/8">
                      <label
                        className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                        비밀번호 확인
                      </label>
                    </div>
                    <input
                      type="password"
                      className="block px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="변경시에만 입력해주세요."
                      onChange={(e) => {
                        setUserpassch(e.target.value);
                        setAlertData();
                      }}
                    />
                  </div>
                  {userInfo.role === 3 ?
                    <>
                      <div className="flex w-full mb-5">
                        <div className="w-1/8">
                          <label
                            className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                            소속 고객
                          </label>
                        </div>
                        <label
                          className="block px-3 py-3 text-sm font-bold text-blueGray-600">
                          {userInfo.customername}
                        </label>
                      </div>
                    </>
                    : null}
                  {userInfo.role === 2 ?
                    <>
                      <div className="flex w-full mb-5">
                        <div className="w-1/8">
                          <label
                            className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                            소속 파트너
                          </label>
                        </div>
                        <label
                          className="block px-3 py-3 text-sm font-bold text-blueGray-600">
                          {userInfo.resellername}
                        </label>
                      </div>
                      <div className="flex w-full mb-5">
                        <div className="w-1/8">
                          <label
                            className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                            지미션 아이디
                          </label>
                        </div>
                        <input
                          type="text"
                          className="block px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={usergmissionid || ""}
                          onChange={(e) => {
                            setUsergmissionid(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="flex w-full mb-5">
                        <div className="w-1/8">
                          <label
                            className="block px-3 py-3 text-sm font-bold uppercase text-blueGray-600">
                            지미션 비밀번호
                          </label>
                        </div>
                        <input
                          type="password"
                          className="block px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          placeholder="변경시에만 입력해주세요."
                          onChange={(e) => {
                            setUsergmissionpw(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                    </>
                    : null}
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid rounded-b border-blueGray-200">
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={onSaveClicked} >
                    저장
                  </button>
                </div>
                <div>
                  <Alert data={alertData}></Alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // <>
    //   <div className="flex flex-wrap">
    //     <div className="w-full h-full">
    //       <CardProfile />
    //     </div>
    //   </div>
    // </>
  );
}
