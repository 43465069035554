import React, { useEffect, useRef, useState } from "react";

import { API } from "api/config";
import request from "api/request";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import { useNavigate } from "react-router-dom";
import NormalDropdown from "components/Dropdowns/NormalDropdown";
import Alert from "components/Alert/StaticAlert";

export default function UserModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

  const [showModal, setShowModal] = useState(show);
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState([]);

  const [userid, setUserid] = useState('');
  const [displayname, setDisplayname] = useState('');
  const [resellerid, setResellerid] = useState('');
  const [resellername, setResellername] = useState('');
  const [customerseq, setCustomerseq] = useState('');
  const [customername, setCustomername] = useState('');
  const [printerRole, setPrinterRole] = useState('');
  const [printerRolename, setPrinterRolename] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(0);
  const [roleName, setRoleName] = useState('선택');

  const [alertData, setAlertData] = useState({ show: false, msg: "" });

  const navigate = useNavigate();

  const useridRef = useRef();
  const displaynameRef = useRef();

  const modalName = "user";

  useEffect(() => {

    setShowModal(show);

    if (!show) {
      // Clear modal
      setUserid('');
      setResellername('');
      setCustomername('');
      setCustomerseq('');
      setResellerid('');
      setDisplayname('');
      setPassword('');
      setRole(0);
      setRoleName('선택');
      setAlertData();
      setSelectedDropdown([]);
    }
  }, [show]);

  useEffect(() => {
    if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
      return;
    }

    console.log('useEffect data', data);

    if (data.seq !== -1) {
      setUserid(data.userid);
      setDisplayname(data.displayname);
      setResellerid(data.resellerid);
      setResellername(data.resellername);
      setCustomername(data.customername);
      setCustomerseq(data.customerseq);
      setPrinterRole(data.printerrole);
      setPrinterRolename(data.printerrolename);
      setRole(data.role);

      switch (data.role) {
        case 1: setRoleName('관리자'); break;
        case 2: setRoleName('파트너'); break;
        case 3: setRoleName('고객'); break;
        case 4: setRoleName('프린터'); break;
      }
    }
  }, [showModal]);

  useEffect(() => {

    if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
      return;
    }

    console.log("useEffect role", role);

    let selected = [];

    setSelectedDropdown([]);
    setDropdownData([]);

    switch (role) {
      case 1: break;
      case 2:
        if (data.resellerid !== undefined && data.resellerid.length > 0) {
          selected.push({
            field: 'reseller',
            value: data.resellerid,
            name: data.resellername
          });
          setSelectedDropdown(selected);
        }
        getResellerList();
        break;
      case 3:
        if (data.resellerid !== undefined && data.resellerid.length > 0 && data.customerseq > 0) {
          selected.push({
            field: 'reseller',
            value: data.resellerid,
            name: data.resellername
          });
          selected.push({
            field: 'customer',
            value: data.customerseq,
            name: data.customername
          });

          setSelectedDropdown(selected);
          getResellerList(data.resellerid);
        } else {
          getResellerList();
        }
        break;
      case 4:
        if (data.printerrole > 0) {
          selected.push({
            field: 'printerrole',
            value: data.printerrole,
            name: data.printerrolename
          });
          setSelectedDropdown(selected);
        }
        getPrinterRoleList();
        break;
    }
  }, [role]);

  const getResellerList = (selectedResellerid) => {

    new request(`${API.USER_RESELLER}`, {
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          let item = {
            "title": "선택",
            "data": resellerList
          };

          if (selectedResellerid !== undefined && selectedResellerid.length > 0) {
            getCustomerList(selectedResellerid, item);
          } else {
            setDropdownData([item]);
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {
        navigate('/login');
      });
  };

  const getCustomerList = (resellerid, mergeData) => {

    new request(`${API.USER_CUSTOMER}`, {
      "resellerid": resellerid,
      "pageindex": -1
    }).post(
      (res) => {
        // onSuccess
        const response = res.data;
        if (response.code === 'success') {

          let customerList = [];

          for (let i = 0; i < response.data.length; i++) {
            customerList.push({
              key: response.data[i]["seq"],
              name: response.data[i]["displayname"],
              type: "customer"
            });
          }

          let item = {
            "title": "선택",
            "data": customerList
          };

          // setDropdownData([...dropdownData, item]);
          if (mergeData !== undefined) {
            mergeData.push(item);
            setDropdownData([...mergeData]);
          } else {
            setDropdownData([...dropdownData, item]);
          }
        }
      }, (err) => {
        // onFailed

      }, () => {
        // onExpired login
        navigate('/login');
      });
  };

  const getPrinterRoleList = () => {
    new request(`${API.USER_PRINTER_ROLE_LIST}`, {
     }).post(
      (res) => {
        // onSuccess
        const response = res.data;
        if (response.code === 'success') {

          let printerroleList = [];

          for (let i = 0; i < response.data.length; i++) {
            printerroleList.push({
              key: response.data[i]["seq"],
              name: response.data[i]["name"],
              type: "printerrole"
            });
          }

          let item = {
            "title": "선택",
            "data": printerroleList
          };

          setDropdownData([item]);
        }
      }, (err) => {
        // onFailed

      }, () => {
        // onExpired login
        navigate('/login');
      });
  };

  const isChangedRowData = (oriData, newData) => {
    const keys1 = Object.keys(oriData);
    const keys2 = Object.keys(newData);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const val1 = oriData[key];
      const val2 = newData[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
        return true;
      }
    }

    return false;
  };

  const onRoleChanged = (fieldKey) => {

    if (role === fieldKey) {
      return;
    }

    setRole(fieldKey);
    switch (fieldKey) {
      case 1: setRoleName('관리자'); break;
      case 2: setRoleName('파트너'); break;
      case 3: setRoleName('고객'); break;
      case 4: setRoleName('프린터'); break;
    }
    setAlertData();
  };

  const isObject = (obj) => {
    return obj != null && typeof obj === 'object';
  };

  const onSaveClicked = () => {

    if (userid === undefined || userid.length === 0) {
      setAlertData({ show: true, msg: "아이디는 필수입력입니다." });
      return useridRef.current.focus();
    }

    if (displayname === undefined || displayname.length === 0) {
      setAlertData({ show: true, msg: "이름은 필수입력입니다." });
      return displaynameRef.current.focus();
    }

    let newData = Object.assign({}, data);
    newData.userid = userid;
    newData.displayname = displayname;
    if (password.length > 0) {
      newData.password = password;
    }
    newData.role = role;

    for (let i = 0; i < selectedDropdown.length; i++) {
      if (selectedDropdown[i].field === 'reseller') {
        newData.resellerid = selectedDropdown[i].value;
      } else if (selectedDropdown[i].field === 'customer') {
        newData.customerseq = selectedDropdown[i].value;
      } else if (selectedDropdown[i].field === 'printerrole') {
        newData.printerrole = selectedDropdown[i].value;
      }
    }

    if (role === 2 && newData.resellerid.length === 0) {
      setAlertData({ show: true, msg: "파트너를 선택해 주세요." });
      return displaynameRef.current.focus();
    } else if (role === 3 && newData.customerseq <= 0) {
      setAlertData({ show: true, msg: "고객을 선택해 주세요." });
      return displaynameRef.current.focus();
    } else if (role === 4 && newData.printerseq <= 0) {
      setAlertData({ show: true, msg: "프린터 관리를 선택해 주세요." });
      return displaynameRef.current.focus();
    }

    if (newData.seq === null || newData.seq === undefined) {
      newData.seq = -1;
    }


    console.log("reseller update", newData);

    if (isChangedRowData(data, newData)) {
      let url = `${API.USER_UPDATE}`.replace("{seq}", newData.seq.toString());
      new request(url, newData).post(
        (res) => {
          const response = res.data;

          if (response.code === 'success') {
            onSaved(modalName, true);
          } else {
            onSaved(modalName, false);
          }
        },
        (err) => {
          console.log(err);
          onSaved(modalName, false);
        },
        () => {

        });
    } else {
      onClosed(modalName);
    }
  };

  const onDropdownSelected = (type, index, selectedKey, selectedName) => {
    setAlertData();

    let selected = [...selectedDropdown];
    let curDropdown = [...dropdownData];

    switch (role) {
      case 2:
        if (selected.length === 0) {
          selected.push({
            field: 'reseller',
            value: selectedKey,
            name: selectedName
          });
        } else {
          selected[index].field = 'reseller';
          selected[index].value = selectedKey;
          selected[index].name = selectedName;
          selected = selected.slice(0, 1);
        }
        setSelectedDropdown(selected);
        break;
      case 3:
        if (index === 0) {
          // reseller select
          if (selected.length === 0) {
            selected.push({
              field: 'reseller',
              value: selectedKey,
              name: selectedName
            });
          } else {
            selected[index].field = 'reseller';
            selected[index].value = selectedKey;
            selected[index].name = selectedName;
            if (selected.length > 1) {
              selected = selected.slice(0, index + 1);
            }
          }
          setSelectedDropdown(selected);

          if (dropdownData.length < 2) {
            getCustomerList(selectedKey);
          } else {
            curDropdown = curDropdown.slice(0, index + 1);
            getCustomerList(selectedKey, curDropdown);
          }
        } else {
          if (selected.length === 1) {
            selected.push({
              field: 'customer',
              value: selectedKey,
              name: selectedName
            });
          } else {
            selected[index].field = 'customer';
            selected[index].value = selectedKey;
            selected[index].name = selectedName;
          }
          setSelectedDropdown(selected);
        }
        break;
      case 4:
        if (selected.length === 0) {
          selected.push({
            field: 'printerrole',
            value: selectedKey,
            name: selectedName
          });
        } else {
          selected[index].field = 'printerrole';
          selected[index].value = selectedKey;
          selected[index].name = selectedName;
          selected = selected.slice(0, 1);
        }
        break;
    }
  };

  const onPrinterRoleSelected = (selectedKey, selectedName) => {
    setAlertData();

    let selected = [...selectedDropdown];
    
    if (selected.length === 0) {
      selected.push({
        field: 'printerrole',
        value: selectedKey,
        name: selectedName
      });
    } else {
      selected[0].field = 'printerrole';
      selected[0].value = selectedKey;
      selected[0].name = selectedName;
      selected = selected.slice(0, 1);
    }
    setSelectedDropdown(selected);
  };


  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={() => { }}
          >
            <div className="relative w-auto max-w-xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">
                    사용자
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClosed(modalName)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative flex-auto p-6 bg-blueGray-100">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          아이디
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={userid || ""}
                          ref={useridRef}
                          readOnly={data.userid ? true : false}
                          onChange={(e) => {
                            setUserid(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          이름
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={displayname || ""}
                          ref={displaynameRef}
                          onChange={(e) => {
                            setDisplayname(e.target.value)
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          비밀번호
                        </label>
                        <input
                          type="password"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setAlertData();
                          }}
                          placeholder="변경시에만 입력해주세요"
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          구분
                        </label>
                        <div className="flex flex-wrap w-full pr-4 mb-2">
                          <NormalDropdown
                            selecteditem={{ key: role, name: roleName }}
                            items={[
                              { key: 1, name: "관리자" },
                              { key: 2, name: "파트너" },
                              { key: 3, name: "고객" },
                              { key: 4, name: "프린터" }
                            ]}
                            onselected={onRoleChanged}
                          />
                        </div>
                      </div>

                      {role !== 1 &&
                        <div className="w-full mb-5">
                          <label
                            className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                            {roleName}
                          </label>
                          <div className="flex flex-wrap w-full pr-4 mb-2">
                            {
                              role !== 4 ?
                                dropdownData.map((item, index) =>
                                  <SearchDropdown
                                    key={"SearchDropdown_" + index}
                                    title={item.title}
                                    inx={index}
                                    selecteditem={
                                      {
                                        key: selectedDropdown[index] === undefined ? undefined : selectedDropdown[index].value,
                                        name: selectedDropdown[index] === undefined ? undefined : selectedDropdown[index].name
                                      }}
                                    items={item.data}
                                    onselected={onDropdownSelected} />
                                )
                                :
                                dropdownData.map((item, index) =>
                                  <NormalDropdown
                                    key={"NormalDropdown_" + index}
                                    title={item.title}
                                    inx={index}
                                    selecteditem={{
                                      key: selectedDropdown[index] === undefined ? undefined : selectedDropdown[index].value,
                                      name: selectedDropdown[index] === undefined ? undefined : selectedDropdown[index].name
                                    }}
                                    items={item.data}
                                    onselected={onPrinterRoleSelected} />
                                )
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </form>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-3 border-t border-solid rounded-b border-blueGray-200">
                  <div>
                    <Alert data={alertData}></Alert>
                  </div>
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => onClosed(modalName)}>
                    닫기
                  </button>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={onSaveClicked}>
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}