import React from "react";

export default function PrinterTable({ classname = "", label = "", icon = "circle", status = false, text = "|" }) {

  const statusList = text.split("|");

  const circleColor = (status ? "emerald" : "orange");
  const msg = (status ? statusList[0] : statusList[1]);

  return (
    <>
      <p className={classname}>
        {label}
        {icon === "circle" && <i className={"mr-2 fas fa-circle text-" + circleColor + "-500"}></i>}
        {msg}
      </p>
    </>
  );
}
