import { useRef } from "react";
import { useState, useEffect } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import Alert from "components/Alert/StaticAlert";

import { API } from "api/config";
import request from "api/request";


export default function PayReaderModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

  const modalName = "payreader";
  
  const userInfo = JSON.parse(sessionStorage.user);

  const [showModal, setShowModal] = useState(show);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);

  const [locationname, setLocationname] = useState('');
  const [privateip, setPrivateip] = useState('');
  const [tid, setTid] = useState('');
  const [serial, setSerial] = useState('');
  const [comment, setComment] = useState('');

  const locationRef = useRef();
  const privateipRef = useRef();
  const tidRef = useRef();
  const serialRef = useRef();

  

  const [alertData, setAlertData] = useState({ show: false, msg: "" });

  useEffect(() => {
    setShowModal(show);

    if (!show) {
      setLocationname('');
      setPrivateip('');
      setTid('');
      setSerial('');
      setComment('')
      setSelectedItem([]);
      setAlertData({});
    }

  }, [show]);

  useEffect(() => {
    if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
      return;
    }

    console.log('useEffect data', data);

    if (data.seq !== -1) {
      setLocationname(data.locationname);
      setPrivateip(data.privateip);
      setTid(data.tid);
      setSerial(data.kiccserial);
      setComment(data.comment || "");
    }

    let selected = [];

    if (data.resellerid !== undefined && data.resellerid.length > 0 && userInfo.resellerid !== data.resellerid) {
      selected.push({ field: "reseller", value: data.resellerid, name: data.resellername });
    }
    if (data.customerseq !== undefined && data.customerseq > 0) {
      selected.push({ field: "customer", value: data.customerseq, name: data.customername });
    }

    if (selected.length > 0) {
      setSelectedItem(selected);
    }

    setDropdownData([]);

  }, [showModal]);

  useEffect(() => {

    if (!showModal) {
      return;
    }

    if (dropdownData.length === 0) {
      if (userInfo.role === 1) {
        getResellerList();
      } else if (userInfo.role === 2) {
        getResellerList(userInfo.resellerid);
      }
    } else {
      if (selectedItem.length > 0 && selectedItem.length >= dropdownData.length) {
        let selected = selectedItem[dropdownData.length - 1];
        if (selected.field === "reseller" && selected.value.length > 0) {
          getResellerList(selected.value);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [dropdownData]);

  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          resellerList.push({
            key: "",
            name: "미정(할당안함)",
            type: "reseller"
          });

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '') {
            let item = {
              "title": "파트너",
              "data": resellerList
            };

            setDropdownData([...dropdownData, item]);
          } else {
            getCustomerList(parentid, resellerList);
          }
        } else {
          if (parentid !== undefined && parentid.length > 0) {
            getCustomerList(parentid);
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {

      });
  };
  const getCustomerList = (resellerid, mergeData) => {

    new request(`${API.USER_CUSTOMER}`, {
      "resellerid": resellerid,
      "pageindex": -1
    }).post(
      (res) => {
        // onSuccess
        const response = res.data;
        if (response.code === 'success') {

          let customerList = [];

          if (mergeData !== undefined) {
            customerList = [...mergeData];
          }

          if (customerList <= 1) {
            customerList.push({
              key: -1,
              name: "미정(할당안함)",
              type: "customer"
            });
          }


          for (let i = 0; i < response.data.length; i++) {
            customerList.push({
              key: response.data[i]["seq"],
              name: response.data[i]["displayname"],
              type: "customer"
            });
          }

          let item = {
            "title": "고객",
            "data": customerList
          };

          setDropdownData([...dropdownData, item]);

        }
      }, (err) => {
        // onFailed

      }, () => {
        // onExpired login

      });
  };
  const onDropdownSelected = (type, index, selectedKey, selectedName) => {

    let selected = [...selectedItem];
    let curDropdown = [...dropdownData];

    let refresh = true;

    if (index < selected.length) {

      if (selected[index].field === type && selected[index].value === selectedKey) {
        refresh = false;
      }

      selected[index].field = type;
      selected[index].value = selectedKey;
    } else {
      selected.push({
        field: type,
        value: selectedKey,
        name: selectedName
      });
    }

    if (refresh) {
      selected = selected.slice(0, index + 1);
      setSelectedItem(selected);
      curDropdown = curDropdown.slice(0, index + 1);
      setDropdownData(curDropdown);
    }
  };

  const getSelectedItem = (index) => {
    if (index < selectedItem.length) {
      return { key: selectedItem[index].value, name: selectedItem[index].name };
    }
    return undefined;
  };

  const onSaveClicked = () => {

    let newData = Object.assign({}, data);

    if (locationname.length === 0) {
      setAlertData({ show: true, msg: "이름(위치정보)은 필수입력입니다." });
      return locationRef.current.focus();
    }

    if (privateip.length === 0) {
      setAlertData({ show: true, msg: "IP주소는 필수입력입니다." });
      return privateipRef.current.focus();
    }

    if (tid.length === 0) {
      setAlertData({ show: true, msg: "TID는 필수입력입니다." });
      return tidRef.current.focus();
    }

    if (serial.length === 0) {
      setAlertData({ show: true, msg: "시리얼번호는 필수입력입니다." });
      return serialRef.current.focus();
    }

    newData.locationname = locationname;
    newData.privateip = privateip;
    newData.tid = tid;
    newData.kiccserial = serial;
    newData.comment = comment;

    let selectedReseller = "";
    let selectedCustomer = -1;
    for (let i = selectedItem.length - 1; i >= 0; i--) {
      if (selectedItem[i].field === "reseller") {
        selectedReseller = (selectedReseller.length === 0) ? selectedItem[i].value : selectedReseller;
      } else {
        selectedCustomer = selectedItem[i].value;
      }
    }

    if (userInfo.role === 2 && selectedReseller.length === 0) {
      selectedReseller = userInfo.resellerid;
    }
    newData.resellerid = selectedReseller;
    newData.customerseq = selectedCustomer;

    if (newData.resellerid === null || newData.resellerid.length === 0 || newData.customerseq === undefined) {
      newData.customerseq = -1;
    }

    console.log("payreader update", newData);

    if (isChangedRowData(data, newData)) {
      let url = `${API.DEVICE_PAYREADER_UPDATE}`.replace("{seq}", newData.seq.toString());
      new request(url, newData).post(
        (res) => {
          const response = res.data;
          if (response.code === 'success') {
            onSaved(true);
          } else {
            onSaved(false);
          }
        },
        (err) => {
          console.log(err);
          onSaved(false);
        },
        () => {

        });
    } else {
      onClosed(modalName);
    }
  };

  const isChangedRowData = (oriData, newData) => {
    const keys1 = Object.keys(oriData);
    const keys2 = Object.keys(newData);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const val1 = oriData[key];
      const val2 = newData[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
        return true;
      }
    }

    return false;
  };

  const isObject = (obj) => {
    return obj != null && typeof obj === 'object';
  };

  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={() => { }}
          >
            <div className="relative w-auto max-w-xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">
                    결제 단말기 정보
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClosed(modalName)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6 bg-blueGray-100">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          이름 (위치정보)
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-350-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          style={{ width: '300px' }}
                          ref={locationRef}
                          value={locationname}
                          onChange={(e) => {
                            setLocationname(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          IP주소 (내부)
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          ref={privateipRef}
                          value={privateip}
                          onChange={(e) => {
                            setPrivateip(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          tid
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          ref={tidRef}
                          value={tid}
                          onChange={(e) => {
                            setTid(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          serial
                        </label>
                        <input
                          type="text"
                          className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          ref={serialRef}
                          value={serial}
                          onChange={(e) => {
                            setSerial(e.target.value);
                            setAlertData();
                          }}
                        />
                      </div>
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          파트너 / 고객
                        </label>
                        <div className="flex flex-wrap w-full pr-4 mb-2">
                          {userInfo.role === 2 &&
                            <h3 className="px-3 pt-2 text-sm font-semibold">
                              {userInfo.resellername}
                            </h3>
                          }
                          {
                            dropdownData.map((item, index) =>
                              <SearchDropdown
                                key={"SearchDropdown_" + index}
                                title={item.title}
                                inx={index}
                                selecteditem={getSelectedItem(index)}
                                items={item.data}
                                onselected={onDropdownSelected} />
                            )
                          }
                        </div>
                      </div>
                      <div className="w-full">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          비고 (코멘트)
                        </label>
                        <textarea
                          rows={5}
                          type="text"
                          className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-3 px-6 border-t border-solid rounded-b border-blueGray-200">
                  <div>
                    <Alert data={alertData}></Alert>
                  </div>
                  <div className="float-right">
                    <button
                      className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                      type="button"
                      onClick={() => onClosed(modalName)}>
                      닫기
                    </button>
                    <button
                      className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={onSaveClicked}>
                      저장
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}