import React, { useEffect, useState } from "react";
import { API } from "../api/config"
import request from "../api/request"
import { useNavigate, useSearchParams } from "react-router-dom"

import Header from "components/Tables/Header.json"
import PayReaderTable from "components/Tables/PayReaderTable.js";
import AndroidBoxTable from "components/Tables/AndroidBoxTable.js";
import CostTable from "components/Tables/CostTable.js";
import PrinterTable from "components/Tables/PrinterTable.js";

import CalendarDropdown from "components/Dropdowns/CalendarDropdown";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown";

import PayReaderModal from "components/Modals/PayReaderModal";
import MiniServerModal from "components/Modals/MiniServerModal";
import CostModal from "components/Modals/CostModal";
import PrinterModal from "components/Modals/PrinterModal";
import Tab from "components/Navbars/Tab";

export default function Management() {

  // tab
  const [tabData, setTabdata] = useState([]);
  const [openTab, setOpenTab] = useState(1);

  // search conition
  const [from, setFrom] = useState(new Date().setDate(new Date().getDate() - 7));
  const [to, setTo] = useState(Date.now());
  const [field, setField] = useState({ payreader: "locationname", miniserver: "managecode", cost: "name", printer: "A.name" });
  const [value, setValue] = useState('');

  // drop down data
  const [selectedItem, setSelectedItem] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdownTo, setDropdownTo] = useState("customer");

  // data table
  const [header, setHeader] = useState({ payreader: [], miniserver: [], cost: [], printer: [] });
  const [dataTable, setDataTable] = useState({ payreader: [], miniserver: [], cost: [], printer: [] });
  const [rowCount, setRowCount] = useState({ payreader: 0, miniserver: 0, cost: 0, printer: 0 });
  const [pageIndex, setPageIndex] = useState({ payreader: 1, miniserver: 1, cost: 1, printer: 1 });

  // modals
  const [showModal, setShowModal] = useState({ payreader: false, miniserver: false, cost: false, printer: false });
  const [selectedRow, setSelectedRow] = useState({});

  const userInfo = JSON.parse(sessionStorage.user);
  const navigate = useNavigate();
  const [queryParam, setQueryParam] = useSearchParams();
  const [rebootMiniserver, setRebootMiniserver] = useState({ code: '', result: -1 });

  useEffect(() => {
    setTableHeader();
    setTab();

    // 미니서버 재부팅 요청 후 접속
    const code = queryParam.get('code');
    const rt = queryParam.get('rt');

    if (code !== null && rt !== null) {
      setRebootMiniserver({ code: code, result: rt });
      startSearch(undefined, "miniserver-search");
      setOpenTab(2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rebootMiniserver.code.length > 0) {
      console.log("rebootMiniserver=", rebootMiniserver);
      let url = `${API.DEVICE_MINISERVER_SELECTED}`.replace("{code}", rebootMiniserver.code);

      new request(url, {}).post(
        (res) => {
          const response = res.data;

          if (response.code === 'success') {
            //response.data;
            setShowModal({ payreader: false, miniserver: true, cost: false, printer: false }); // show 부터 먼저 호출

            response.data[0].rebootStatus = rebootMiniserver.result;
            setSelectedRow(response.data[0]);
          }
        },
        (err) => {
          const errResponse = err.response;
          if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
            // 조회된 리스트가 없습니다.
            console.log(errResponse.data.message);
          } else {
            // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
            console.log(errResponse);
          }
        },
        () => {
          // expired login token
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rebootMiniserver]);

  useEffect(() => {
    if (dropdownData.length === 0) {
      if (userInfo.role === 1) {
        getResellerList();
      } else if (userInfo.role === 2) {
        getResellerList(userInfo.resellerid);
      }
    } else {
      if (selectedItem.length > 0 && selectedItem.length === dropdownData.length) {
        let selected = selectedItem[selectedItem.length - 1];
        if (selected.field === "reseller" && selected.value.length > 0) {
          getResellerList(selected.value);
        }
      }
    }
  }, [dropdownData]);


  const onTabClick = (tabIndex) => {
    setOpenTab(tabIndex);

    // 검색창 초기화
    if (tabIndex === 3) {
      setDropdownTo("reseller");
    } else {
      setDropdownTo("customer");
    }

    if (openTab !== tabIndex) {
      setValue("");
      setDropdownData([]);
      setSelectedItem([]);
    }
  };

  const setTableHeader = () => {
    setHeader({
      payreader: Header.payreader.filter((c) => c.role === undefined || c.role >= userInfo.role),
      miniserver: Header.miniserver.filter((c) => c.role === undefined || c.role >= userInfo.role),
      cost: Header.cost.filter((c) => c.role === undefined || c.role >= userInfo.role),
      printer: Header.printer.filter((c) => c.role === undefined || c.role >= userInfo.role)
    });
  };

  const setTab = () => {
    setTabdata(["결제 단말기", "미니서버", "비용", "프린터"]);
  };

  /**
   * search condition
   */
  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          if (response.data.length > 1) {
            resellerList.push({
              key: "",
              name: "전체",
              type: "reseller"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '' || dropdownTo !== "customer") {

            let item = {
              "title": "파트너",
              "data": resellerList
            };
            setDropdownData([...dropdownData, item]);
          } else {
            getCustomerList(parentid, resellerList);
          }
        } else {
          // no data
          if (parentid !== undefined && parentid.length > 0) {
            if (dropdownTo === "customer") {
              getCustomerList(parentid);
            }
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {
        navigate('/login');
      });
  };
  const getCustomerList = (resellerid, mergeData) => {

    new request(`${API.USER_CUSTOMER}`, {
      "resellerid": resellerid,
      "pageindex": -1
    }).post(
      (res) => {
        // onSuccess
        const response = res.data;
        if (response.code === 'success') {

          let customerList = [];

          if (mergeData !== undefined) {
            customerList = [...mergeData];
          }

          if (response.data.length > 1 && customerList.length <= 1) {
            customerList.push({
              key: -1,
              name: "전체",
              type: "customer"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            customerList.push({
              key: response.data[i]["seq"],
              name: response.data[i]["displayname"],
              type: "customer"
            });
          }

          let item = {
            "title": "고객",
            "data": customerList
          };

          setDropdownData([...dropdownData, item]);

        }
      }, (err) => {
        // onFailed

      }, () => {
        // onExpired login
        navigate('/login');
      });
  };
  const onDropdownSelected = (type, index, selectedKey) => {

    let selected = [...selectedItem];
    let curDropdown = [...dropdownData];

    let refresh = true;

    if (selected.length - 1 >= index) {

      if (selected[index].field === type && selected[index].value === selectedKey) {
        refresh = false;
      }

      selected[index].field = type;
      selected[index].value = selectedKey;
    } else {
      selected.push({
        field: type,
        value: selectedKey
      });
    }

    if (refresh) {
      selected = selected.slice(0, index + 1);
      setSelectedItem(selected);
      curDropdown = curDropdown.slice(0, index + 1);
      setDropdownData(curDropdown);
    }
  };
  const onDateChanged = (field, value) => {
    // 1710403156713

    if (field === 'from') {
      setFrom(value);
    } else {
      setTo(value);
    }
  };
  const onFieldChanged = (fieldKey) => {
    let rt = Object.assign({}, field);

    switch (openTab) {
      case 1: rt.payreader = fieldKey; break;
      case 2: rt.miniserver = fieldKey; break;
      case 3: rt.cost = fieldKey; break;
      case 4: rt.printer = fieldKey; break;
      default: rt.payreader = fieldKey; break;
    }

    setField(rt);
  };



  /**
   * search button event
   */
  const startSearch = (e, searcher, pageindex) => {

    let url = "";
    let data = {};
    let searchForm = "";

    let list = Object.assign({}, dataTable);
    let cnt = Object.assign({}, rowCount);

    if (e !== undefined) {
      e.preventDefault();
      searchForm = e.target.id;
    } else {
      searchForm = searcher;
    }

    for (let i = selectedItem.length - 1; i >= 0; i--) {
      if (selectedItem[i].field === "reseller") {
        data.resellerid = (data.resellerid !== undefined && data.resellerid.length > 0) ? data.resellerid : selectedItem[i].value
      } else {
        data.customerseq = selectedItem[i].value;
      }
    }

    if (userInfo.role !== 1 && (data.resellerid === undefined || data.resellerid.length === 0)) {
      data.resellerid = userInfo.resellerid;
    }

    if (pageindex === undefined) {
      // 검색버튼을 통한 조회 시 Pagination 초기화
      pageindex = { payreader: 1, miniserver: 1, cost: 1, printer: 1 };
      cnt[searchForm.replace("-search", "")] = 0;
      setRowCount(cnt);
    }

    data.value = value;

    if (searchForm === "payreader-search") {

      url = `${API.DEVICE_PAYREADER}`;
      data.field = field.payreader;
      data.pageindex = pageindex.payreader;

    } else if (searchForm === "miniserver-search") {

      url = `${API.DEVICE_MINISERVER}`;
      data.field = field.miniserver;
      data.pageindex = pageindex.miniserver;

    } else if (searchForm === "cost-search") {

      url = `${API.DEVICE_PRINTER_COST}`;
      data.field = field.cost;
      data.pageindex = pageindex.cost;

    } else if (searchForm === "printer-search") {

      url = `${API.DEVICE_PRINTER}`;
      data.field = field.printer;
      data.pageindex = pageindex.printer;
    }

    new request(url, data).post(
      (res) => {
        const response = res.data;

        if (response.code === 'success') {
          switch (searchForm) {
            case 'payreader-search': list.payreader = response.data; cnt.payreader = response.rowcnt; break;
            case 'miniserver-search': list.miniserver = response.data; cnt.miniserver = response.rowcnt; break;
            case 'cost-search': list.cost = response.data; cnt.cost = response.rowcnt; break;
            case 'printer-search': list.printer = response.data; cnt.printer = response.rowcnt; break;
          }
          setDataTable(list);
          setRowCount(cnt);

        }
      },
      (err) => {
        const errResponse = err.response;
        if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
          // 조회된 리스트가 없습니다.

          console.log(errResponse.data.message);

          switch (searchForm) {
            case 'payreader-search': list.payreader = []; cnt.payreader = 0; break;
            case 'miniserver-search': list.miniserver = []; cnt.miniserver = 0; break;
            case 'cost-search': list.cost = []; cnt.cost = 0; break;
            case 'printer-search': list.printer = []; cnt.printer = 0; break;
          }
          setDataTable(list);
          setRowCount(cnt);
        } else {
          // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
          console.log(errResponse);
        }
      },
      () => {
        // expired login token
      }
    );
  };
  const onPageChanged = (tablename, pageindex) => {
    console.log("onPageChanged", tablename, pageindex);

    let pindex = Object.assign({}, pageIndex);
    pindex[tablename] = pageindex;
    setPageIndex(pindex);

    startSearch(undefined, tablename + '-search', pindex);
  };
  const onRowSelected = (name, index) => {
    let show = { payreader: false, miniserver: false, cost: false, printer: false };
    show[name] = true;

    setShowModal(show); // show 부터 먼저 호출
    setSelectedRow(Object.assign({}, dataTable[name][index]));
  };

  const onClosed = (modalName, isRefreshed) => {
    let show = Object.assign({}, showModal);
    show[modalName] = false;
    setShowModal(show);

    console.log("isRefreshed", isRefreshed);
    if (isRefreshed !== undefined && isRefreshed === true) {
      startSearch(undefined, "miniserver-search");
    }
  };


  /**
   * update
   */
  const onUpdatePayreader = (isSaved) => {
    if (isSaved) {
      startSearch(undefined, "payreader-search");
    } else {
      // TODO : alert 
    }
    onClosed("payreader");
  };

  const onUpdateMiniserver = (isSaved) => {
    if (isSaved) {
      startSearch(undefined, "miniserver-search");
    } else {
      // TODO : alert 
    }
    onClosed("miniserver");
  };

  const onUpdateCost = (isSaved) => {
    if (isSaved) {
      startSearch(undefined, "cost-search");
    } else {
      // TODO : alert 
    }
    onClosed("cost");
  };

  const onUpdatePrinter = (isSaved) => {
    if (isSaved) {
      startSearch(undefined, "printer-search");
    } else {
      // TODO : alert 
    }
    onClosed("printer");
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">

          <Tab data={tabData} onChanged={onTabClick} startTab={openTab} />

          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg min-h-screen-75">
            <div className="flex-auto px-4 py-5">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {/** Pay Reader list table */}

                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    결제 단말기
                  </label>

                  <form id="payreader-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      <div className="relative flex flex-wrap items-stretch mr-4">
                        <CalendarDropdown type={"from"} onChange={onDateChanged} value={from} />
                      </div>
                      <div className="relative flex flex-wrap items-stretch py-2 mr-4">
                        ~
                      </div>
                      <div className="relative flex flex-wrap items-stretch">
                        <CalendarDropdown type={"to"} onChange={onDateChanged} value={to} />
                      </div>
                    </div>
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      {
                        dropdownData.map((item, index) =>
                          <SearchDropdown
                            key={"SearchDropdown_" + index}
                            title={item.title}
                            inx={index}
                            selecteditem={undefined}
                            items={item.data}
                            onselected={onDropdownSelected} />
                        )
                      }
                    </div>
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "locationname", name: "이름 (위치)" }}
                        items={[
                          { key: "locationname", name: "이름 (위치)" },
                          { key: "privateip", name: "IP 주소" },
                          { key: "tid", name: "TID" },
                          { key: "kiccserial", name: "시리얼 번호" }
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  {userInfo.role === 1 &&
                    <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                      <button
                        className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModal({ payreader: true, miniserver: false, cost: false, printer: false });
                          setSelectedRow({ seq: -1 });
                        }}>
                        추가
                      </button>
                    </div>
                  }
                  <div className="flex flex-wrap w-full">
                    <PayReaderTable
                      name={"payreader"}
                      header={header.payreader}
                      data={dataTable.payreader}
                      totalCount={rowCount.payreader}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected} />
                  </div>

                  <PayReaderModal show={showModal.payreader} data={selectedRow} onSaved={onUpdatePayreader} onClosed={onClosed} />

                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  {/** Android Box table */}
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    미니 서버
                  </label>

                  <form id="miniserver-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      <div className="relative flex flex-wrap items-stretch mr-4">
                        <CalendarDropdown type={"from"} onChange={onDateChanged} value={from} />
                      </div>
                      <div className="relative flex flex-wrap items-stretch py-2 mr-4">
                        ~
                      </div>
                      <div className="relative flex flex-wrap items-stretch">
                        <CalendarDropdown type={"to"} onChange={onDateChanged} value={to} />
                      </div>
                    </div>

                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      {
                        dropdownData.map((item, index) =>
                          <SearchDropdown
                            key={"SearchDropdown_" + index}
                            title={item.title}
                            inx={index}
                            items={item.data}
                            onselected={onDropdownSelected} />
                        )
                      }
                    </div>

                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "managecode", name: "관리코드" }}
                        items={[
                          { key: "managecode", name: "관리코드" },
                          { key: "privateip", name: "IP주소(내부)" },
                          { key: "connectionip", name: "IP주소(외부)" }
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  {userInfo.role === 1 &&
                    <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                      <button
                        className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModal({ payreader: false, miniserver: true, cost: false, printer: false });
                          setSelectedRow({ seq: -1 });
                        }}>
                        추가
                      </button>
                    </div>
                  }
                  <div className="flex flex-wrap w-full">
                    <AndroidBoxTable
                      name={"miniserver"}
                      header={header.miniserver}
                      data={dataTable.miniserver}
                      totalCount={rowCount.miniserver}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected} />
                  </div>

                  <MiniServerModal show={showModal.miniserver} data={selectedRow} onSaved={onUpdateMiniserver} onClosed={onClosed} />

                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  {/** Android Box table */}
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    비용
                  </label>

                  <form id="cost-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex flex-wrap w-full pr-4 mb-2" style={{ height: "40px" }}>
                    </div>
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      {
                        dropdownData.map((item, index) =>
                          <SearchDropdown
                            key={"SearchDropdown_" + index}
                            title={item.title}
                            inx={index}
                            items={item.data}
                            onselected={onDropdownSelected} />
                        )
                      }
                    </div>
                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "name", name: "관리코드(이름)" }}
                        items={[{ key: "name", name: "관리코드(이름)" }]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                    <button
                      className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal({ payreader: false, miniserver: false, cost: true, printer: false });
                        setSelectedRow({ costid: -1 });
                      }}>
                      추가
                    </button>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <CostTable
                      name={"cost"}
                      header={header.cost}
                      data={dataTable.cost}
                      totalCount={rowCount.cost}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected} />
                  </div>

                  <CostModal show={showModal.cost} data={selectedRow} onSaved={onUpdateCost} onClosed={onClosed} />

                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  {/** Printer list table */}
                  <i className="mr-2 fas fa-bars"></i>
                  <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                    프린터
                  </label>

                  <form id="printer-search" onSubmit={startSearch} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      <div className="relative flex flex-wrap items-stretch mr-4">
                        <CalendarDropdown type={"from"} onChange={onDateChanged} value={from} />
                      </div>
                      <div className="relative flex flex-wrap items-stretch py-2 mr-4">
                        ~
                      </div>
                      <div className="relative flex flex-wrap items-stretch">
                        <CalendarDropdown type={"to"} onChange={onDateChanged} value={to} />
                      </div>
                    </div>

                    <div className="flex flex-wrap w-full pr-4 mb-2">
                      {
                        dropdownData.map((item, index) =>
                          <SearchDropdown
                            key={"SearchDropdown_" + index}
                            title={item.title}
                            inx={index}
                            items={item.data}
                            onselected={onDropdownSelected} />
                        )
                      }
                    </div>

                    <div className="flex flex-wrap w-full mb-2">
                      <NormalDropdown
                        selecteditem={{ key: "name", name: "이름(위치)" }}
                        items={[
                          { key: "A.name", name: "이름(위치)" },
                          { key: "A.privateip", name: "IP주소" },
                          { key: "A.model", name: "모델명" },
                          { key: "A.serial", name: "Serial" },
                          { key: "B.managecode", name: "미니서버" },
                          { key: "D.locationname", name: "결제단말기(위치)" },
                          { key: "F.name", name: "비용코드" }
                        ]}
                        onselected={onFieldChanged} />
                      <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                        <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="검색어를 입력하세요."
                          className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                      >
                        검색
                      </button>
                    </div>
                  </form>
                  <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                    <button
                      className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal({ payreader: false, miniserver: false, cost: false, printer: true });
                        setSelectedRow({ seq: -1 });
                      }}>
                      추가
                    </button>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <PrinterTable
                      name={"printer"}
                      header={header.printer}
                      data={dataTable.printer}
                      totalCount={rowCount.printer}
                      onPageChanged={onPageChanged}
                      onRowSelected={onRowSelected} />
                  </div>

                  <PrinterModal show={showModal.printer} data={selectedRow} onSaved={onUpdatePrinter} onClosed={onClosed} onSettingDevice={onTabClick} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
