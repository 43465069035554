import React, { useRef } from "react";
import { useState, useEffect } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import StatusDropdown from "components/Dropdowns/StatusDropdown";

import { API } from "api/config";
import request from "api/request";
import Alert from "components/Alert/StaticAlert";
import Header from "components/Tables/Header.json"
import CalendarDropdown from "components/Dropdowns/CalendarDropdown";
import CardHistoryTable from "components/Tables/CardHistoryTable";
import moment from "moment";

export default function CardHistoryModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

  const [showModal, setShowModal] = React.useState(show);

  const [cardnum, setCardnum] = useState('');

  const [header, setHeader] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);

  // search conition
  const [from, setFrom] = useState(new Date().setDate(new Date().getDate() - 7));
  const [to, setTo] = useState(Date.now());

  const [alertData, setAlertData] = React.useState({ show: false, msg: "" });

  const modalName = "cardhistory";

  const userInfo = JSON.parse(sessionStorage.user);

  useEffect(() => {
    setShowModal(show);

    if (!show) {
      setDataTable([]);
      setAlertData();
    }
  }, [show]);

  useEffect(() => {
    // console.log(data);
    // if (data === undefined || data === null || Object.keys(data).length === 0) {
    //   return;
    // }
    if(showModal){

    console.log('useEffect data', data);

    setCardnum(data.cardnum);
    setHeader(Header.cardhistory.filter((c) => c.role === undefined || c.role === userInfo.role));

    startSearch();
    }
  }, [showModal]);

  const onDateChanged = (field, value) => {
    // 1710403156713

    if (field === 'from') {
      setFrom(value);
    } else {
      setTo(value);
    }
  };

  const onPageChanged = (tablename, pageindex) => {
    console.log("onPageChanged", tablename, pageindex);

    let pindex = pageindex;

    startSearch(undefined, pindex);
  };

  const startSearch = (e, pageindex) => {

    let url = `${API.USER_CARD_HISTORY}`;
    let newdata = {};
    
    if (pageindex !== undefined) {
      setPageIndex(pageindex);
    } else {
      pageindex = pageIndex;
    }
    console.log(pageindex);

    newdata.from = moment(from).format("YYYY-MM-DD");
    newdata.to = moment(to).format("YYYY-MM-DD");

    newdata.seq = data.seq;
    newdata.pageindex = pageindex;
    newdata.resellerid = userInfo.resellerid;

    console.log("search data : " + JSON.stringify(newdata));

    new request(url, newdata).post(
      (res) => {
        const response = res.data;

        console.log(response);

        if (response.code === "success") {
          setDataTable(response.data);
          setRowCount(response.rowcnt);
        }
        if (response.code === "error" && response.message === "no data") {
          setDataTable([]);
          setRowCount(0);
        }
      },
      (error) => {
        const errResponse = error.response;
        if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
          setDataTable([]);
          setRowCount(0);
        } else {
          console.log(errResponse);
        }
      },
      () => {
        // expired login token
      }
    )
  }

  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={() => { }}
          >
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">
                    사용 내역
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClosed(modalName)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative flex-auto p-6 bg-blueGray-100">
                  <div className="flex flex-wrap">
                    <div className="w-full mb-5 flex">
                    <label
                        className="px-3 py-3 text-sm block font-bold uppercase text-blueGray-600">
                        카드 번호 
                      </label>
                    <label
                      className="px-3 py-3 text-sm block font-bold text-blueGray-600">
                      {cardnum}
                    </label>
                    </div>
                    <div className="w-full mb-5">
                      <div className="flex flex-wrap w-full pr-4 mb-2">
                        <div className="relative flex flex-wrap items-stretch mr-4">
                          <CalendarDropdown type={"from"} onChange={onDateChanged} value={from} />
                        </div>
                        <div className="relative flex flex-wrap items-stretch py-2 mr-4">
                          ~
                        </div>
                        <div className="relative flex flex-wrap items-stretch">
                          <CalendarDropdown type={"to"} onChange={onDateChanged} value={to} />
                        </div>
                        <button
                          type="submit"
                          className="w-2/12 px-4 py-2 mb-1 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                          onClick={startSearch}
                        >
                          검색
                        </button>
                      </div>
                    </div>
                    <CardHistoryTable
                      name={"cardhistory"}
                      header={header}
                      data={dataTable}
                      totalCount={rowCount}
                      onPageChanged={onPageChanged}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-3 px-6 border-t border-solid rounded-b border-blueGray-200">
                  <div>
                    <Alert data={alertData}></Alert>
                  </div>
                  <div className="float-right">
                    <button
                      className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                      type="button"
                      onClick={() => onClosed(modalName)}>
                      닫기
                    </button>
                    <button
                      className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={() => onClosed(modalName)}
                    >
                      확인
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};