import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import moment from "moment";

import Tab from "components/Navbars/Tab";
import CalendarDropdown from "components/Dropdowns/CalendarDropdown";
import NormalDropdown from "components/Dropdowns/NormalDropdown";
import SearchDropdown from "components/Dropdowns/SearchDropdown";

import Header from "components/Tables/Header.json"
import ByJobTable from "components/Tables/ByJobTable";
import ByDayTable from "components/Tables/ByDayTable";
import ConsumableTable from "components/Tables/ConsumableTable";

import { API } from "../api/config"
import request from "../api/request"

export default function Reports() {

    const userInfo = JSON.parse(sessionStorage.user);
    const navigate = useNavigate();

    const [tabData, setTabdata] = useState([]);
    const [openTab, setOpenTab] = useState(1);

    const [from, setFrom] = useState(Date.now());
    const [to, setTo] = useState(Date.now());
    const [field, setField] = useState({ byjob: "D.tid", byday: "E.locationname", consumable: "E.name" });
    const [searchInput, setSearchInput] = useState({});

    const [value, setValue] = useState('');

    const [selectedItem, setSelectedItem] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    const [header, setHeader] = useState({ byjob: [], byday: [], consumable: [] });
    const [dataTable, setDataTable] = useState({ byjob: [], byday: [], consumable: [] });
    const [rowCount, setRowCount] = useState({ byjob: 0, byday: 0, consumable: 0 });
    const [pageIndex, setPageIndex] = useState({ byjob: 1, byday: 1, consumable: 1 });


    useEffect(() => {
        if (userInfo.role === 4) {
            navigate('/admin/printer');
        }

        setTabdata(["건별 상세", "일일 통계", "소모품 상태"]);
        setHeader({
            byjob: Header.transaction.filter((c) => c.role === undefined || c.role >= userInfo.role),
            byday: Header.transaction_day.filter((c) => c.role === undefined || c.role >= userInfo.role),
            consumable: Header.consumable.filter((c) => c.role === undefined || c.role >= userInfo.role),
        });
    }, []);

    useEffect(() => {
        if (dropdownData.length === 0) {
            if (userInfo.role === 1) {
                getResellerList();
            } else if (userInfo.role === 2) {
                getResellerList(userInfo.resellerid);
            }
        } else {
            if (selectedItem.length > 0 && selectedItem.length === dropdownData.length) {
                let selected = selectedItem[selectedItem.length - 1];
                if (selected.field === "reseller" && selected.value.length > 0) {
                    getResellerList(selected.value);
                }
            }
        }
    }, [dropdownData]);

    const onTabClick = (tabIndex) => {
        setOpenTab(tabIndex);

        if (openTab !== tabIndex) {
            // todo : clear input
            setDropdownData([]);
            setSelectedItem([]);

        }



    };

    const getResellerList = (parentid) => {

        new request(`${API.USER_RESELLER}`, {
            "parentid": parentid,
            "pageindex": -1
        }).post(
            (res) => {
                const response = res.data;
                if (response.code === 'success') {

                    let resellerList = [];

                    if (response.data.length > 1) {
                        resellerList.push({
                            key: "",
                            name: "전체",
                            type: "reseller"
                        });
                    }

                    for (let i = 0; i < response.data.length; i++) {
                        resellerList.push({
                            key: response.data[i]["resellerid"],
                            name: response.data[i]["name"],
                            type: "reseller"
                        });
                    }

                    if (parentid === undefined || parentid === '') {

                        let item = {
                            "title": "파트너",
                            "data": resellerList
                        };
                        setDropdownData([...dropdownData, item]);
                    } else {
                        getCustomerList(parentid, resellerList);
                    }
                } else {
                    // no data
                    if (parentid !== undefined && parentid.length > 0) {
                        getCustomerList(parentid);
                    }
                }
            }, (err) => {
                console.log(err);
            }, () => {
                navigate('/login');
            });
    };
    const getCustomerList = (resellerid, mergeData) => {

        new request(`${API.USER_CUSTOMER}`, {
            "resellerid": resellerid,
            "pageindex": -1
        }).post(
            (res) => {
                // onSuccess
                const response = res.data;
                if (response.code === 'success') {

                    let customerList = [];

                    if (mergeData !== undefined) {
                        customerList = [...mergeData];
                    }

                    if (response.data.length > 1 && customerList.length <= 1) {
                        customerList.push({
                            key: -1,
                            name: "전체",
                            type: "customer"
                        });
                    }

                    for (let i = 0; i < response.data.length; i++) {
                        customerList.push({
                            key: response.data[i]["seq"],
                            name: response.data[i]["displayname"],
                            type: "customer"
                        });
                    }

                    let item = {
                        "title": "고객",
                        "data": customerList
                    };

                    setDropdownData([...dropdownData, item]);

                }
            }, (err) => {
                // onFailed

            }, () => {
                // onExpired login
                navigate('/login');
            });
    };
    const onDropdownSelected = (type, index, selectedKey) => {

        let selected = [...selectedItem];
        let curDropdown = [...dropdownData];

        let refresh = true;

        if (selected.length - 1 >= index) {

            if (selected[index].field === type && selected[index].value === selectedKey) {
                refresh = false;
            }

            selected[index].field = type;
            selected[index].value = selectedKey;
        } else {
            selected.push({
                field: type,
                value: selectedKey
            });
        }

        if (refresh) {
            selected = selected.slice(0, index + 1);
            setSelectedItem(selected);
            curDropdown = curDropdown.slice(0, index + 1);
            setDropdownData(curDropdown);
        }
    };

    const onDateChanged = (field, value) => {
        // 1710403156713

        if (field === 'from') {
            setFrom(value);
        } else {
            setTo(value);
        }
    };

    const onFieldChanged = (val, fieldname) => {
        if (fieldname === undefined) {
            let rt = Object.assign({}, field);
            switch (openTab) {
                case 1: rt.byjob = val; break;
                case 2: rt.byday = val; break;
                case 3: rt.consumable = val; break;
            }
            setField(rt);
        } else {
            let searchData = Object.assign({}, searchInput);
            searchData[fieldname] = val;
            setSearchInput(searchData);
        }
    };

    const downloadExcelFile = (tableName) => { };

    const startSearch = (e, searcher, pageindex) => {
        let url = "";
        let data = {};
        let searchForm = "";

        let list = Object.assign({}, dataTable);
        let cnt = Object.assign({}, rowCount);

        if (e !== undefined) {
            e.preventDefault();
            searchForm = e.target.id;
        } else {
            searchForm = searcher;
        }

        for (let i = selectedItem.length - 1; i >= 0; i--) {
            if (selectedItem[i].field === "reseller") {
                data.resellerid = (data.resellerid !== undefined && data.resellerid.length > 0) ? data.resellerid : selectedItem[i].value
            } else {
                data.customerseq = selectedItem[i].value;
            }
        }

        if (userInfo.role !== 1 && (data.resellerid === undefined || data.resellerid.length === 0)) {
            data.resellerid = userInfo.resellerid;
        }

        if (pageindex === undefined) {
            pageindex = { byjob: 1, byday: 1, consumable: 1 };
            cnt[searchForm.replace("-search", "")] = 0;
            setRowCount(cnt);
        }

        data.value = value;

        if (searchForm === "byjob-search") {

            url = `${API.TRANSACTION_BYJOB}`;
            data.field = field.byjob;
            data.pageindex = pageindex.byjob;
            data.from = moment(from).format("YYYY-MM-DD");
            data.to = moment(to).format("YYYY-MM-DD");

            if (searchInput.jobtype !== undefined) {
                data.jobtype = searchInput.jobtype;
            }

            if (searchInput.paymode !== undefined) {
                data.paymode = Number(searchInput.paymode);
            }
        } else if (searchForm === "byday-search") {
            url = `${API.TRANSACTION_BYDAY}`;
            data.field = field.byday;
            data.pageindex = pageindex.byday;
            data.from = moment(from).format("YYYY-MM-DD");
            data.to = moment(to).format("YYYY-MM-DD");

        } else if (searchForm === "consumable-search") {
            url = `${API.PRINTER_CONSUMABLE}`;
            data.field = field.consumable;
            data.pageindex = pageindex.consumable;
        }

        new request(url, data).post(
            (res) => {
                const response = res.data;

                if (response.code === 'success') {
                    switch (searchForm) {
                        case 'byjob-search': list.byjob = response.data; cnt.byjob = response.rowcnt; break;
                        case 'byday-search': list.byday = response.data; cnt.byday = response.rowcnt; break;
                        case 'consumable-search': list.consumable = response.data; cnt.consumable = response.rowcnt; break;
                    }
                    setDataTable(list);
                    setRowCount(cnt);
                } else {
                    switch (searchForm) {
                        case 'byjob-search': list.byjob = []; cnt.byjob = 0; break;
                        case 'byday-search': list.byday = []; cnt.byday = 0; break;
                        case 'consumable-search': list.consumable = []; cnt.consumable = 0; break;
                    }
                    setDataTable(list);
                    setRowCount(cnt);
                }
            },
            (err) => {
                const errResponse = err.response;
                if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
                    // 조회된 리스트가 없습니다.

                    console.log(errResponse.data.message);

                    switch (searchForm) {
                        case 'byjob-search': list.byjob = []; cnt.byjob = 0; break;
                        case 'byday-search': list.byday = []; cnt.byday = 0; break;
                        case 'consumable-search': list.consumable = []; cnt.consumable = 0; break;
                    }
                    setDataTable(list);
                    setRowCount(cnt);

                } else {
                    // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
                    console.log(errResponse);
                }
            },
            () => {
                // expired login token
            }
        );
    };

    const onPageChanged = (tablename, pageindex) => {
        let pindex = Object.assign({}, pageIndex);
        pindex[tablename] = pageindex;
        setPageIndex(pindex);

        startSearch(undefined, tablename + '-search', pindex);
    };

    const onRowSelected = () => {

    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">

                    <Tab data={tabData} onChanged={onTabClick} />

                    <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg min-h-screen-75">
                        <div className="flex-auto px-4 py-5">
                            <div className="tab-content tab-space">


                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <i className="mr-2 fas fa-bars"></i>
                                    <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                                        건별 결제 내역
                                    </label>

                                    <form id="byjob-search" onSubmit={(e) => startSearch(e)} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                                        <div className="flex flex-wrap w-full pr-4 mb-2">
                                            <div className="relative flex flex-wrap items-stretch mr-4">
                                                <CalendarDropdown type={"from"} onChange={onDateChanged} value={from} />
                                            </div>
                                            <div className="relative flex flex-wrap items-stretch py-2 mr-4">
                                                ~
                                            </div>
                                            <div className="relative flex flex-wrap items-stretch">
                                                <CalendarDropdown type={"to"} onChange={onDateChanged} value={to} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap w-full pr-4 mb-2">
                                            {
                                                dropdownData.map((item, index) =>
                                                    <SearchDropdown
                                                        key={"SearchDropdown_" + index}
                                                        title={item.title}
                                                        inx={index}
                                                        selecteditem={undefined}
                                                        items={item.data}
                                                        onselected={onDropdownSelected} />
                                                )
                                            }
                                        </div>
                                        <div className="flex flex-wrap w-full mb-2">
                                            <NormalDropdown
                                                name={"jobtype"}
                                                selecteditem={{ key: "", name: "작업유형" }}
                                                items={[
                                                    { key: "", name: "전체" },
                                                    { key: "Print", name: "출력" },
                                                    { key: "Copy", name: "복사" },
                                                    { key: "Scan", name: "스캔" },
                                                    { key: "Fax", name: "팩스" }
                                                ]}
                                                onselected={onFieldChanged} />
                                            <NormalDropdown
                                                name={"paymode"}
                                                selecteditem={{ key: "-1", name: "결제방식" }}
                                                items={[
                                                    { key: "-1", name: "전체" },
                                                    { key: "1", name: "신용/체크카드" },
                                                    { key: "2", name: "티머니" },
                                                    { key: "3", name: "캐시비(Cashbee)" },
                                                    { key: "4", name: "회원카드" },
                                                    { key: "6", name: "쿠폰" }
                                                ]}
                                                onselected={onFieldChanged} />
                                            <NormalDropdown
                                                selecteditem={{ key: "D.tid", name: "검색목록" }}
                                                items={[
                                                    { key: "D.tid", name: "TID" },
                                                    { key: "A.r09", name: "승인번호" },
                                                    { key: "A.r13", name: "카드사" },
                                                    { key: "A.r23", name: "카드번호" }
                                                ]}
                                                onselected={onFieldChanged} />
                                            <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                                                <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="검색어를 입력하세요."
                                                    className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                            >
                                                검색
                                            </button>
                                        </div>
                                    </form>

                                    {/** Excel 내보내기 */}
                                    <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                                        <button
                                            className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                downloadExcelFile("byjob");
                                            }}>
                                            Excel 내보내기
                                        </button>
                                    </div>

                                    <div className="flex flex-wrap w-full">
                                        <ByJobTable
                                            name={"byjob"}
                                            header={header.byjob}
                                            data={dataTable.byjob}
                                            totalCount={rowCount.byjob}
                                            onPageChanged={onPageChanged}
                                            onRowSelected={onRowSelected} />
                                    </div>

                                </div>


                                <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                                    <i className="mr-2 fas fa-bars"></i>
                                    <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                                        일일 통계
                                    </label>

                                    <form id="byday-search" onSubmit={(e) => startSearch(e)} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                                        <div className="flex flex-wrap w-full pr-4 mb-2">
                                            <div className="relative flex flex-wrap items-stretch mr-4">
                                                <CalendarDropdown type={"from"} onChange={onDateChanged} value={from} />
                                            </div>
                                            <div className="relative flex flex-wrap items-stretch py-2 mr-4">
                                                ~
                                            </div>
                                            <div className="relative flex flex-wrap items-stretch">
                                                <CalendarDropdown type={"to"} onChange={onDateChanged} value={to} />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap w-full pr-4 mb-2">
                                            {
                                                dropdownData.map((item, index) =>
                                                    <SearchDropdown
                                                        key={"SearchDropdown_" + index}
                                                        title={item.title}
                                                        inx={index}
                                                        selecteditem={undefined}
                                                        items={item.data}
                                                        onselected={onDropdownSelected} />
                                                )
                                            }
                                        </div>
                                        <div className="flex flex-wrap w-full mb-2">
                                            <NormalDropdown
                                                selecteditem={{ key: "E.locationname", name: "단말기 이름(위치)" }}
                                                items={[
                                                    { key: "E.tid", name: "TID" },
                                                    { key: "E.locationname", name: "단말기 이름(위치)" }
                                                ]}
                                                onselected={onFieldChanged} />
                                            <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                                                <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="검색어를 입력하세요."
                                                    className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                            >
                                                검색
                                            </button>
                                        </div>
                                    </form>

                                    {/** Excel 내보내기 */}
                                    <div className="flex flex-wrap w-full mb-3" style={{ marginTop: "-60px" }}>
                                        <button
                                            className="px-6 py-3 mb-1 ml-auto mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                downloadExcelFile("byjob");
                                            }}>
                                            Excel 내보내기
                                        </button>
                                    </div>

                                    <div className="flex flex-wrap w-full">
                                        <ByDayTable
                                            name={"byday"}
                                            header={header.byday}
                                            data={dataTable.byday}
                                            totalCount={rowCount.byday}
                                            onPageChanged={onPageChanged}
                                            onRowSelected={onRowSelected} />
                                    </div>

                                </div>

                                <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                                    <i className="mr-2 fas fa-bars"></i>
                                    <label className="mb-4 text-base font-semibold uppercase text-blueGray-600 lg:inline-block">
                                        소모품 상태(잔량)
                                    </label>

                                    <form id="consumable-search" onSubmit={(e) => startSearch(e)} className="flex flex-row flex-wrap items-center mb-3 mr-3 max-w-full-px min-h-146-px">
                                        <div className="flex flex-wrap w-full pr-4 mb-2" style={{ height: "40px" }}>
                                        </div>
                                        <div className="flex flex-wrap w-full pr-4 mb-2">
                                            {
                                                dropdownData.map((item, index) =>
                                                    <SearchDropdown
                                                        key={"SearchDropdown_" + index}
                                                        title={item.title}
                                                        inx={index}
                                                        selecteditem={undefined}
                                                        items={item.data}
                                                        onselected={onDropdownSelected} />
                                                )
                                            }
                                        </div>
                                        <div className="flex flex-wrap w-full mb-2">
                                            <NormalDropdown
                                                selecteditem={{ key: "E.name", name: "프린터명(위치)" }}
                                                items={[
                                                    { key: "E.name", name: "프린터명(위치)" },
                                                    { key: "E.privateip", name: "IP주소" },
                                                    { key: "E.model", name: "모델명" },
                                                    { key: "E.serial", name: "시리얼" }
                                                ]}
                                                onselected={onFieldChanged} />
                                            <div className="relative flex flex-wrap items-stretch w-8/12 border border-blueGray-100">
                                                <span className="absolute z-10 items-center justify-center w-8 h-full py-2 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-400">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="검색어를 입력하세요."
                                                    className="relative w-full px-3 py-2 pl-10 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="w-2/12 px-4 py-2 ml-3 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
                                            >
                                                검색
                                            </button>
                                        </div>
                                    </form>


                                    <div className="flex flex-wrap w-full">
                                        <ConsumableTable
                                            name={"consumable"}
                                            header={header.consumable}
                                            data={dataTable.consumable}
                                            totalCount={rowCount.consumable}
                                            onPageChanged={onPageChanged}
                                            onRowSelected={onRowSelected} />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
