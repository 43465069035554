import React, { useRef } from "react";
import { useState, useEffect } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import StatusDropdown from "components/Dropdowns/StatusDropdown";

import { API } from "api/config";
import request from "api/request";
import Alert from "components/Alert/StaticAlert";

export default function MiniServerModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

    const modalName = "miniserver";

    const [showModal, setShowModal] = React.useState(show);
    const [selectedItem, setSelectedItem] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    const [managecode, setManagecode] = useState('');
    const [mac, setMac] = useState('');
    const [serial, setSerial] = useState('');
    const [privateip, setPrivateip] = useState('');
    const [maxcount, setMaxcount] = useState('');
    const [keeptime, setKeeptime] = useState('');
    const [status, setStatus] = useState(false);
    const [comment, setComment] = useState('');
    const [rebootStatus, setRebootStatus] = useState(0);
    const [refreshStatus, setRefreshStatus] = useState(0); // 0: default 1:precessing 2: failed
    const [refreshText, setRefreshText] = useState('새로고침');
    const [isRefreshed, setRefreshed] = useState(false);
    const [clipBoardBtnText, setClipBoardBtnText] = useState('url 복사');
    let rebootRetry = 5;

    const [rebootEnable, setRebootEnable] = useState(false); // reboot button display
    const [refreshEnable, setRefreshEnable] = useState(false); // refresh button display

    const [alertData, setAlertData] = React.useState({ show: false, msg: "" });

    const managecodeRef = useRef();
    const rebootFormRef = useRef();
    const rebootFrameRef = useRef();

    const userInfo = JSON.parse(sessionStorage.user);

    


    useEffect(() => {
        setShowModal(show);

        if (!show) {
            setManagecode('');
            setMac('');
            setSerial('');
            setPrivateip('');
            setKeeptime('');
            setMaxcount('');
            setStatus(false);
            setComment('');
            setSelectedItem([]);
            setAlertData({});
            setRebootEnable(false);
            setRebootStatus(0);
            setRefreshEnable(false);
            setRefreshStatus(0);
            rebootRetry = 0;
        }

    }, [show]);

    useEffect(() => {
        if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
            return;
        }

        console.log("useEffect", data);

        setManagecode(data.managecode);
        setMac(data.mac);
        setSerial(data.serial);
        setPrivateip(data.privateip);
        setKeeptime(data.keeptime);
        setMaxcount(data.maxcount);
        setStatus(data.status === 1);
        setComment(data.comment || "");

        if (data.rebootStatus === "1") {
            rebootRetry = 5;
            setRebootStatus(Number(data.rebootStatus));
            setTimeout(chkUpdated, 5000);
        }

        let selected = [];

        if (data.resellerid !== undefined && data.resellerid.length > 0 && userInfo.resellerid !== data.resellerid) {
            selected.push({ field: "reseller", value: data.resellerid, name: data.resellername });
        }

        if (data.customerseq !== undefined && data.customerseq > 0) {
            selected.push({ field: "customer", value: data.customerseq, name: data.customername });
        }

        if (selected.length > 0) {
            setSelectedItem(selected);
        }

        setDropdownData([]);

        chkClientIP();
        setRebootEnable(true);
        setRefreshEnable(true);
        setRefreshText('새로고침');
        setRefreshed(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {

        if (!showModal) {
            return;
        }

        if (dropdownData.length === 0) {
            if (userInfo.role === 1) {
                getResellerList();
            } else if (userInfo.role === 2) {
                getResellerList(userInfo.resellerid);
            }
        } else {
            if (selectedItem.length > 0 && selectedItem.length >= dropdownData.length) {
                let selected = selectedItem[dropdownData.length - 1];
                if (selected.field === "reseller" && selected.value.length > 0) {
                    getResellerList(selected.value);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [dropdownData]);

    const getResellerList = (parentid) => {

        new request(`${API.USER_RESELLER}`, {
            "parentid": parentid,
            "pageindex": -1
        }).post(
            (res) => {
                const response = res.data;
                if (response.code === 'success') {

                    let resellerList = [];

                    resellerList.push({
                        key: "",
                        name: "미정(할당안함)",
                        type: "reseller"
                    });

                    for (let i = 0; i < response.data.length; i++) {
                        resellerList.push({
                            key: response.data[i]["resellerid"],
                            name: response.data[i]["name"],
                            type: "reseller"
                        });
                    }

                    if (parentid === undefined || parentid === '') {
                        let item = {
                            "title": "파트너",
                            "data": resellerList,
                            "enabled": userInfo.role === 1 ? true : false
                        };

                        setDropdownData([...dropdownData, item]);
                    } else {
                        getCustomerList(parentid, resellerList);
                    }
                } else {
                    if (parentid !== undefined && parentid.length > 0) {
                        getCustomerList(parentid);
                    }
                }
            }, (err) => {
                console.log(err);
            }, () => {

            });
    };
    const getCustomerList = (resellerid, mergeData) => {

        new request(`${API.USER_CUSTOMER}`, {
            "resellerid": resellerid,
            "pageindex": -1
        }).post(
            (res) => {
                // onSuccess
                const response = res.data;
                if (response.code === 'success') {

                    let customerList = [];

                    if (mergeData !== undefined) {
                        customerList = [...mergeData];
                    }

                    if (customerList <= 1) {
                        customerList.push({
                            key: -1,
                            name: "미정(할당안함)",
                            type: "customer"
                        });
                    }


                    for (let i = 0; i < response.data.length; i++) {
                        customerList.push({
                            key: response.data[i]["seq"],
                            name: response.data[i]["displayname"],
                            type: "customer"
                        });
                    }

                    let item = {
                        "title": "고객",
                        "data": customerList
                    };

                    setDropdownData([...dropdownData, item]);

                }
            }, (err) => {
                // onFailed

            }, () => {
                // onExpired login

            });
    };
    const onDropdownSelected = (type, index, selectedKey, selectedName) => {

        let selected = [...selectedItem];
        let curDropdown = [...dropdownData];

        let refresh = true;

        if (index < selected.length) {

            if (selected[index].field === type && selected[index].value === selectedKey) {
                refresh = false;
            }

            selected[index].field = type;
            selected[index].value = selectedKey;
        } else {
            selected.push({
                field: type,
                value: selectedKey,
                name: selectedName
            });
        }

        if (refresh) {
            selected = selected.slice(0, index + 1);
            setSelectedItem(selected);
            curDropdown = curDropdown.slice(0, index + 1);
            setDropdownData(curDropdown);
        }
    };

    const getSelectedItem = (index) => {
        if (index < selectedItem.length) {
            return { key: selectedItem[index].value, name: selectedItem[index].name };
        }
        return undefined;
    };

    const onStatusChanged = (selectedStatus) => {
        setStatus(selectedStatus);
    };

    const onRebootClicked = (e, guid) => {
        if (e) {
            e.preventDefault();
            

            
            new request(`${API.DEVICE_MINISERVER_REBOOT}`, {}).post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {
                        onRebootClicked(undefined, response.data._guid);
                    } else {
                        setAlertData({ show: true, msg: response.message });
                    }
                },
                (err) => {
                    console.log(err);
                },
                () => {
                });
        } else {
            console.log("guid : " + guid);

            window.location.href = "http://" + privateip + ":8080/page.req.reboot?guid=" + guid;

            //rebootFrameRef.current.src = "http://" + privateip + ":8080/page.req.reboot?guid=" + guid;
            //rebootFormRef.current.action = "http://" + privateip + ":8080/page.req.reboot";
            //rebootFormRef.current.children[0].value = guid;
            //rebootFormRef.current.requestSubmit();
        };
    };

    const onRefreshClicked = (e) => {
        
        e.preventDefault();
        console.log("miniserver seq : ", data.seq);

        setRefreshStatus(1);
        setRefreshText('새로고침 중입니다.');


        new request(`${API.DEVICE_MINISERVER_REFRESH}`, {
            "cmd": "A01",
            "seq": data.seq
        }).post(
            (res) => {
                console.log("success", res);
                setRefreshed(true);
                setRefreshStatus(0);
                setRefreshText('새로고침 완료');
                setTimeout(() => { setRefreshText('새로고침'); }, 4000);
            }, 
            (err) => {
                var resData = err.response.data;

                console.log("err", resData);
                
                setRefreshStatus(2);
                setRefreshText(resData.message);
                setTimeout(() => { 
                    setRefreshStatus(0);
                    setRefreshText('새로고침'); 
                }, 4000);
            }, 
            () => {
                
            });
    }

    const onClipboardCopy = (e) => {
        /*
        Clipboard API는 https 또는 localhost 환경에서만 동작한다.
         */
        if (navigator.clipboard === undefined) {
            return;
        }
        navigator.clipboard.writeText('http://' + data.privateip + ':8080/main.html')
        .then(() => {
            setClipBoardBtnText('클립보드에 복사되었습니다.');
            setTimeout(() => { setClipBoardBtnText('url 복사'); }, 2000);
        });
    };

    const onRebootCompleted = () => {
        
    };

    const onSaveClicked = () => {
        let newData = Object.assign({}, data);

        if (managecode.length === 0) {
            setAlertData({ show: true, msg: "관리코드는 필수입력입니다." });
            return managecodeRef.current.focus();
        }

        newData.managecode = managecode;
        newData.mac = mac;
        newData.serial = serial;
        newData.privateip = privateip;
        newData.keeptime = keeptime;
        newData.maxcount = Number(maxcount);
        newData.status = status ? 1 : 0;
        newData.comment = comment;


        let selectedReseller = "";
        let selectedCustomer = -1;
        for (let i = selectedItem.length - 1; i >= 0; i--) {
            if (selectedItem[i].field === "reseller") {
                selectedReseller = (selectedReseller.length === 0) ? selectedItem[i].value : selectedReseller;
            } else {
                selectedCustomer = selectedItem[i].value;
            }
        }

        if (userInfo.role === 2 && selectedReseller.length === 0) {
            selectedReseller = userInfo.resellerid;
        }
        newData.resellerid = selectedReseller;
        newData.customerseq = selectedCustomer;

        console.log("miniserver update", newData);

        if (isChangedRowData(data, newData)) {
            let url = `${API.DEVICE_MINISERVER_UPDATE}`.replace("{seq}", newData.seq.toString());
            new request(url, newData).post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {
                        onSaved(true);
                    } else {
                        setAlertData({ show: true, msg: response.message });
                    }
                },
                (err) => {
                    console.log(err);
                    onSaved(false);
                },
                () => {

                });
        } else {
            onClosed(modalName, isRefreshed);
        }
    };

    const isChangedRowData = (oriData, newData) => {
        const keys1 = Object.keys(oriData);
        const keys2 = Object.keys(newData);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            const val1 = oriData[key];
            const val2 = newData[key];
            const areObjects = isObject(val1) && isObject(val2);
            if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
                return true;
            }
        }

        return false;
    };

    const isObject = (obj) => {
        return obj != null && typeof obj === 'object';
    };

    /**
     * 사용안함.
     * 같은 네크워크 대역대에 있는지 체크.
     */
    const chkClientIP = () => {
        new request('https://checkip.amazonaws.com/', null).get(
            (res) => {
                console.log(res);
                if (res.data.includes(data.connectionip)) {
                    setRebootEnable(true);
                } else {
                    setRebootEnable(false);
                }
            });
    };

    const chkUpdated = () => {
        console.log("retry remaian=", rebootRetry);

        if (rebootRetry-- <= 0) {
            setRebootStatus(-1);
            return;
        }

        let url = `${API.DEVICE_MINISERVER_SELECTED}`.replace("{code}", data.managecode);

        new request(url, {}).post(
            (res) => {
                const response = res.data;

                if (response.code === 'success') {
                    const prev = new Date(data.lastupdate);
                    const curr = new Date(response.data[0].lastupdate);

                    if (prev < curr) {
                        // reboot complete
                        setRebootStatus(2);
                        console.log("reboot completed");
                    } else {
                        console.log("retry");
                        setTimeout(chkUpdated, 5000);
                    }
                }
            },
            (err) => {
                const errResponse = err.response;
                if (errResponse.data !== undefined && errResponse.data.code === "error" && errResponse.data.message === "no data") {
                    // 조회된 리스트가 없습니다.
                    console.log(errResponse.data.message);
                } else {
                    // alert : 문제가 발생하였습니다. 데이터를 조회할 수 없습니다.
                    console.log(errResponse);
                }
            },
            () => {
                // expired login token
            }
        );
    };

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                        onClick={() => { }}
                    >
                        <div className="relative w-auto max-w-xl mx-auto my-6">
                            {/*content*/}
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                                    <h3 className="text-xl font-semibold">
                                        미니서버 정보
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => onClosed(modalName, isRefreshed)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6 bg-blueGray-100">
                                    <form>
                                        <div className="flex flex-wrap">

                                            <div className="w-full mb-5">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    관리코드
                                                </label>

                                                <div className="flex flex-wrap items-center w-full mb-5">
                                                    {userInfo.role === 1 ? (
                                                        <input
                                                            type="text"
                                                            className="px-3 py-3 mr-4 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                            ref={managecodeRef}
                                                            value={managecode}
                                                            onChange={(e) => {
                                                                setManagecode(e.target.value);
                                                                setAlertData();
                                                            }}
                                                        />
                                                    ) : (
                                                        <h3 className="px-3 text-lg font-semibold">
                                                            {managecode}
                                                        </h3>
                                                    )}

                                                    <button
                                                        type="button"
                                                        style={{ maxHeight: "28px" }}
                                                        disabled={rebootStatus === 1 ? "disabled" : ""}
                                                        className={(rebootEnable ? "block " : "hidden ") + (rebootStatus === 1 ? "cursor-wait " : "") + (rebootStatus === 2 ? "bg-lightBlue-500 " : "bg-lightBlue-400 ") + "w-2/12 px-2 py-1 mr-4 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"}
                                                        onClick={(e) => onRebootClicked(e)}>
                                                        {rebootStatus === 1 && <div className="loading-spinner"></div>}
                                                        {rebootStatus === 1 ? "재부팅 중입니다" :
                                                            rebootStatus === 2 ? "재부팅 완료" : "재부팅"}
                                                    </button>

                                                    <button
                                                        type="button"
                                                        style={{ maxHeight: "28px" }}
                                                        disabled={refreshStatus === 1 ? "disabled" : ""}
                                                        className={(refreshEnable ? "block " : "hidden ") + (refreshStatus === 1 ? "cursor-wait " : "") + (refreshStatus === 0 ? "bg-emerald-500 " : (refreshStatus === 1 ? "bg-emerald-400 " : "bg-amber-500 ")) + "w-2/12 px-2 py-1 mr-4 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-md focus:outline-none"}
                                                        onClick={(e) => onRefreshClicked(e)}>
                                                        {refreshStatus === 1 && <div className="loading-spinner"></div>}
                                                        {refreshText}
                                                    </button>
                                                </div>
                                            </div>

                                            {
                                                userInfo.role === 1 && (
                                                    <div className="flex flex-wrap w-full mb-5">
                                                        <div className="w-6/12">
                                                            <label
                                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                                mac
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                                value={mac}
                                                                onChange={(e) => setMac(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="w-6/12">
                                                            <label
                                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                                serial
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                                value={serial}
                                                                onChange={(e) => setSerial(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="w-full mb-5">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    IP주소 (내부)
                                                </label>

                                                <div className="flex flex-wrap items-center w-full mb-5">
                                                    <input
                                                        type="text"
                                                        className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                        value={privateip}
                                                        onChange={(e) => setPrivateip(e.target.value)}
                                                    />

                                                    <button
                                                        type="button"
                                                        style={{ maxHeight: "28px" }}
                                                        className={"block bg-lightBlue-500 w-2/12 px-2 py-1 ml-4 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none active:bg-lightBlue-600 hover:shadow-md focus:outline-none"}
                                                        onClick={(e) => onClipboardCopy(e)}>
                                                        { clipBoardBtnText }
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap w-full mb-5">
                                                {userInfo.role === 1 &&
                                                    <div className="w-4/12">
                                                        <label
                                                            className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                            연결제한 (복합기 수)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-100-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                            value={maxcount}
                                                            onChange={(e) => setMaxcount(e.target.value)}
                                                        />
                                                    </div>
                                                }

                                                <div className="w-6/12">
                                                    <label
                                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                        파일보관 (시간)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-100-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                        value={keeptime}
                                                        onChange={(e) => setKeeptime(e.target.value)}
                                                    />
                                                    <span className="px-3 text-xs font-semibold text-blueGray-500">(예: 0.5 → 30분)</span>
                                                </div>
                                            </div>

                                            <div className="w-full mb-5">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    상태
                                                </label>
                                                <StatusDropdown status={status} onStatusChanged={onStatusChanged} />
                                            </div>

                                            <div className="w-full mb-5">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    파트너 / 고객
                                                </label>
                                                <div className="flex flex-wrap w-full pr-4 mb-2">
                                                    {userInfo.role === 2 &&
                                                        <h3 className="px-3 pt-2 text-sm font-semibold">
                                                            {userInfo.resellername}
                                                        </h3>
                                                    }
                                                    {
                                                        dropdownData.map((item, index) =>
                                                            <SearchDropdown
                                                                key={"SearchDropdown_" + index}
                                                                title={item.title}
                                                                inx={index}
                                                                selecteditem={getSelectedItem(index)}
                                                                items={item.data}
                                                                onselected={onDropdownSelected}
                                                                enabled={item.enabled} />
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className="w-full">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    비고 (주석)
                                                </label>
                                                <textarea
                                                    rows={5}
                                                    type="text"
                                                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-between p-3 px-6 border-t border-solid rounded-b border-blueGray-200">
                                    <div>
                                        <Alert data={alertData}></Alert>
                                    </div>
                                    <div className="float-right">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            type="button"
                                            onClick={() => onClosed(modalName, isRefreshed)}>
                                            닫기
                                        </button>
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={onSaveClicked}>
                                            저장
                                        </button>
                                    </div>
                                </div>
                                <form method="GET" ref={rebootFormRef} action="" target="rebootProcess">
                                    <input type="hidden" name="guid" value="" />
                                </form>
                                <iframe name="rebootProcess" ref={rebootFrameRef} width={"0"} height={"0"} style={{ border: "0px" }}>

                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </>
    );
};