import React from "react";
import { createPopper } from "@popperjs/core";

const StatusDropdown = ({ status = false, statusText = undefined, onStatusChanged }) => {
    // dropdown props
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const [trueText, setTrueText] = React.useState('사용');
    const [falseText, setFalseText] = React.useState('사용안함');

    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();

    React.useEffect(() => {

        if (statusText === undefined || statusText.length === 0) {
            return;
        }

        if (statusText.toString().indexOf("|") <= 0) {
            return;
        }

        let statusList = statusText.toString().split("|", 2);
        setTrueText(statusList[0]);
        setFalseText(statusList[1]);

    }, [statusText]);

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start"
        });
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };


    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <div className="relative inline-flex w-full align-middle">
                        <button
                            className="px-4 py-2 mb-1 mr-1 text-sm font-bold uppercase transition-all duration-150 ease-linear bg-white rounded shadow outline-none text-blueGray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            ref={btnDropdownRef}
                            onClick={() => {
                                dropdownPopoverShow
                                    ? closeDropdownPopover()
                                    : openDropdownPopover();
                            }}
                        >
                            <i className={"fas fa-circle text-" + (status ? "emerald" : "orange") + "-500 mr-2"}></i> {(status ? (trueText) : (falseText))}
                            <i className={"ml-2 fas fa-caret-" + (dropdownPopoverShow ? "up" : "down")}></i>
                        </button>

                        <div
                            className={(dropdownPopoverShow ? "block " : "hidden ") + "fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"}
                            onClick={(e) => closeDropdownPopover()}>
                            <div
                                ref={popoverDropdownRef}
                                className={
                                    (dropdownPopoverShow ? "block " : "hidden ") +
                                    "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48 border-blueGray-200 border"
                                }
                            >

                                <div className="overflow-y-auto">
                                    <a
                                        href="#pablo"
                                        className="block w-full px-4 py-2 text-sm font-normal whitespace-no-wrap bg-transparent text-blueGray-500"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            closeDropdownPopover();
                                            if (status === false) {
                                                onStatusChanged(true);
                                            }
                                        }}
                                    >
                                        <i className="mr-2 fas fa-circle text-emerald-500"></i> {trueText}
                                    </a>
                                    <a
                                        href="#pablo"
                                        className="block w-full px-4 py-2 text-sm font-normal whitespace-no-wrap bg-transparent text-blueGray-500"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            closeDropdownPopover();
                                            if (status === true) {
                                                onStatusChanged(false);
                                            }
                                        }}
                                    >
                                        <i className="mr-2 text-orange-500 fas fa-circle"></i> {falseText}
                                    </a>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );

};



export default StatusDropdown;
