import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";


import Admin from "layouts/Admin.js";
import Login from "layouts/Auth.js";
import LoginRoute from "route/LoginRouter.js";
import LogoutRoute from "route/LogoutRouter.js";

import Dashboard from "views/Dashboard.js";
import Profile from "views/Profile.js";
import Management from "views/Management.js";
import CardAndCoupon from "views/CardAndCoupon";
import Reports from "views/Reports.js";
import Printer from "views/Printers.js";

ReactDOM.render(
  <BrowserRouter>
    <Routes>

      <Route element={<LogoutRoute />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Route>

      <Route element={<LoginRoute />}>
        {/* Logined User */}
        <Route element={<Admin />}>
          <Route path="*" element={<Reports />} />
          <Route path="/admin/profile" exact element={<Profile />} />
          <Route path="/admin/management" exact element={<Management />} />
          <Route path="/admin/reports" exact element={<Reports />} />
          {/* 20240423 PYH */}
          <Route path="/admin/cardcoupon" exact element={<CardAndCoupon />} />
          {/* 프린터 라이센스 On/Off */}
          <Route path="/admin/printer" exact element={<Printer />} />
        </Route>
      </Route>

    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
