import Pagination from "./Pagination.js";

export default function CostTable({ name = "", header = [], data = [], totalCount = 0, onPageChanged, onRowSelected }) {

  const columnKey = header.filter((r) => r.value.length > 0).map((i) => i.value);

  const setHeader = () => {
    let headerRowCnt = 1;
    for (let i = 0; i < header.length; i++) {
      let rowinx = header[i]["rowinx"] !== null ? header[i]["rowinx"] : 0;
      if (rowinx + 1 > headerRowCnt) {
        headerRowCnt = rowinx + 1;
      }
    }

    const result = [];
    for (let i = 0; i < headerRowCnt; i++) {
      let headerRow = header.filter((r) => r.rowinx === i);
      result.push(
        <tr key={name + "_h_tr_" + i}>
          {setHeaderColumn(headerRow, i)}
        </tr>
      );
    }

    return result;
  };

  const setHeaderColumn = (columns, rowinx) => {
    const result = [];

    for (let i = 0; i < columns.length; i++) {
        result.push(
          <th
            key={name + "_h_th_" + rowinx + "_" + i}
            colSpan={columns[i].colspan !== undefined ? columns[i].colspan : 1}
            rowSpan={columns[i].rowspan !== undefined ? columns[i].rowspan : 1}
            className={"px-3 py-3 text-xs font-semibold text-center uppercase align-middle border border-l-0 border-r" + (i === columns.length - 1 && rowinx === 0 ? "-0" : "") + " border-solid whitespace-nowrap bg-blueGray-200 text-blueGray-500 border-blueGray-100"}>
            {columns[i].text}
          </th>
        );
    }

    return result;
  };

  const setRow = () => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push(
        <tr
          key={name + "_tr_" + i}
          className={"border-t"}>
          {setColumn(i, data[i])}
        </tr>
      );
    }

    return result;
  };

  const setColumn = (index, item) => {

    let rowData = {};
    rowData.name = item.name;
    rowData.resellername = item.resellername;
    rowData.a4color = item.a4color + " (" + item.a4color2 + ")";
    rowData.a4gray = item.a4gray + " (" + item.a4gray2 + ")";
    rowData.a3color = item.a3color + " (" + item.a3color2 + ")";
    rowData.a3gray = item.a3gray + " (" + item.a3gray2 + ")";
    rowData.b4color = item.b4color + " (" + item.b4color2 + ")";
    rowData.b4gray = item.b4gray + " (" + item.b4gray2 + ")";
    rowData.b5color = item.b5color + " (" + item.b5color2 + ")";
    rowData.b5gray = item.b5gray + " (" + item.b5gray2 + ")";
    rowData.lettercolor = item.lettercolor + " (" + item.lettercolor2 + ")";
    rowData.lettergray = item.lettergray + " (" + item.lettergray2 + ")";
    rowData.legalcolor = item.legalcolor + " (" + item.legalcolor2 + ")";
    rowData.legalgray = item.legalgray + " (" + item.legalgray2 + ")";
    rowData.anycolor = item.anycolor + " (" + item.anycolor2 + ")";
    rowData.anygray = item.anygray + " (" + item.anygray2 + ")";
    rowData.scan = item.scan;
    rowData.fax = item.fax;
    rowData.isdefault = item.isDefault;

    //console.log("item", item);
    //console.log("rowData", rowData);

    const result = [];
    for (let i = 0; i < columnKey.length; i++) {
      if (columnKey[i] === 'name') {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"flex items-center p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            <span className={"ml-3 font-bold text-blueGray-600"}>{rowData[columnKey[i]]}</span></td>
        );
      } else if (columnKey[i] === 'isdefault') {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            {rowData[columnKey[i]] === 1 && <span className={"font-bold text-blueGray-600"}>기본으로 설정</span>}
          </td>
        );
      } else {
        result.push(
          <td
            key={name + "_td_" + index + "_" + i}
            className={"p-2 px-4 text-xs align-middle border-l-0 border-r-0 whitespace-nowrap"}
            onClick={() => { onRowSelected(name, index) }}>
            {rowData[columnKey[i]]}
          </td>
        );
      }
    }

    return result;
  };

  return (
    <>
      <div className={"relative flex flex-col border border-blueGray-200 w-full transition-custom1 min-h-595-px min-w-0 mb-6 break-words bg-white rounded shadow-lg"}>
        <div className={"block w-full overflow-x-auto rounded-t min-h-553-px"}>
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              {setHeader()}
            </thead>
            <tbody>
              {
                !data.length && <tr>
                  <td colSpan={columnKey.length} className="items-center p-4 px-4 text-xs text-center align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap" style={{ paddingTop: "140px" }}>
                    <span
                      className="ml-3 font-bold text-blueGray-600"
                    >
                      조회된 리스트가 없습니다.
                    </span>
                  </td>
                </tr>
              }
              {setRow()}
            </tbody>
          </table>
        </div>

        {
          totalCount > 10 &&
          <Pagination tablename={name} rowcount={totalCount} onPageChanged={onPageChanged} />
        }
      </div>
    </>
  );
}
