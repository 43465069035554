import axios from "axios";
import { API } from "./config";

class request {

    url;
    data;
    onSuccess;
    onFailed;
    onExpiredLogin;

    constructor(url, data) {
        this.url = url;
        this.data = data;
        console.log("request url : " + url);
        console.log("request data : " + JSON.stringify(data));
    }

    post(onSuccess, onFailed, onExpiredLogin) {

        let token = {};
        if (sessionStorage.token !== null) {
            token = JSON.parse(sessionStorage.token); //.accessToken;
        }

        axios.post(this.url, this.data, {
            headers: {
                "X-AUTH-TOKEN": token.accessToken
            }
        })
            .then((res) => onSuccess(res))
            .catch((err) => {
                if (err.response !== undefined) {
                    const response = err.response.data
                    if (response.code === "4011") {

                        console.log("token expired!");
                        this.onSuccess = onSuccess;
                        this.onFailed = onFailed;
                        this.onExpiredLogin = onExpiredLogin;
                        this.#refresh(token.refreshToken);
                    } else if (response.code === "4012" || response.code === "4013") {
                        onExpiredLogin();
                    } else {
                        onFailed(err)
                    }
                } else {
                    onFailed(err)
                }
            });
    }

    get(then) {
        axios.get(this.url).then((res) => then(res));
    }

    #refresh(refreshToken) {
        axios.post(`${API.REFRESH}`, {
            "refreshToken": refreshToken
        })
            .then((res) => {
                const response = res.data;
                if (response.data !== null) {
                    sessionStorage.token = JSON.stringify(response.data);
                    console.log("token refresh!");
                    this.post(this.onSuccess, this.onFailed);
                } else {
                    sessionStorage.clear();
                    this.onFailed({
                        // expired token, redirect login page
                        "httpStatus": "UNAUTHORIZED",
                        "code": "4014",
                        "message": "인증되지 않은 사용자입니다."
                    });
                }
            })
            .catch((err) => {
                const response = err.response.data;
                if (response.code === "error") {
                    sessionStorage.clear();
                    this.onExpiredLogin();
                } else {
                    this.onFailed(err)
                }
            });
    }

}


export default request;