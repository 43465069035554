import { useState, useEffect } from "react";

export default function Tab({ data = [], onChanged = undefined, startTab = 1 }) {

    const [openTab, setOpenTab] = useState(startTab);

    useEffect(() => {
        if (onChanged) {
            onChanged(openTab);
        }
    }, [openTab]);


    useEffect(() => {
        setOpenTab(startTab);
    }, [startTab]);

    return (
        <>
            <ul
                className="flex flex-row flex-wrap pt-3 pb-4 mb-0 list-none"
                role="tablist"
            >
                {
                    data.map((item, index) =>
                        <li
                            key={"Tab_" + index}
                            className="flex-auto mr-2 -mb-px text-center last:mr-0">
                            <a
                                className={
                                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                    (openTab === (index + 1)
                                        ? "text-white bg-lightBlue-600"
                                        : "text-lightBlue-600 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    if (openTab !== index + 1) {
                                        setOpenTab(index + 1);
                                    }
                                }}
                                data-toggle="tab"
                                href={"#link" + (index + 1)}
                                role="tablist"
                            >
                                {item}
                            </a>
                        </li>
                    )
                }
            </ul>
        </>
    );
}
