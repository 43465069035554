const BASE_URL = 'https://www.myprint24.kr/api';
//const BASE_URL = 'http://192.168.1.250:8080/api';


export const API = {
    LOGIN: `${BASE_URL}/user/login`,
    REFRESH: `${BASE_URL}/user/refresh`,
    
    DEVICE_PAYREADER: `${BASE_URL}/device/payreader`,
    DEVICE_PAYREADER_UPDATE: `${BASE_URL}/device/payreader/update/{seq}`,

    DEVICE_MINISERVER: `${BASE_URL}/device/miniserver`,
    DEVICE_MINISERVER_SELECTED: `${BASE_URL}/device/miniserver/{code}`,
    DEVICE_MINISERVER_UPDATE: `${BASE_URL}/device/miniserver/update/{seq}`,
    DEVICE_MINISERVER_REBOOT: `${BASE_URL}/device/miniserver/reboot`,
    // 20241014
    DEVICE_MINISERVER_REFRESH: `${BASE_URL}/device/miniserver/refresh`,

    DEVICE_PRINTER_COST: `${BASE_URL}/device/printer/cost`,
    DEVICE_PRINTER_COST_UPDATE: `${BASE_URL}/device/printer/cost/update/{seq}`,

    DEVICE_PRINTER: `${BASE_URL}/device/printer`,
    DEVICE_PRINTER_UPDATE: `${BASE_URL}/device/printer/update/{seq}`,
    
    DEVICE_LIST_MINISERVERCODE: `${BASE_URL}/device/list/managecode`,
    DEVICE_LIST_PAYREADER: `${BASE_URL}/device/list/readerlocation`,
    DEVICE_LIST_COST: `${BASE_URL}/device/list/costname`,

    TRANSACTION_BYJOB: `${BASE_URL}/transaction/byjob`,
    TRANSACTION_BYDAY: `${BASE_URL}/transaction/byday`,
    PRINTER_CONSUMABLE: `${BASE_URL}/transaction/printercmyk`,

    USER_RESELLER: `${BASE_URL}/user/reseller`,
    USER_CUSTOMER: `${BASE_URL}/user/customer`,

    // 20240416 PYH 
    USER_LIST: `${BASE_URL}/user/list`,
    
    // 20240415 PYH reseller update(add) 
    USER_RESELLER_UPDATE: `${BASE_URL}/user/reseller/update/{seq}`,

    // 20240418 PYH user update
    USER_UPDATE: `${BASE_URL}/user/update/{seq}`,

    // 20240423 PYH 
    USER_CUSTOMER_UPDATE: `${BASE_URL}/user/customer/update/{seq}`,
    USER_CARD: `${BASE_URL}/user/card`,
    USER_CARD_UPDATE: `${BASE_URL}/user/card/update/{seq}`,
    USER_CARD_HISTORY: `${BASE_URL}/user/card/history`,
    USER_COUPON: `${BASE_URL}/user/coupon`,
    USER_COUPON_UPDATE: `${BASE_URL}/user/coupon/update/{seq}`,
    USER_CUSTOMER2: `${BASE_URL}/user/customer2`,

    USER_PRINTER_ROLE_LIST: `${BASE_URL}/user/list/printerrole`,

    
};