import React from "react";
import { createPopper } from "@popperjs/core";
import { useNavigate } from "react-router-dom";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const navigate = useNavigate();
  
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  
  const logout = (e) => {
    e.preventDefault();
    if (sessionStorage.user != null) {
      sessionStorage.clear();
    }

    navigate("/login");
  };

  return (
    <>
      <a
        className="block text-blueGray-500"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center w-12 h-12 text-sm text-white rounded-full bg-blueGray-200">
            <i className="text-3xl align-middle border-none rounded-full fas fa-user-circle text-lightBlue-500"></i>
            
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        {/*<div className="h-0 my-2 border border-solid border-blueGray-100" />*/}
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => logout(e)}
        >
          로그아웃
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
