import React, { useRef } from "react";
import { useState, useEffect } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";
import StatusDropdown from "components/Dropdowns/StatusDropdown";

import { API } from "api/config";
import request from "api/request";
import Alert from "components/Alert/StaticAlert";

export default function UserCardModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

  const [showModal, setShowModal] = React.useState(show);
  const [selectedItem, setSelectedItem] = useState({});
  const [dropdownData, setDropdownData] = useState({});

  const [cardnum, setCardnum] = useState('');
  const [resellerid, setResellerid] = useState('');
  const [resellername, setResellername] = useState('');
  const [balance, setBalance] = useState('');
  const [status, setStatus] = useState(false);
  const [comment, setComment] = useState('');
  const [description, setDescription] = useState('');
  const [changebal, setChangebal] = useState('');
  const [changebalshow, setChangebalshow] = useState(false);

  const [alertData, setAlertData] = React.useState({ show: false, msg: "" });

  const modalName = "card";
  const oribal = data.balance;
  const cardnumRef = useRef();
  const balanceRef = useRef();
  const commentRef = useRef();
  const changebalRef = useRef();

  const userInfo = JSON.parse(sessionStorage.user);

  useEffect(() => {
    setShowModal(show);

    if (!show) {
      setCardnum('');
      setResellerid('');
      setResellername('');
      setBalance('');
      setStatus(false);
      setComment('');
      setDescription('');
      setChangebal('');
      setChangebalshow(false);
      setSelectedItem({});
      setAlertData();
    }

  }, [show]);

  useEffect(() => {
    // if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
    //   return;
    // }

    console.log("useEffect", data);

    setCardnum(data.cardnum || "");
    setResellerid(data.resellerid || "");
    setResellername(data.resellername || "");
    setBalance(data.balance || "");
    setStatus(data.status === 1);
    setComment(data.comment || "");
    setDescription(data.description || "");

    setSelectedItem({});

    let selected = {};

    if (data.resellerid !== undefined && data.resellerid.length > 0) {
      // selected.push({ field: "reseller", value: data.resellerid, name: data.resellername });
      selected.field = "reseller";
      selected.value = data.resellerid;
      selected.name = data.resellername;
    }

    // if (data.customerseq !== undefined && data.customerseq > 0) {
    //   selected.push({ field: "customer", value: data.customerseq, name: data.customername });
    // }

    if (selected.field) {
      setSelectedItem(selected);
    }

    if (userInfo.role === 1) {
      getResellerList();
    }
    // setDropdownData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  // useEffect(() => {

  //   if (!showModal) {
  //     return;
  //   }

  //   if (dropdownData.length === 0) {
  //     if (userInfo.role === 1) {
  //       getResellerList();
  //     } else if (userInfo.role === 2) {
  //       getResellerList(userInfo.resellerid);
  //     }
  //   } else {
  //     if (selectedItem.length > 0 && selectedItem.length >= dropdownData.length) {
  //       let selected = selectedItem[dropdownData.length - 1];
  //       if (selected.field === "reseller" && selected.value.length > 0) {
  //         getResellerList(selected.value);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps

  // }, [dropdownData]);

  const getResellerList = (parentid) => {

    new request(`${API.USER_RESELLER}`, {
      "parentid": parentid,
      "pageindex": -1
    }).post(
      (res) => {
        const response = res.data;
        if (response.code === 'success') {

          let resellerList = [];

          if (response.data.length > 1) {
            resellerList.push({
              key: "",
              name: "전체",
              type: "reseller"
            });
          }

          for (let i = 0; i < response.data.length; i++) {
            resellerList.push({
              key: response.data[i]["resellerid"],
              name: response.data[i]["name"],
              type: "reseller"
            });
          }

          if (parentid === undefined || parentid === '') {
            let item = {
              "title": "선택",
              "data": resellerList
            };

            // setDropdownData([...dropdownData, item]);
            setDropdownData(item);
          }
        }
      }, (err) => {
        console.log(err);
      }, () => {

      });
  };
  const onDropdownSelected = (type, index, selectedKey) => {

    setAlertData();

    let selected = Object.assign({}, selectedItem);
    let curDropdown = Object.assign({}, dropdownData);

    let refresh = true;

    // if (index < selected.length) {

    if (selected.field === type && selected.value === selectedKey) {
      refresh = false;
    }

    selected.field = type;
    selected.value = selectedKey;
    // } else {
    //   selected.push({
    //     field: type,
    //     value: selectedKey,
    //     name: selectedName
    //   });
    // }

    if (refresh) {
      // selected = selected.slice(0, index + 1);
      setSelectedItem(selected);
      // curDropdown = curDropdown.slice(0, index + 1);
      setDropdownData(curDropdown);
    }
  };

  const getSelectedItem = () => {
    // if (index < selectedItem.length) {
    return { key: selectedItem.value, name: selectedItem.name };
    // }
    // return undefined;
  };

  const onStatusChanged = (selectedStatus) => {
    setStatus(selectedStatus);
    setAlertData();
  };

  const onSaveClicked = () => {
    let newData = Object.assign({}, data);

    if (cardnum.length === 0) {
      setAlertData({ show: true, msg: "카드번호는 필수입력입니다." });
      return cardnumRef.current.focus();
    }
    if (balance.length === 0) {
      setAlertData({ show: true, msg: "금액은 필수입력입니다." });
      return balanceRef.current.focus();
    }
    if (selectedItem.value === undefined) {
      setAlertData({ show: true, msg: "파트너는 필수입력입니다." });
      return;
    }
    // if (changebalshow) {
    //   if (changebal === "" || changebal === null || changebal === undefined) {
    //     setAlertData({ show: true, msg: "금액 변경 사유를 입력해주세요."});
    //     return;
    //   }
    // }

    newData.cardnum = cardnum;
    newData.balance = balance;
    newData.status = status ? 1 : 0;
    newData.comment = comment;

    newData.resellerid = selectedItem.value || "";
    newData.changebal = changebal;
    newData.description = description;


    // if (userInfo.role === 2 && selectedReseller.length === 0) {
    //   selectedReseller = userInfo.resellerid;
    // }
    // newData.resellerid = selectedReseller;
    // newData.customerseq = selectedCustomer;

    if (newData.seq === null || newData.seq === undefined) {
      newData.seq = -1;
    }

    console.log("card update", newData);

    if (isChangedRowData(data, newData)) {
      let url = `${API.USER_CARD_UPDATE}`.replace("{seq}", newData.seq.toString());
      new request(url, newData).post(
        (res) => {
          const response = res.data;
          if (response.code === 'success') {
            onSaved(modalName, true);
          } else {
            setAlertData({ show: true, msg: response.message });
          }
        },
        (err) => {
          console.log(err);
          onSaved(modalName, false);
        },
        () => {

        });
    } else {
      onClosed(modalName);
    }
  };

  const isChangedRowData = (oriData, newData) => {
    const keys1 = Object.keys(oriData);
    const keys2 = Object.keys(newData);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const val1 = oriData[key];
      const val2 = newData[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
        return true;
      }
    }

    return false;
  };

  const isObject = (obj) => {
    return obj != null && typeof obj === 'object';
  };

  useEffect(() => {
    if (oribal == balance) {
      setChangebalshow(false);
    } else {
      setChangebalshow(true);
    }
  }, [balance]);

  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={() => { }}
          >
            <div className="relative w-auto max-w-xl min-w-500-px mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">
                    사용자 카드
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClosed(modalName)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6 bg-blueGray-100">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full mb-5">
                        <label
                          className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                          카드 번호
                        </label>
                        {userInfo.role === 1 ? (
                          <input
                            type="text"
                            className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                            ref={commentRef}
                            value={comment}
                            onChange={(e) => {
                              setComment(e.target.value);
                              setAlertData();
                            }}
                          />
                        ) : (
                          <h3 className="px-3 text-lg font-semibold">
                            {comment}
                          </h3>
                        )}

                      </div>
                      {userInfo.role === 1 ? (
                        <div className="w-full mb-5">
                          <label
                            className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                            내부 번호
                          </label>
                          <input
                            type="text"
                            className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                            ref={cardnumRef}
                            value={cardnum}
                            onChange={(e) => {
                              setCardnum(e.target.value);
                              setAlertData();
                            }}
                          />

                        </div>
                      ) : null}
                      </div>
                    {
                      (userInfo.role === 1 || userInfo.role === 2) && (
                        <div className="flex flex-wrap w-full mb-5">
                          <div className="w-6/12">
                            <label
                              className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                              금액(원)
                            </label>
                            <input
                              type="number"
                              className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                              ref={balanceRef}
                              value={balance}
                              onChange={(e) => {
                                setBalance(e.target.value);
                                // setChangebalshow(true);
                                setAlertData();
                              }}
                            />
                          </div>
                        </div>
                      )
                    }
                    {
                      changebalshow && 
                      <div className="flex flex-wrap w-full mb-5">
                          <div className="w-6/12">
                            <label
                              className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                              변경 사유
                            </label>
                            <input
                              type="text"
                              className="px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                              ref={changebalRef}
                              value={changebal}
                              onChange={(e) => {
                                setChangebal(e.target.value);
                                setAlertData();
                              }}
                            />
                          </div>
                        </div>
                    }
                    <div className="w-full mb-5">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                        상태
                      </label>
                      <StatusDropdown status={status} statusText={"사용가능|사용불가"} onStatusChanged={onStatusChanged} />
                    </div>

                    <div className="w-full mb-5">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                        파트너
                      </label>
                      <div className="flex flex-wrap w-full pr-4 mb-2">
                        {userInfo.role === 2 ?
                          <h3 className="px-3 pt-2 text-sm font-semibold">
                            {userInfo.resellername}
                          </h3>
                        :
                        <SearchDropdown
                          title={dropdownData.title}
                          inx={0}
                          selecteditem={getSelectedItem}
                          items={dropdownData.data}
                          onselected={onDropdownSelected} />
                        }
                      </div>
                    </div>

                    <div className="w-full">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                        비고 (주석)
                      </label>
                      <textarea
                        rows={5}
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-3 px-6 border-t border-solid rounded-b border-blueGray-200">
                  <div>
                    <Alert data={alertData}></Alert>
                  </div>
                  <div className="float-right">
                    <button
                      className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                      type="button"
                      onClick={() => onClosed(modalName)}>
                      닫기
                    </button>
                    <button
                      className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={onSaveClicked}>
                      저장
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};