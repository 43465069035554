import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Header from "components/Tables/Header.json"

const CostUpdateTable = forwardRef((props = {}, _ref) => {

    const header = Header.costupdate;
    const columnKey = header.filter((r) => r.value.length > 0).map((i) => i.value);

    const [costData, setCostData] = useState(props.data);

    useEffect(() => {
        if (props.data === null || props.data === undefined || Object.keys(props.data).length === 0) {
            let initCost = {};
            columnKey.map((c, index) => {
                initCost[c] = 0;
            });
            setCostData(initCost);
        } else {
            setCostData(props.data);
        }
    }, [props.data]);

    const setHeader = () => {
        let headerRowCnt = 1;
        for (let i = 0; i < header.length; i++) {
            let rowinx = header[i]["rowinx"] !== null ? header[i]["rowinx"] : 0;
            if (rowinx + 1 > headerRowCnt) {
                headerRowCnt = rowinx + 1;
            }
        }

        const result = [];
        for (let i = 0; i < headerRowCnt; i++) {
            let headerRow = header.filter((r) => r.rowinx === i);
            result.push(
                <tr key={"CostUpdateTable_h_tr_" + i}>
                    {setHeaderColumn(headerRow, i)}
                </tr>
            );
        }

        return result;
    };

    const setHeaderColumn = (columns, rowinx) => {
        const result = [];

        for (let i = 0; i < columns.length; i++) {
            result.push(
                <th
                    key={"CostUpdateTable_h_th_" + rowinx + "_" + i}
                    colSpan={columns[i].colspan !== undefined ? columns[i].colspan : 1}
                    rowSpan={columns[i].rowspan !== undefined ? columns[i].rowspan : 1}
                    className={"px-3 py-2 text-xs font-semibold text-center uppercase align-middle border border-l-0 border-r" + (i === columns.length - 1 && rowinx === 0 ? "-0" : "") + " border-solid whitespace-nowrap bg-blueGray-200 text-blueGray-500 border-blueGray-100"}>
                    {columns[i].text}
                </th>
            );
        }

        return result;
    };

    const setInputCost = (name, value) => {
        if (!isNaN(value)) {
            let cost = Object.assign({}, costData);
            cost[name] = Number(value);
            setCostData(cost);
        }
    };

    useImperativeHandle(_ref, () => ({
        getCostData: () => {
            
            return costData;
        }
    }));

    return (
        <>
            <div className={"relative flex flex-col border border-blueGray-200 w-full transition-custom1 min-w-0 break-words bg-white rounded shadow-lg"}>
                <div className={"block w-full overflow-x-auto rounded-t"}>
                    <table className="items-center w-full bg-transparent border-collapse ">
                        <thead>
                            {setHeader()}
                        </thead>
                        <tbody>
                            <tr className="border-t">
                                {
                                    columnKey.map((c, index) =>
                                        <td
                                            key={"CostUpdateTable_td_" + index}
                                            className={"text-xs align-middle border-l-0 border-r border-b whitespace-nowrap"}>
                                            <input
                                                type="text"
                                                className="px-2 py-2 text-sm font-semibold text-right transition-all duration-150 ease-linear bg-white border-0 rounded max-w-55-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none"
                                                value={costData[c] === undefined ? 0 : costData[c]}
                                                onChange={(e) => setInputCost(c, e.target.value)}
                                            />
                                        </td>
                                    )
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
});

export default CostUpdateTable;
