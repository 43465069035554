import React, { useRef } from "react";
import { useState, useEffect } from "react";
import SearchDropdown from "components/Dropdowns/SearchDropdown";

import { API } from "api/config";
import request from "api/request";

import CostUpdateTable from "components/Tables/CostUpdateTable";
import Alert from "components/Alert/StaticAlert";

export default function CostModal({ show = false, data = {}, onSaved = undefined, onClosed = undefined }) {

    const modalName = "cost";

    const userInfo = JSON.parse(sessionStorage.user);

    const [alertData, setAlertData] = React.useState({ show: false, msg: "" });

    const [showModal, setShowModal] = React.useState(show);
    const [selectedItem, setSelectedItem] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    const [costName, setCostName] = useState('');
    const [printCost, setPrintCost] = useState({});
    const [copyCost, setCopyCost] = useState({});
    const [scanCost, setScanCost] = useState(0);
    const [faxCost, setFaxCost] = useState(0);
    const [isDefault, setDefault] = useState(false);

    const costNameRef = useRef();
    const printCostTable = useRef();
    const copyCostTable = useRef();

    useEffect(() => {
        setShowModal(show);

        if (!show) {
            // TODO : clear
            setCostName('');
            setPrintCost({});
            setCopyCost({});
            setSelectedItem([]);
            setScanCost(0);
            setFaxCost(0);
            setDefault(false);
            setAlertData({});
        }

    }, [show]);

    useEffect(() => {
        if (data === undefined || data === null || Object.keys(data).length === 0 || !showModal) {
            return;
        }

        console.log("useEffect", data);

        if (data.costid !== -1) {
            setCostName(data.name);
            setDefault(data.isDefault === 1);
            setPrintCost({
                a4color: data.a4color,
                a4gray: data.a4gray,
                a3color: data.a3color,
                a3gray: data.a3gray,
                b4color: data.b4color,
                b4gray: data.b4gray,
                b5color: data.b5color,
                b5gray: data.b5gray,
                legalcolor: data.legalcolor,
                legalgray: data.legalgray,
                lettercolor: data.lettercolor,
                lettergray: data.lettergray,
                anycolor: data.anycolor,
                anygray: data.anygray
            });
            setCopyCost({
                a4color: data.a4color2,
                a4gray: data.a4gray2,
                a3color: data.a3color2,
                a3gray: data.a3gray2,
                b4color: data.b4color2,
                b4gray: data.b4gray2,
                b5color: data.b5color2,
                b5gray: data.b5gray2,
                legalcolor: data.legalcolor2,
                legalgray: data.legalgray2,
                lettercolor: data.lettercolor2,
                lettergray: data.lettergray2,
                anycolor: data.anycolor2,
                anygray: data.anygray2
            });
            setScanCost(data.scan);
            setFaxCost(data.fax);
        }

        let selected = [];

        if (data.resellerid !== undefined && data.resellerid.length > 0 && userInfo.resellerid !== data.resellerid) {
            selected.push({ field: "reseller", value: data.resellerid, name: data.resellername });
        }

        if (selected.length > 0) {
            setSelectedItem(selected);
        }

        setDropdownData([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {

        if (!showModal) {
            return;
        }

        if (dropdownData.length === 0) {
            if (userInfo.role === 1) {
                getResellerList();
            } else if (userInfo.role === 2) {
                getResellerList(userInfo.resellerid);
            }
        } else {
            if (selectedItem.length > 0 && selectedItem.length >= dropdownData.length) {
                let selected = selectedItem[dropdownData.length - 1];
                if (selected.field === "reseller" && selected.value.length > 0) {
                    getResellerList(selected.value);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownData]);

    const getResellerList = (parentid) => {

        new request(`${API.USER_RESELLER}`, {
            "parentid": parentid,
            "pageindex": -1
        }).post(
            (res) => {
                const response = res.data;
                if (response.code === 'success') {

                    let resellerList = [];

                    resellerList.push({
                        key: "",
                        name: "미정(할당안함)",
                        type: "reseller"
                    });

                    for (let i = 0; i < response.data.length; i++) {
                        resellerList.push({
                            key: response.data[i]["resellerid"],
                            name: response.data[i]["name"],
                            type: "reseller"
                        });
                    }

                    let item = {
                        "title": "파트너",
                        "data": resellerList
                    };

                    setDropdownData([...dropdownData, item]);

                } else {
                    if (parentid !== undefined && parentid.length > 0) {
                        //getCustomerList(parentid);
                    }
                }
            }, (err) => {
                console.log(err);
            }, () => {

            });
    };

    /*
    const getCustomerList = (resellerid, mergeData) => {

        new request(`${API.USER_CUSTOMER}`, {
            "resellerid": resellerid,
            "pageindex": -1
        }).post(
            (res) => {
                // onSuccess
                const response = res.data;
                if (response.code === 'success') {

                    let customerList = [];

                    if (mergeData !== undefined) {
                        customerList = [...mergeData];
                    }

                    if (customerList <= 1) {
                        customerList.push({
                            key: -1,
                            name: "미정(할당안함)",
                            type: "customer"
                        });
                    }


                    for (let i = 0; i < response.data.length; i++) {
                        customerList.push({
                            key: response.data[i]["seq"],
                            name: response.data[i]["displayname"],
                            type: "customer"
                        });
                    }

                    let item = {
                        "title": "고객",
                        "data": customerList
                    };

                    setDropdownData([...dropdownData, item]);

                }
            }, (err) => {
                // onFailed

            }, () => {
                // onExpired login

            });
    };
    */

    const onDropdownSelected = (type, index, selectedKey, selectedName) => {

        let selected = [...selectedItem];
        let curDropdown = [...dropdownData];

        let refresh = true;

        if (index < selected.length) {

            if (selected[index].field === type && selected[index].value === selectedKey) {
                refresh = false;
            }

            selected[index].field = type;
            selected[index].value = selectedKey;
        } else {
            selected.push({
                field: type,
                value: selectedKey,
                name: selectedName
            });
        }

        if (refresh) {
            selected = selected.slice(0, index + 1);
            setSelectedItem(selected);
            curDropdown = curDropdown.slice(0, index + 1);
            setDropdownData(curDropdown);
        }
    };

    const getSelectedItem = (index) => {
        if (index < selectedItem.length) {
            return { key: selectedItem[index].value, name: selectedItem[index].name };
        }
        return undefined;
    };

    const onSaveClicked = () => {
        if (costName.length === 0) {
            setAlertData({ show: true, msg: "비용코드(이름)은 필수입력입니다." });

            return costNameRef.current.focus();
        }

        let newData = Object.assign({}, data);
        let newPrintCost = printCostTable.current.getCostData();
        let newCopyCost = copyCostTable.current.getCostData();

        newData.name = costName;
        newData.isDefault = isDefault ? 1 : 0;

        let selectedReseller = "";
        for (let i = selectedItem.length - 1; i >= 0; i--) {
            if (selectedItem[i].field === "reseller") {
                selectedReseller = (selectedReseller.length === 0) ? selectedItem[i].value : selectedReseller;
            }
        }

        if (userInfo.role === 2 && selectedReseller.length === 0) {
            selectedReseller = userInfo.resellerid;
        }
        newData.resellerid = selectedReseller;

        newData.a4color = newPrintCost.a4color;
        newData.a4gray = newPrintCost.a4gray;
        newData.a3color = newPrintCost.a3color;
        newData.a3gray = newPrintCost.a3gray;
        newData.b4color = newPrintCost.b4color;
        newData.b4gray = newPrintCost.b4gray;
        newData.b5color = newPrintCost.b5color;
        newData.b5gray = newPrintCost.b5gray;
        newData.legalcolor = newPrintCost.legalcolor;
        newData.legalgray = newPrintCost.legalgray;
        newData.lettercolor = newPrintCost.lettercolor;
        newData.lettergray = newPrintCost.lettergray;
        newData.anycolor = newPrintCost.anycolor;
        newData.anygray = newPrintCost.anygray;

        newData.a4color2 = newCopyCost.a4color;
        newData.a4gray2 = newCopyCost.a4gray;
        newData.a3color2 = newCopyCost.a3color;
        newData.a3gray2 = newCopyCost.a3gray;
        newData.b4color2 = newCopyCost.b4color;
        newData.b4gray2 = newCopyCost.b4gray;
        newData.b5color2 = newCopyCost.b5color;
        newData.b5gray2 = newCopyCost.b5gray;
        newData.legalcolor2 = newCopyCost.legalcolor;
        newData.legalgray2 = newCopyCost.legalgray;
        newData.lettercolor2 = newCopyCost.lettercolor;
        newData.lettergray2 = newCopyCost.lettergray;
        newData.anycolor2 = newCopyCost.anycolor;
        newData.anygray2 = newCopyCost.anygray;

        newData.scan = scanCost;
        newData.fax = faxCost;

        console.log("data", data);
        console.log("newData", newData);

        if (isChangedRowData(data, newData)) {
            let url = `${API.DEVICE_PRINTER_COST_UPDATE}`.replace("{seq}", newData.costid.toString());
            new request(url, newData).post(
                (res) => {
                    const response = res.data;
                    if (response.code === 'success') {
                        onSaved(true);
                    } else {
                        onSaved(false);
                    }
                },
                (err) => {
                    console.log(err);
                    onSaved(false);
                },
                () => {

                });
        } else {
            onClosed(modalName);
        }
    };

    const isChangedRowData = (oriData, newData) => {
        const keys1 = Object.keys(oriData);
        const keys2 = Object.keys(newData);

        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            const val1 = oriData[key];
            const val2 = newData[key];
            const areObjects = isObject(val1) && isObject(val2);
            if ((areObjects && !isChangedRowData(val1, val2)) || (!areObjects && val1 !== val2)) {
                return true;
            }
        }

        return false;
    };

    const isObject = (obj) => {
        return obj != null && typeof obj === 'object';
    };

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                        onClick={() => { }}
                    >
                        <div className="relative w-auto max-w-4xl mx-auto my-6">
                            {/*content*/}
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid rounded-t border-blueGray-200">
                                    <h3 className="text-xl font-semibold">
                                        비용 정보
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => onClosed(modalName)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6 bg-blueGray-100">
                                    <form>
                                        <div className="flex flex-wrap w-full mb-5">
                                            <div className="mr-4">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    관리코드 (이름)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="px-3 py-3 text-sm font-semibold transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-210-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    ref={costNameRef}
                                                    value={costName}
                                                    onChange={(e) => {
                                                        setCostName(e.target.value);
                                                        setAlertData();
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className="block mt-8 mb-2 text-sm font-bold uppercase text-blueGray-600">
                                                    <input
                                                        type="checkbox"
                                                        className="mr-2"
                                                        checked={isDefault}
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            setDefault(e.target.checked);
                                                        }} />
                                                    기본으로 설정
                                                </label>
                                            </div>
                                        </div>


                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                파트너
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                {userInfo.role === 2 &&
                                                    <h3 className="px-3 pt-2 text-sm font-semibold">
                                                        {userInfo.resellername}
                                                    </h3>
                                                }
                                                {
                                                    dropdownData.map((item, index) =>
                                                        <SearchDropdown
                                                            key={"SearchDropdown_" + index}
                                                            title={item.title}
                                                            inx={index}
                                                            selecteditem={getSelectedItem(index)}
                                                            items={item.data}
                                                            onselected={onDropdownSelected} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                출력
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                <CostUpdateTable data={printCost} ref={printCostTable} />
                                            </div>
                                        </div>
                                        <div className="w-full mb-5">
                                            <label
                                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                복사
                                            </label>
                                            <div className="flex flex-wrap w-full pr-4 mb-2">
                                                <CostUpdateTable data={copyCost} ref={copyCostTable} />
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap w-full mb-5">
                                            <div className="mr-4">
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    스캔
                                                </label>
                                                <input
                                                    type="text"
                                                    className="px-4 py-3 text-sm font-semibold text-right transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-70-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    value={scanCost}
                                                    onChange={(e) => {
                                                        if (!isNaN(e.target.value)) {
                                                            setScanCost(Number(e.target.value));
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600">
                                                    팩스
                                                </label>
                                                <input
                                                    type="text"
                                                    className="px-4 py-3 text-sm font-semibold text-right transition-all duration-150 ease-linear bg-white border-0 rounded shadow max-w-70-px placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                                    value={faxCost}
                                                    onChange={(e) => {
                                                        if (!isNaN(e.target.value)) {
                                                            setFaxCost(Number(e.target.value));
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-between p-3 border-t border-solid rounded-b border-blueGray-200">
                                    <div>
                                        <Alert data={alertData}></Alert>
                                    </div>
                                    <div className="float-right">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            type="button"
                                            onClick={() => onClosed(modalName)}>
                                            닫기
                                        </button>
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={onSaveClicked}>
                                            저장
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </>
    );
};