import React, { useState } from "react";
import { createPopper } from "@popperjs/core";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";

const CalendarDropdown = ({ type, onChange, value }) => {
    // dropdown props
    const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);

    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start",
        });
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const onDateChanged = (selectedDate) => {
        if (onChange) {
            onChange(type, selectedDate);
        }
        closeDropdownPopover();
    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <div className="relative inline-flex w-full align-middle">
                        <button
                            className="px-4 py-2 mb-1 mr-1 text-sm font-bold uppercase transition-all duration-150 ease-linear bg-white rounded shadow outline-none text-blueGray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            ref={btnDropdownRef}
                            onClick={() => {
                                dropdownPopoverShow
                                    ? closeDropdownPopover()
                                    : openDropdownPopover();
                            }} >
                            {moment(value).format("YYYY-MM-DD")}
                            <i className={"ml-2 fas fa-caret-" + (dropdownPopoverShow ? "up" : "down")}></i>
                        </button>

                        <div
                            className={(dropdownPopoverShow ? "block " : "hidden ") + "fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"}
                            onClick={(e) => closeDropdownPopover()}>

                            <div
                                ref={popoverDropdownRef}
                                className={
                                    (dropdownPopoverShow ? "block " : "hidden ") +
                                    "bg-white text-base z-50 float-left list-none text-left rounded shadow-lg mt-f-1 min-w-48 border-blueGray-200 border"
                                }
                            >
                                <Calendar
                                    onChange={onDateChanged}
                                    value={moment(value).format("YYYY-MM-DD")}
                                    formatDay={(locale, date) => moment(date).format("D")}
                                    next2Label={null}
                                    prev2Label={null} />
                            </div>

                        </div>






                    </div>
                </div>
            </div>
        </>
    );

};

export default CalendarDropdown;
