import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Navbar({ title }) {
  const userInfo = JSON.parse(sessionStorage.user);

  //const displayName = 
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 z-10 flex items-center w-full p-4 border-b border-blueGray-300 bg-blueGray-200 md:flex-row md:flex-nowrap md:justify-start">
        <div className="flex flex-wrap items-center justify-between w-full px-4 mx-autp md:flex-nowrap md:px-10">
          {/* Brand */}
          <label
            className="hidden text-xl font-semibold uppercase text-blueGray-600 lg:inline-block"
          >
            {title}
          </label>
          {/* Form */}
          <div className="flex-row flex-wrap items-center hidden mr-3 md:flex lg:ml-auto">
            <label
              className="text-base font-semibold uppercase text-blueGray-600 lg:inline-block"
            >
              {userInfo.displayname}
            </label>
          </div>
          {/* User */}
          <ul className="flex-col items-center hidden list-none md:flex-row md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
